import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../view-models/api-response';
import {RegistrationRequest} from '../view-models/registration-request';
import {RegistrationResponse} from '../view-models/registration-response';

@Injectable()
export class RegistrationServiceHttp {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient) {
	}

	public register(requestViewModel: RegistrationRequest): Observable<ApiResponse<RegistrationResponse>> {
		return this.http.post<ApiResponse<RegistrationResponse>>(`${this.coreApiKey}/Ticketing/IdentityManagement/customer/registration`, requestViewModel);
	}

}


