import * as i0 from "@angular/core";
export class PlatformService {
    constructor() { }
    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!window.opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!window.chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
}
PlatformService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlatformService_Factory() { return new PlatformService(); }, token: PlatformService, providedIn: "root" });
