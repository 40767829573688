import {Injectable} from '@angular/core';
import {
	CanActivate, Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateChild,
	CanLoad, Route, UrlSegment, UrlTree
} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private authService: AuthenticationService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = `${state.url}`;
		const teamId = route.parent.paramMap.get('team');
		const redirectUrl = teamId ? `/${teamId}` : '/';
		return this.loadAuthenticationChecker(url, redirectUrl, teamId);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state);
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		const url = `/${route.path}`;
		const teamCodePath = segments[0].path;
		const redirectUrl = url.indexOf(':team') > -1 ? `${teamCodePath}` : `/`;
		return this.loadAuthenticationChecker(url, redirectUrl, teamCodePath);
	}

	private loadAuthenticationChecker(url: string, redirectUrl: string, teamCodePath: string): boolean {
		if (this.authService.isLoggedInPortal(teamCodePath)) {
			return true;
		}
		this.router.navigate([redirectUrl]);
		return false;
	}
}
