import { Observable, of, concat } from 'rxjs';
import { ITicket } from './i-ticket';
import { CartService, getTicketId } from '../cart/cart.service';
import { Injectable } from '@angular/core';
import { SalesHttpService } from '../../../../http-services/ticket-sales-http.service';
import { CartItem } from '../cart/cartItem';
import {
	AddFanToTicketAndGetPriceRequest,
	CreateFriendlyMatchTicketRequest,
	CreateTicketRequest
} from '../../../../view-models/create-ticket.request';
import { ApiResponse } from '../../../../view-models/api-response';
import { map, switchMap, tap } from 'rxjs/operators';
import { AcquiringResponse } from '../../../../view-models/acquiring-response';
import { PortalServiceHttp } from '../../../../http-services/portal-http-service';
import { FriendlyMatchTicketsResponse } from '../../../../view-models/friendly-match-ticket-response';
import { FanInformation } from '../../models/season-ticket-info';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentStatusEnum } from '../../models/payment-status-enum';
import { PortalSettingsResponse } from '../../../../view-models/portal-management/portal-settings-response';
import { PaymentSystems } from 'src/app/modules/core/team-configuration/Enum/PaymentSystems';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { InstalmentPaymentHttpService } from 'src/app/http-services/instalment-payment-http.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TicketSaleService {
	private languages = [
		{name: 'en-GB', code: 'en'},
		{name: 'el-CY', code: 'cy'},
		{name: 'ru-RU', code: 'ru'},
	];

	constructor(
		private cartService: CartService,
		private salesServiceHttpService: SalesHttpService,
		private portalServiceHttpService: PortalServiceHttp,
		private toastService: ToastService,
    	private instalmentPaymentHttpService: InstalmentPaymentHttpService,
		private translateService: TranslateService,
	) {	}

	public getTicketsInCart(saleId: number) {
		const cart = this.cartService.getCart();
		if (!cart.items.length) {
			this.salesServiceHttpService.getTicketsInCart(saleId).subscribe(cartTicketsResult => {
				if (cartTicketsResult.isSuccess) {
					const tickets = cartTicketsResult.data;
					if (tickets[0]) {
						cart.saleId = tickets[0].saleId;
					}
					tickets.results.forEach(ticket => {
						const item = new CartItem(ticket);
						item.fanInformation = ticket.fan;
						this.cartService.addToCart(item);
					});
				}

			});
		}
	}

	public buyTicket(request: CreateTicketRequest, sectionDescription: string): Observable<ApiResponse<ITicket>> {
		// because season ticket does not have an eventId, database query depends on join with Events table
		if (request.eventId === 0) {
			request.eventId = null;
		}

		return this.salesServiceHttpService.addTicketToBasket(request)
			.pipe(map(result => {
				if (result.isSuccess) {
					result.data.productType = request.productType;
					result.data.productId = request.productId;
					result.data.sectionDescription = sectionDescription;
					this.cartService.addToCart(new CartItem(result.data));
					return result;
				}
			}));
	}

	public buyFriendlyMatchTicket(request: CreateFriendlyMatchTicketRequest, sectionDescription: string): Observable<ApiResponse<FriendlyMatchTicketsResponse[]>> {
		return this.salesServiceHttpService.addFriendlyMatchTicketToBasket(request)
			.pipe(map(result => {
				if (result.isSuccess) {
					const ticketInfo = result.data[0];
					const cartItem = new CartItem(ticketInfo.ticket);
					cartItem.ticket.productType = request.productTypeId;
					cartItem.ticket.productId = request.productId;
					cartItem.ticket.sectionDescription = sectionDescription;
					cartItem.ticket.isFriendlyMatchTicket = true;

					let fanInfo = new FanInformation();
					fanInfo.firstName = ticketInfo.fan.fanName;
					fanInfo.lastName = ticketInfo.fan.fanSurname;
					fanInfo.ticketPrice = ticketInfo.fan.price;
					fanInfo.isChild = false;
					cartItem.fanInformation = fanInfo;
					this.cartService.addToCart(cartItem);
					return result;
				}
			}));
	}

	public addFanToTicketAndGetPrice(request: AddFanToTicketAndGetPriceRequest) {
		return this.salesServiceHttpService.addFanToTheTicketAndGetPrice(request)
			.pipe(map(result => {
				if (result.isSuccess) {
					this.cartService.setFanToCartItem(result.data, request.soldTicketId);
				} else if (+result.statusCode != 500){
					this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));

				}
				return result;
			}));
	}
	public clearCart() {
		let cart = this.cartService.getCart();
		if (!cart) return;
		if (cart.isCheckedOutAndPaid) {
			this.cartService.clearCart();
			return;
		}
		let ticketsToDelete = cart.items;
		this.cartService.clearCart();
		if (!ticketsToDelete || ticketsToDelete.length === 0) return;
		let ticketIds = this.getUniqueTicketIdsFromCartItems(ticketsToDelete);
		ticketIds.forEach(item => {
			if (item) {
				this.removeTicket(item).subscribe(r => {
					//If the sale is complete, this will not remove it.
				});
			}
		});
	}

	private getUniqueTicketIdsFromCartItems(ticketsToDelete: CartItem[]) {
		return [...new Set(ticketsToDelete.map(t => t.ticket.soldTicketId))];
	}

	public removeTicket(ticketId: number): Observable<ApiResponse<any>> {
		if (ticketId == -1) return of(new ApiResponse());
		if(this.cartService.isCartLockedForChangesFromCurrentTab()) {
			this.toastService.showError(new ToastMsg({ detail: this.translateService.instant('cartLocked')}));
			return of(new ApiResponse());
		}
		return this.salesServiceHttpService.removeTicketFromCart(ticketId)
			.pipe(map(result => {
				if (result.isSuccess) {
					this.cartService.removeFromCart(ticketId);
				}
				return result;
			}));
	}

	public checkout(deliveryAddress: string, includeDelivery: boolean, acquiringSystem: string, isCheckoutWithInstalment:boolean) {
		const cart = this.cartService.getCart();
		const saleId = cart.saleId;
		const items = cart.items;
		const productType = items[0].ticket.productType; //as cart accept one product type sale at a time
		const orderId = `${saleId}_${Date.now()}`;
		const ticketIdsWithMembershipFee: Array<Object> = [];
		this.cartService.setOrderId(orderId);
		const checkoutPipe = this.portalServiceHttpService.GetPortalSettings().pipe(
			switchMap(portalSettingsResult => {
				if (!portalSettingsResult.isSuccess) {
					return of(null);
				}
				let paymentSystem = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'DEFAULT_PAYMENT_SYSTEM';
				});
				if (paymentSystem == null) {
					paymentSystem = new PortalSettingsResponse();
					paymentSystem.code = 'DEFAULT_PAYMENT_SYSTEM';
					paymentSystem.value = '1'; //Default payment system
				}
				cart.ticketIdsWithMembershipFee.forEach(t => {
					ticketIdsWithMembershipFee.push({
						soldTicketId: t,
						paymentSystemId: parseInt(paymentSystem.value, 0)
					});
				});
				const request: any[] = [];
				items.forEach(item => {
					request.push({
						amount: item.fanInformation.ticketPrice,
						paymentSystemId: parseInt(paymentSystem.value, 0),
						soldTicketId: getTicketId(item.ticket),
						saleId: saleId,
						status: PaymentStatusEnum.SALE
					});
				});
				return of({ request });
			}),
			switchMap(result => {
				if (result.request == null || isCheckoutWithInstalment) {
					return of(null);
				}
				return this.salesServiceHttpService.createPaymentDetails(result.request)
					.pipe(map(paymentDetailsResult => {
						return { ...paymentDetailsResult };
					}));
			}),
			switchMap(result => {
				let productCheckout =  {
					saleId: saleId,
					productType: productType,
					customDeliveryAddress: deliveryAddress,
					isDeliveryFeeIncluded: includeDelivery,
					TicketsWithMembershipFees: ticketIdsWithMembershipFee,
					RequestLanguage: this.getCurrentSelectedLanguage()
				};
				if (isCheckoutWithInstalment) {
					return this.instalmentPaymentHttpService
					.initPaymentByInstalment(orderId, productCheckout);
				} 
				else if (result.isSuccess) {
					const acquiringSystemType = acquiringSystem.toUpperCase();
					this.cartService.lockCart();
					return this.checkoutNew(orderId, productCheckout, acquiringSystemType);
				}
				return of(null);
			})
		);
		return checkoutPipe;
	}

	public getAcquiringResponse(orderId: string): Observable<ApiResponse<AcquiringResponse>> {
		return this.salesServiceHttpService.getAcquiringResult(orderId).pipe(map(result => {
			return result;
		}));
	}

	public deleteSale(saleId: number): Observable<ApiResponse<any>> {
		return this.salesServiceHttpService.deleteSale(saleId);
	}

	public checkoutNew(orderId, request, paymentType: string): Observable<ApiResponse<string>> {
		switch (paymentType) {
			case PaymentSystems.JCC:
				return this.salesServiceHttpService.checkoutJcc(orderId, request);
			case PaymentSystems.PBT:
				return this.salesServiceHttpService.checkoutPbt(orderId, request);
			case PaymentSystems.Six:
				return this.salesServiceHttpService.checkoutSix(orderId, request);
			case PaymentSystems.Viva:
				return this.salesServiceHttpService.checkoutViva(request);
		}
	}

	private getCurrentSelectedLanguage() {
		return this.languages.find(({ code }) => code === this.translateService.currentLang).name;
	}
}
