import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ITicket} from '../modules/main-module/services/sales/i-ticket';
import {
	AddFanToTicketAndGetPriceRequest,
	CreateFriendlyMatchTicketRequest,
	CreateTicketRequest,
	FanAndPriceResponse
} from '../view-models/create-ticket.request';
import {ApiResponse} from '../view-models/api-response';
import {AcquiringResponse} from '../view-models/acquiring-response';
import {PaymentSystemResponse} from '../view-models/payment-system-response';
import {FriendlyMatchTicketsResponse} from '../view-models/friendly-match-ticket-response';
import {ListResponse} from '../view-models/list-response';
import {SaleTotalRequest} from '../view-models/sale-total-request';
import {SaleTotalResponse} from '../view-models/sale-total-response';
import {NgxSpinnerService} from 'ngx-spinner';
import {fromPromise} from 'rxjs/internal-compatibility';
import {tap} from 'rxjs/operators';
import {
	CheckSeasonTicketsTransferredRequest,
	RenewSeasonTicketRequest,
	RenewSoldTicketResponse,
	SoldTicketDisplayResponse,
	TransferTicketRequest
} from '../view-models/renew-seasonticket';
import {NationalIdRequest} from '../view-models/NationalIdRequest';
import {FanInformation, FanShortInformation} from '../modules/main-module/models/season-ticket-info';
import {PrintTicketResponse} from '../view-models/print-ticket-response';
import { SimpleTicketData } from '../modules/shared-module/components/cps-simple-tickets/common/models/ticket-data';
import { FindPreviousSeasonTicketForRenewalRequest } from '../view-models/find-previous-season-ticket-request';
import { VerifyPaymentRequest } from '../view-models/verify-payment-request';
import { VivaAcquiringResponse } from '../view-models/viva-acquiring-response';

@Injectable()
export class SalesHttpService {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient, private spinner: NgxSpinnerService) {

	}

	public getTicketsInCart(saleId: number): Observable<ApiResponse<ListResponse<ITicket>>> {
		return this.http.post<ApiResponse<ListResponse<ITicket>>>(`${this.coreApiKey}/Ticketing/SoldTickets/GetSoldTickets`, {saleId: saleId});
	}

	public addTicketToBasket(request: CreateTicketRequest): Observable<ApiResponse<ITicket>> {
		return this.http.post<ApiResponse<ITicket>>(`${this.coreApiKey}/Ticketing/SoldTickets/CreateSoldTicket`, request);
	}

	public addFriendlyMatchTicketToBasket(request: CreateFriendlyMatchTicketRequest):
		Observable<ApiResponse<FriendlyMatchTicketsResponse[]>> {
		return this.http.post<ApiResponse<FriendlyMatchTicketsResponse[]>>(
			`${this.coreApiKey}/ticketing/soldTickets/CreateFriendlyMatchTickets`, request);
	}

	public addFanToTheTicketAndGetPrice(request: AddFanToTicketAndGetPriceRequest): Observable<ApiResponse<FanAndPriceResponse>> {
		return this.http.post<ApiResponse<FanAndPriceResponse>>(`${this.coreApiKey}/Ticketing/CustomerSales/AssignTicketToFan`, request);
	}

	public removeTicketFromCart(ticketId: number): Observable<ApiResponse<any>> {
		return this.http.post<ApiResponse<any>>(`${this.coreApiKey}/Ticketing/SoldTickets/DeleteOneSoldTicket`, {soldTicketId: ticketId});
	}

	public createPaymentDetails(request: any[]): Observable<ApiResponse<any>> {
		return this.http.post<ApiResponse<any>>(`${this.coreApiKey}/Ticketing/PaymentDetails/CreatePaymentDetails`, request);
	}

	public checkout(orderId, request): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/Ticketing/CustomerSales/startPaymentProcess/${orderId}`, request);
	}

	public checkoutJcc(orderId, request): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/ticketing/customerSales/jcc/startPaymentProcess/${orderId}`, request);
	}

	public checkoutPbt(orderId, request): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/ticketing/customerSales/pbt/startPaymentProcess/${orderId}`, request);
	}

	public checkoutSix(orderId, request): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/ticketing/customerSales/six/startPaymentProcess/${orderId}`, request);
	}

	public getAcquiringResult(orderId: string): Observable<ApiResponse<AcquiringResponse>> {
		return this.http.get<ApiResponse<AcquiringResponse>>(`${this.coreApiKey}/ticketing/customerSales/getExternalTransactionResponse/${orderId}`);
	}

	public checkoutViva(request): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/ticketing/customerSales/viva/startPaymentProcess`, request);
	}

	public deleteSale(saleId: number): Observable<ApiResponse<any>> {
		return this.http.post<ApiResponse<any>>(`${this.coreApiKey}/Ticketing/Sales/DeleteSale`, {saleId: saleId});
	}

	public getPaymentSystems(): Observable<ApiResponse<PaymentSystemResponse[]>> {
		return this.http.get<ApiResponse<PaymentSystemResponse[]>>(`${this.coreApiKey}/Ticketing/PaymentSystems/GetDetailedPaymentSystemsByPortal`);
	}

	public getSaleTotal(request: SaleTotalRequest): Observable<ApiResponse<SaleTotalResponse>> {
		this.spinner.show();
		return this.http.get<ApiResponse<SaleTotalResponse>>(
			`${this.coreApiKey}/ticketing/customerSales/getSaleTotal`, {params: request as any})
			.pipe(tap(() => {
				this.spinner.hide();
			}));
	}

	public isFanAChild(nationalIdRequest: NationalIdRequest): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/ticketing/IdentityManagement/customer/isFanAChild`, nationalIdRequest);
	}

	public locateOldTicket(request: FindPreviousSeasonTicketForRenewalRequest): Observable<ApiResponse<SoldTicketDisplayResponse>> {
		return this.http.post<ApiResponse<SoldTicketDisplayResponse>>(`${this.coreApiKey}/ticketing/customerSales/FindPreviousSeasonTicketForRenewal`, request);
	}

	public renewSeasonTicket(renewRequest: RenewSeasonTicketRequest): Observable<ApiResponse<RenewSoldTicketResponse>> {
		return this.http.post<ApiResponse<RenewSoldTicketResponse>>(
			`${this.coreApiKey}/ticketing/customerSales/RenewSeasonTicket`, renewRequest);
	}

	public getInformationAboutFan(nationalId: string, fanCardId: string): Observable<ApiResponse<FanShortInformation>> {
		return this.http.get<ApiResponse<FanShortInformation>>(
			`${this.coreApiKey}/ticketing/customerSales/getInformationAboutFan?nationalId=${nationalId}&fanCardId=${fanCardId}`);
	}

	public transferTicket(request: TransferTicketRequest): Observable<ApiResponse<SimpleTicketData>> {
		return this.http.post<ApiResponse<SimpleTicketData>>(
			`${this.coreApiKey}/ticketing/customerSales/TransferSeasonTicket`, request);
	}

	public checkSeasonTicketsTransferred(request: CheckSeasonTicketsTransferredRequest): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(
			`${this.coreApiKey}/ticketing/customerSales/CheckSeasonTicketsTransferred`, request);
	}

	public verifyVivaPayment(request: VerifyPaymentRequest): Observable<ApiResponse<VivaAcquiringResponse>> {
		return this.http.post<ApiResponse<VivaAcquiringResponse>>(`${this.coreApiKey}/ticketing/customerSales/viva/VerifyPayment`, request);
	}
}
