import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TeamService} from '../services/team.service';

@Injectable()
export class PortalIdInterceptor implements HttpInterceptor {
	constructor(private teamService: TeamService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.teamService.teamTemplate && this.teamService.teamTemplate.teamId) {
			request = request.clone({
				setHeaders: {
					PortalCode: this.teamService.teamTemplate.teamId.toLowerCase(),
					PortalId: this.teamService.teamTemplate.portalId.toString()
				}
			});
		}
		return next.handle(request);
	}

}
