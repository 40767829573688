import { SuccessComponent } from './pages/registration-page/success/success.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthPageComponent} from './pages/auth-page/auth-page.component';
import {UnauthLayoutComponent} from '../layout-module/components/unauth-layout/unauth-layout.component';
import {TeamTemplateResolver} from '../core/team-configuration/team-template-resolver.service';
import {RegistrationPageComponent} from './pages/registration-page/registration-page.component';
import {UnAuthenticatedGuard} from '../core/guards/unauth-guard';
import {ForgotPasswordPageComponent} from './pages/forgot-password-page/forgot-password-page.component';
import {SetPasswordPageComponent} from './pages/set-password-page/set-password-page.component';
import { RegistrationComponent } from './pages/registration-page/registration/registration.component';
import { ProfileInfoComponent } from './pages/registration-page/profile-info/profile-info.component';
import { AddressInformationComponent } from './pages/registration-page/address-information/address-information.component';

const routes = [
	{
		path: '',
		component: UnauthLayoutComponent,
		resolve: {
			teamTemplate: TeamTemplateResolver
		}, children: [
			{path: '', component: AuthPageComponent, data: {title: 'Login page'}, canActivate: [UnAuthenticatedGuard]},
			{path: 'login', component: AuthPageComponent, data: {title: 'Login page'}, canActivate: [UnAuthenticatedGuard]},
			{
				path: 'steps', component: RegistrationPageComponent, data: {title: 'Registration page'},
				children: [
					{ path: '', redirectTo: 'registration', pathMatch: 'full' },
					{ path: 'registration', component: RegistrationComponent },
					{ path: 'profile-info', component: ProfileInfoComponent },
					{ path: 'address-information', component: AddressInformationComponent },
				]
			},
			{path: '', redirectTo: '/steps/registration', pathMatch: 'full'},
			{path: 'success', component: SuccessComponent, data: {title: 'Successful Registration'}, canActivate: [UnAuthenticatedGuard]},
			{path: 'forgot-password', component: ForgotPasswordPageComponent, data: {title: 'Forgot password'}},
			{path: 'set-password', component: SetPasswordPageComponent, data: {title: 'Set password'}, canActivate: [UnAuthenticatedGuard]}
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthRoutingModule {
}
