export class AddressResponse {

	public addressId: number;

	public country: string;

	public district: string;

	public municipality: string;

	public postalCode: string;

	public street: string;

	constructor(data?: AddressResponse) {

		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CountryResponse {

	public countryId: number;

	public name: string;

	public code: string;

	public phoneCode?: number;

	public IsActive: boolean;

	public street: string;
}


