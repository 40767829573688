import { SuccessComponent } from './pages/registration-page/success/success.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { UnauthLayoutComponent } from '../layout-module/components/unauth-layout/unauth-layout.component';
import { TeamTemplateResolver } from '../core/team-configuration/team-template-resolver.service';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { UnAuthenticatedGuard } from '../core/guards/unauth-guard';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { SetPasswordPageComponent } from './pages/set-password-page/set-password-page.component';
import { RegistrationComponent } from './pages/registration-page/registration/registration.component';
import { ProfileInfoComponent } from './pages/registration-page/profile-info/profile-info.component';
import { AddressInformationComponent } from './pages/registration-page/address-information/address-information.component';
const ɵ0 = { title: 'Login page' }, ɵ1 = { title: 'Login page' }, ɵ2 = { title: 'Registration page' }, ɵ3 = { title: 'Successful Registration' }, ɵ4 = { title: 'Forgot password' }, ɵ5 = { title: 'Set password' };
const routes = [
    {
        path: '',
        component: UnauthLayoutComponent,
        resolve: {
            teamTemplate: TeamTemplateResolver
        }, children: [
            { path: '', component: AuthPageComponent, data: ɵ0, canActivate: [UnAuthenticatedGuard] },
            { path: 'login', component: AuthPageComponent, data: ɵ1, canActivate: [UnAuthenticatedGuard] },
            {
                path: 'steps', component: RegistrationPageComponent, data: ɵ2,
                children: [
                    { path: '', redirectTo: 'registration', pathMatch: 'full' },
                    { path: 'registration', component: RegistrationComponent },
                    { path: 'profile-info', component: ProfileInfoComponent },
                    { path: 'address-information', component: AddressInformationComponent },
                ]
            },
            { path: '', redirectTo: '/steps/registration', pathMatch: 'full' },
            { path: 'success', component: SuccessComponent, data: ɵ3, canActivate: [UnAuthenticatedGuard] },
            { path: 'forgot-password', component: ForgotPasswordPageComponent, data: ɵ4 },
            { path: 'set-password', component: SetPasswordPageComponent, data: ɵ5, canActivate: [UnAuthenticatedGuard] }
        ]
    },
];
export class AuthRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
