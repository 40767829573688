var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng-lts/api';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { CartService } from 'src/app/modules/main-module/services/cart/cart.service';
import { PortalService } from 'src/app/modules/main-module/services/portals/portal-service';
import { TicketSaleService } from 'src/app/modules/main-module/services/sales/ticket-sale.service';
import { LayoutShared } from '../../shared/layout.shared';
import { AuthPageComponent } from '../../../../auth-module/pages/auth-page/auth-page.component';
import { GetJsonDataService } from 'src/app/modules/core/services/get-json-data.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';
export class MainHeaderComponent extends LayoutShared {
    constructor(templateService, translateService, route, authenticationService, router, pageTitle, cartService, toastService, portalService, renderer, saleService, getJsonDataService, confirmationService) {
        super(translateService, templateService);
        this.templateService = templateService;
        this.translateService = translateService;
        this.route = route;
        this.authenticationService = authenticationService;
        this.router = router;
        this.pageTitle = pageTitle;
        this.cartService = cartService;
        this.toastService = toastService;
        this.portalService = portalService;
        this.renderer = renderer;
        this.saleService = saleService;
        this.getJsonDataService = getJsonDataService;
        this.confirmationService = confirmationService;
        this.isUserEditOptionsDisplayed = false;
        this.navbarCollapsed = true;
        this.socialMedia = {};
        this.isCompany = false;
        this.display = false;
        this.isLoggedIn = false;
        this.styleColors = '';
        this.selectedPage = 'home';
        this.team = route.snapshot.data['teamTemplate'];
        this.router.events.subscribe((event) => {
            if (event.snapshot && event.snapshot.data.title) {
                this.title = event.snapshot.data.title;
                const localized = this.translateService.instant(this.title);
                pageTitle.setTitle(localized);
            }
        });
        this.template = templateService.teamTemplate;
        this.isShowLoyaltyPage = portalService.canShowLoyaltyPage(this.template.portalId);
        this.canShowPricingRulesPage = portalService.canShowPricingRulesPage(this.template.portalId);
    }
    get getSelectedPage() {
        return this.selectedPage;
    }
    ngOnInit() {
        this.onLanguageChange();
        if (this.isUserLoggedIn()) {
            this.loadPortalSettings();
            this.showMenuHeaderBar();
            this.loadCart();
            this.checkToClosePopUpMenu();
            this.loadUserDropdownItems();
        }
        this.teamTemplateColors();
    }
    checkToClosePopUpMenu() {
        this.renderer.listen('window', 'click', (e) => {
            if (this.toggleButton && e.target !== this.toggleButton.nativeElement) {
                this.isUserEditOptionsDisplayed = false;
            }
        });
    }
    isUserLoggedIn() {
        this.isLoggedIn = this.authenticationService.isLoggedInPortal(this.team.teamId) ? true : false;
        return this.isLoggedIn;
    }
    displayEditOptions() {
        this.isUserEditOptionsDisplayed = !this.isUserEditOptionsDisplayed;
    }
    emptyCartBeforeRedirect(urlPage) {
        this.display = false;
        let doRoute = () => {
            this.router.navigate([urlPage], { relativeTo: this.route.parent });
            this.selectedPage = urlPage;
        };
        const cart = this.cartService.getCart();
        if (!cart || cart.items.length == 0) {
            doRoute();
            return;
        }
        //at least one ticket in the cart, opens clear cart dialog
        this.clearCart(doRoute);
    }
    loadUserDropdownItems() {
        this.items = [
            {
                label: this.translateService.instant('layout.menu.editProfile'),
                icon: 'pi pi-user-edit',
                routerLink: 'profile',
            },
            {
                label: this.translateService.instant('layout.menu.changePassword'),
                icon: 'pi pi-lock',
                routerLink: 'change-password',
            },
            {
                separator: true
            },
            {
                label: this.translateService.instant('layout.menu.logout'),
                icon: 'pi pi-sign-out',
                command: () => {
                    this.logout();
                },
            }
        ];
    }
    showMenuHeaderBar() {
        this.lowerNav.nativeElement.classList.add(this.team.teamId);
    }
    onLanguageChange() {
        this.translateService.onLangChange.subscribe(r => {
            this.team = this.route.snapshot.data['teamTemplate'];
            const data = this.getDataRecursively(this.route.snapshot);
            const localized = this.translateService.instant(data.title);
            this.pageTitle.setTitle(localized);
            this.title = localized;
        });
    }
    loadPortalSettings() {
        this.portalSettings = this.portalService.getPortalSettings();
    }
    loadCart() {
        this.cartService.loadCart(this.teamCode).subscribe(r => { });
    }
    getDataRecursively(route) {
        let dataMap = Object.assign({}, route.data);
        for (let i = 0; i < route.children.length; i++) {
            dataMap = Object.assign({}, dataMap, this.getDataRecursively(route.children[i]));
        }
        return dataMap;
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            this.authenticationService.logout().subscribe(r => {
                location.href = `/${this.template.teamId}/login`;
                this.portalService.resetPortalSettings();
            });
        });
    }
    get isLoginPage() {
        return this.route.children[0].component == AuthPageComponent;
    }
    teamTemplateColors() {
        const style = document.createElement('style');
        this.getJsonDataService.getTeamThemeColors(this.team.teamId).subscribe(data => {
            data.colors.forEach((element) => {
                Object.entries(element).forEach((array) => {
                    typeof array[0] != 'object' ? this.styleColors += '--team-' + array[0] + ':' + array[1] + ';' : '';
                });
            });
            style.innerHTML = ':root {' + this.styleColors + '}';
            document.head.appendChild(style);
        });
    }
    register() {
        this.router.navigate(['steps'], { relativeTo: this.route.parent });
    }
    clearCart(callback) {
        let ticketCount = this.cartService.getCart().items.length;
        if (ticketCount == 0) {
            this.toastService.showError(new ToastMsg({ detail: 'The cart is empty' }));
            return;
        }
        this.confirmationService.confirm({
            message: ticketCount == 1 ? this.translateService.instant('layout.menu.clear_cart_confirmation_content_single')
                : this.translateService.instant('layout.menu.clear_cart_confirmation_content_multiple', { ticketCount: ticketCount }),
            header: this.translateService.instant('layout.menu.clear_cart_confirmation_title'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.saleService.clearCart();
                if (callback)
                    callback();
                this.toastService.showSuccess(new ToastMsg({ detail: "Cleared Cart Successfully" }));
            }
        });
    }
    login() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.router.navigate([`/${this.team.teamId}/main`]);
        });
    }
}
