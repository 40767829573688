import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
const ɵ0 = environment.apiUrl, ɵ1 = environment.coreUrl;
const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: 'BASE_API_URL', useValue: ɵ0 },
    { provide: 'CORE_API_URL', useValue: ɵ1 }
];
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser(providers).bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.log(err));
export { ɵ0, ɵ1 };
