<div class="language-switcher pl-2">
	<!-- <language-switcher/> -->
	<p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name" (onChange)="onChange($event)">
		<ng-template pTemplate="selectedItem">
			<div class="lang-item d-flex align-items-center" *ngIf="selectedLanguage">
				<img [src]="'assets/img/flag-' + selectedLanguage.code + '.svg'" width="22" height="22" alt="">
				<span>{{ selectedLanguage.name }}</span>
			</div>
		</ng-template>
		<ng-template let-language pTemplate="item" >
			<div class="lang-item d-flex align-items-center">
				<img [src]="'assets/img/flag-' + language.code + '.svg'" width="22" height="22" alt="">
				<span>{{ language.name }}</span>
			</div>
		</ng-template>
	</p-dropdown>
</div>

