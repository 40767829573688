import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-specify-team',
	templateUrl: './specify-team.component.html',
	styleUrls: ['./specify-team.component.scss']
})
export class SpecifyTeamComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
