import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
export class UnAuthenticatedGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        const url = `/${state.url}`;
        const teamId = route.parent.paramMap.get('team');
        const redirectUrl = `${teamId}/main`;
        return this.loadAuthenticationChecker(url, redirectUrl, teamId);
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
    canLoad(route, segments) {
        const url = `/${route.path}`;
        const teamCodePath = segments[0].path;
        const redirectUrl = `${teamCodePath}/main`;
        return this.loadAuthenticationChecker(url, redirectUrl, teamCodePath);
    }
    loadAuthenticationChecker(url, redirectUrl, teamCodePath) {
        if (this.authService.isLoggedInPortal(teamCodePath)) {
            this.router.navigate([redirectUrl]);
            return false;
        }
        return true;
    }
}
UnAuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnAuthenticatedGuard_Factory() { return new UnAuthenticatedGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: UnAuthenticatedGuard, providedIn: "root" });
