import {IFan} from '../services/sales/i-ticket';
import {TicketInfoBase} from './ticket-info-base';


export class SeasonTicketInfo extends TicketInfoBase {

}

export class FanInformation implements IFan {
	public fanCardId: string;
	company: boolean;
	companyEmail: string;
	companyName: string;
	companyPhone: string;
	companyRegVatNo: string;
	customerId: number;
	dateOfBirth: Date;
	email: string;
	firstName: string;
	lastName: string;
	mobile: string;
	nationalId: string;
	phone: string;
	sex: string;
	status: string;
	ticketPrice: number;
	packagePrice: number;
	isChild: boolean;
	isRegisteredFan: boolean;
	simpleTicketId: number;
	displayInfo: string;
}

export class FanShortInformation {
	public fanCardID: string;
	public nationalId: string;
	public displayInfo: string;
}
