import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {uniq} from 'lodash';
import { Dropdown } from 'primeng-lts/dropdown';
import {AddressesServiceHttp} from 'src/app/http-services/addresses-http.service';
import { FormHelpersService } from 'src/app/modules/core/services/form-helpers.service';
import {AddressResponse, CountryResponse} from 'src/app/view-models/address-response';
import {AddressRequest} from '../../../../view-models/address-request';

@Component({
	selector: 'ticketing-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit, AfterViewInit  {
	@Input() public form: FormGroup;
	@Input() public addressData: any;
	@Input() addresses: AddressResponse[];
	public countries: CountryResponse[];
	public isManualAddress: boolean;
	public municipalities: string[];
	public postalCodes: string[];
	public streets: string[];
	public numberAndCharactersRegexpPattern = /^[0-9A-Za-zΑ-Ωα-ωΆά Έέ Ήή Ίί Όό Ύύ ΏώΪϊ Ϋϋ""'\-\-]*$/;
	public selectedCountry: CountryResponse = new CountryResponse();
	public isLoadingCountries: boolean = true;
	public selectedCity: string;
	public selectedMunicipality: string;
	public selectedPostalCode: string;
	public selectedStreet: string;
	private defaultCountryId: number;
	@ViewChild('postcodeDropdown', {
		static: false
	}) dropdown?: Dropdown;

	public get cities() {
		if (this.addresses) {
			return uniq(this.addresses.map(d => d.district));
		}
		return [];
	}

	get formHS() { return this.formHelpersService; }

	constructor(private cd: ChangeDetectorRef,
							private addressesServiceHttp: AddressesServiceHttp,
							private formHelpersService: FormHelpersService) {}


	ngOnInit() {
		this.addressesServiceHttp.getCountries()
			.subscribe(r => {
				this.countries = r.data;
				this.selectedCountry = this.countries.find(c => c.code == 'CY');
				this.isLoadingCountries = false;
			});

		if (!this.addresses || this.addresses.length === 0) {
			this.addressesServiceHttp.getAddresses(new AddressRequest('cy'))
				.subscribe(r => {
					this.addresses = r.data.results;
					this.setAddressData();
				});
		}

		this.form.controls['countryId'].valueChanges.subscribe((val) => {
			if (this.countries) {
				this.selectedCountry = this.countries.find(c => c.countryId == val);
				if (this.selectedCountry.code == 'CY') {
					this.isManualAddress = false;
				} else {
					this.isManualAddress = true;

				}
				this.clearAddressFields();
			}
		});


		this.form.controls['district'].valueChanges.subscribe(val => {
			if (this.addresses) {
				this.municipalities = uniq(this.addresses.filter(r => r.district == val).map(d => d.municipality));
				this.postalCodes = [];
				this.streets = [];
			}
		});
		this.form.controls['municipality'].valueChanges.subscribe(val => {
			if (this.addresses) {
				this.postalCodes = uniq(this.addresses.filter(r => r.municipality == val).map(d => d.postalCode)).sort();

				this.streets = [];
			}
		});
		this.form.controls['postalCode'].valueChanges.subscribe(val => {
			if (this.addresses) {
				this.streets = uniq(this.addresses.filter(r => r.postalCode == val).map(d => d.street));
			}
		});


	}

	private clearAddressFields() {
		this.form.controls['district'].reset();
		this.form.controls['municipality'].reset();
		this.form.controls['postalCode'].reset();
		this.form.controls['street'].reset();
		this.form.controls['buildingNumber'].reset();
	}

	private setAddressData() {
		if (this.defaultCountryId) {
			this.form.controls['countryId'].setValue(this.defaultCountryId);
		}

		if (this.addressData) {
			setTimeout(() => {
				this.form.controls['district'].patchValue(this.addressData.district);
				this.form.controls['municipality'].patchValue(this.addressData.municipality);
				this.form.controls['postalCode'].patchValue(this.addressData.postalCode);
				this.form.controls['street'].patchValue(this.addressData.street);
				this.form.controls['buildingNumber'].patchValue(this.addressData.buildingNumber);
			});
		}
	}
	ngAfterViewInit(): void {
		if (this.dropdown) {
		  (this.dropdown.filterBy as any) = {
			split: (_: any) => [(item: any) => item],
		  };
		}
	  }
}
