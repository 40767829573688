import { PortalIdEnum } from '../../models/portal-id-enum';
import { Injectable } from '@angular/core';
import { PortalSettings } from 'src/app/view-models/portal-management/portal-settings';
import { Observable } from 'rxjs';
import { PortalSettingsResponse } from 'src/app/view-models/portal-management/portal-settings-response';
import { ListResponse } from 'src/app/view-models/list-response';
import { PortalServiceHttp } from 'src/app/http-services/portal-http-service';
import { TeamService } from '../../../core/services/team.service';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';

@Injectable({ providedIn: 'root' })
export class PortalService extends TeamTemplateComponent {
	public portalSettings: PortalSettings = {} as PortalSettings;
	private paymentGateway: string;
	public template: ITeamTemplate;
	private portalCode: number;
	private portalSettingsText: string;

	constructor(
		private portalServiceHttp: PortalServiceHttp,
		templateService: TeamService,
		private authenticationService: AuthenticationService,
	) {
		super(templateService);
		this.portalCode = templateService.teamTemplate.portalId;
		this.portalSettingsText = localStorage.getItem(`${this.portalCode}-portal-settings`);
		this.template = templateService.teamTemplate;

		if(this.isUserLoggedIn()) {
			this.initPortalSettings(this.portalCode);
		}
	}

	private isUserLoggedIn() {
		return this.authenticationService.isLoggedInPortal(this.template.teamId) ? true : false;
	}

	private initPortalSettings(portalCode: number) {
		this.portalServiceHttp.GetPortalSettings().subscribe(portalSettingsResult => {
			if (portalSettingsResult.isSuccess) {
				const ShowSeasonTicketsPage = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'HideSeasonTicketsPage';
				});
				const showCancellationPolicyResult = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'HideConflictResolutionPolicy';
				});
				const showConflictResPolicyResult = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'HideConflictResolutionPolicy';
				});
				const showFooterGuide = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'ShowFooterGuide';
				});
				const showRenewSeasonTicketResult = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'ShowRenewSeasonTicket';
				});
				const showMarketingTooltip = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'ShowMarketingTooltip';
				});
				const paymentGateway = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'CUSTOMER_PAYMENT_GATEWAY';
				});
				const ShowMembershipPopup = portalSettingsResult.data.results.find(ps => {
					return ps.code === 'ShowMembershipPopup';
				});

				this.portalSettings.canShowSeasonTicketsPage = ShowSeasonTicketsPage ? ShowSeasonTicketsPage.value == 'true' : true;
				this.portalSettings.showCancellationPolicy = showCancellationPolicyResult ? showCancellationPolicyResult.value == 'false' : false;
				this.portalSettings.showConflictResPolicy = showConflictResPolicyResult ? showConflictResPolicyResult.value == 'false' : false;
				this.portalSettings.showRenewSeasonTicket = showRenewSeasonTicketResult ? showRenewSeasonTicketResult.value == 'true' : true;
				this.portalSettings.showMarketingTooltip = showMarketingTooltip ? showMarketingTooltip.value == 'true' : false;
				this.portalSettings.showMembershipPopup = ShowMembershipPopup ? ShowMembershipPopup.value == 'true' : false;
				this.portalSettings.paymentGateway = paymentGateway.value;

				localStorage.setItem(`${portalCode}-portal-settings`, JSON.stringify(this.portalSettings));

				this.templateService.setAcquiringSystem(this.portalSettings.paymentGateway);
			}
		});
	}

	resetPortalSettings() {
		this.portalSettings = undefined;
	}

	getPortalSettings() {
		if(this.isUserLoggedIn()) {
			this.initPortalSettings(this.portalCode);
		}
		return this.portalSettings;
	}

	public haveMembershipFee(): boolean {
		return this.portalSettings.showMembershipPopup;
	}
	
	public canShowLoyaltyPage(portalId: number): boolean {
		switch (portalId) {
			case PortalIdEnum.OtherTeam:
				return false;
			case PortalIdEnum.Omonoia:
				return false;
			case PortalIdEnum.Apoel:
				return true;
			case PortalIdEnum.Olympiakos:
				return false;
			case PortalIdEnum.Aek:
				return false;
			case PortalIdEnum.Paphos:
				return false;
			case PortalIdEnum.Paeek:
				return false;
			case PortalIdEnum.Doxa:
				return false;
			case PortalIdEnum.Kop:
				return false;
			default:
				return false;
		}
	}

	public canShowInstalmentsOption(portalId: number): boolean {
		switch (portalId) {
			case PortalIdEnum.OtherTeam:
				return false;
			case PortalIdEnum.Omonoia:
					return false;
			default:
					return false;
		}
	}

	public canShowLegalNoticeOnFooter(portalId: number): boolean {
		switch (portalId) {
			case PortalIdEnum.OtherTeam:
				return false;
			case PortalIdEnum.Apoel:
				return true;
			default:
				return false;
		}
	}

	public canShowPricingRulesPage(portalId: number): boolean {
		switch (portalId) {
			case PortalIdEnum.OtherTeam:
				return false;
			case PortalIdEnum.Omonoia:
				return false;
			case PortalIdEnum.Apoel:
				return false;
			case PortalIdEnum.Olympiakos:
				return false;
			case PortalIdEnum.Aek:
				return false;
			case PortalIdEnum.Paphos:
				return false;
			case PortalIdEnum.Paeek:
				return false;
			case PortalIdEnum.Doxa:
				return false;
			case PortalIdEnum.Kop:
				return false;
			default:
				return false;
		}
	}
}
