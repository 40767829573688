import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../view-models/api-response";
import { InstalmentPaymentResponse } from "../view-models/instalment-payment.response";

@Injectable({
  providedIn: 'root',
})
export class InstalmentPaymentHttpService {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient) {

	}

	public getPaymentInstalments(totalAmount: number): Observable<ApiResponse<InstalmentPaymentResponse[]>> {
		return this.http.get<ApiResponse<InstalmentPaymentResponse[]>>(`${this.coreApiKey}/ticketing/instalments/getTicketInstalments?totalAmount=${totalAmount}`);
	}

	public initPaymentByInstalment(orderId: string, request: any): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(`${this.coreApiKey}/Ticketing/Instalments/StartPaymentByInstalment?orderId=${orderId}`,request);
	}

	public getCurrentUserInstalments(): Observable<ApiResponse<any[]>> {
		return this.http.get<ApiResponse<any[]>>(`${this.coreApiKey}/ticketing/instalments/GetCurrentUserInstalments`);
	}
}
