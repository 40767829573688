<p-toast styleClass="customToast" position="top-right"></p-toast>

<router-outlet></router-outlet>

	<!-- <ngx-spinner bdOpacity=0.8
						 bdColor="rgba(255,255,255,.8)"
						 size="large"
						 color="black"
						 type="line-spin-fade"
						 fullScreen="false">
	<p style="color: black"> {{'loading'|translate}} </p>
</ngx-spinner>
<ngx-loading-bar [color]="'#275EFE'"></ngx-loading-bar> -->
