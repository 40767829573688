import { TeamService } from '../services/team.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/team.service";
export class TeamTemplateResolver {
    constructor(teamService) {
        this.teamService = teamService;
    }
    resolve(route, state) {
        const routeParameter = route.paramMap.get('team');
        const result = this.teamService.getTeamInfoByTemplateName(routeParameter);
        return result;
    }
}
TeamTemplateResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeamTemplateResolver_Factory() { return new TeamTemplateResolver(i0.ɵɵinject(i1.TeamService)); }, token: TeamTemplateResolver, providedIn: "root" });
