<div class="main-container">
	<div *ngIf="!isInvalidLink" class="card form-card p-4 mt-4">
		<form [formGroup]="pForm" (ngSubmit)="pFormSubmit()">
			<div class="form-header mb-2">
				<div class="team-logo text-center">
					<img [src]="logoPath" width="60">
				</div>
				<div class="header-content">
					<h2 class="pt-2 text-uppercase">{{'pages.forgot_password.password_title' | translate}}</h2>
				</div>
			</div>
			<div class="form-content">
				<div class="form-group">
					<label for="newPassword">{{'pages.set_password.fields.newPassword' | translate}}</label>
					<input pInputText type="password" class="form-control" id="newPassword" formControlName="newPassword" placeholder="New password"/>
					<validator-info [model]="pForm.controls.newPassword"></validator-info>
				</div>
				<div class="form-group">
					<label for="passwordConfirm">{{'pages.set_password.fields.confirmPassword' | translate}}</label>
					<input pInputText type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm"
									placeholder="Confirm password"/>
					<validator-info [model]="pForm.controls.passwordConfirm"></validator-info>
				</div>
			</div>
			<div class="button-group">
				<button pButton pRipple type="submit" label="{{'buttons.setPassword'|translate}}" class="p-button-primary w-100"></button>
			</div>
		</form>
	</div>
	<div *ngIf="isInvalidLink" class="alert alert-danger d-flex align-items-center" role="alert">
		<i class="pi pi-exclamation-triangle pr-1"></i>
		<div>
			{{'pages.set_password.invalid_url_message'|translate}}
		</div>
	</div>
</div>
