import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Guid } from '../../../core/types/guid';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { Cart } from './cart';
import { CartItem } from './cartItem';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FanInformation } from '../../models/season-ticket-info';
import { FanAndPriceResponse } from '../../../../view-models/create-ticket.request';
import { IFan, ITicket } from '../sales/i-ticket';
import { SalesHttpService } from '../../../../http-services/ticket-sales-http.service';
import { ProductTypeEnum } from '../../models/product-type-enum';
import { CookieService } from 'ngx-cookie-service';
import { SaleTotalRequest } from '../../../../view-models/sale-total-request';
import { SaleTotalResponse } from '../../../../view-models/sale-total-response';
import { ApiResponse } from '../../../../view-models/api-response';
import { RenewSeasonTicketRequest, RenewSoldTicketResponse, SoldTicketDisplayResponse } from 'src/app/view-models/renew-seasonticket';
import { SaleStatusEnum } from '../../models/sale-status-enum';
import { SoldTicketStatuses } from '../../models/soldTicket-status-enum';
import { NationalIdRequest } from 'src/app/view-models/NationalIdRequest';
import { SingleEventPackageProducts, SingleEventPackageProduct } from 'src/app/view-models/products-response';
import { EventModel } from 'src/app/view-models/event';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { SeasonModel } from '../../models/season-model';
import { FindPreviousSeasonTicketForRenewalRequest } from 'src/app/view-models/find-previous-season-ticket-request';

@Injectable({ providedIn: 'root' })
export class CartService {
	private cart: Cart;
	private cartSubKey: string;
	public onCartChangeEvent: BehaviorSubject<any> = new BehaviorSubject(null);
	private readonly _expiryTime = 10;
	private readonly lockExpiryInMinutes = 10;
	private selectedSeasonTicketTypeId: number = 0;

	constructor(
		private route: ActivatedRoute,
		private saleHttpService: SalesHttpService,
		private toastService: ToastService,
		private cookiesService: CookieService,
		private router: Router
	) {}

	getProductType(): ProductTypeEnum {
		if (this.isEmpty() || !this.cart.items[0].ticket) {
			return ProductTypeEnum.SimpleTicket;
		}
		return this.cart.items[0].ticket.productType;
	}

	public updateProduct(cartItem: CartItem, changeAmount: number = 1): void {
		if (
			this.cart == null
			|| this.cart.singleEventPackageProducts == null
			|| this.cart.singleEventPackageProducts.Products == null
			|| cartItem.fanInformation == null
		) return;
		let products = this.cart.singleEventPackageProducts.Products;
		let product = products.find(p => p.simpleTicketId == cartItem.fanInformation.simpleTicketId);
		if (product) {
			if (product.soldTicketId == cartItem.ticket.soldTicketId) {
				//this ticket has already been counted
				return;
			}
			product.soldTicketId = cartItem.ticket.soldTicketId;
			product.currentCount += changeAmount;
			this.validateProducts();
		}
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
	}
	public validateProducts() {

		if (this.cart == null || this.cart.singleEventPackageProducts == null || this.cart.singleEventPackageProducts.Products == null) {
			return;
		}
		let ticketCount = 0, packageCount = 0, packageCommon = 1;
		this.cart.singleEventPackageProducts.Products.forEach(p => {
			ticketCount += p.currentCount;
			packageCount += p.productCount;
			packageCommon *= p.productCount
		});
		if (ticketCount % packageCount != 0) {
			this.cart.singleEventPackageProducts.IsValid = false;
			return;
		}
		this.cart.singleEventPackageProducts.IsValid = true;
		let item0 = this.cart.singleEventPackageProducts.Products[0];
		let commonRatio = item0.currentCount * packageCommon / item0.productCount;
		this.cart.singleEventPackageProducts.Products.forEach(product => {
			let currentRatio = product.currentCount * packageCommon / product.productCount;
			if (currentRatio != commonRatio) {
				this.cart.singleEventPackageProducts.IsValid = false;
				return;
			}
		});
	}
	public addToCart(cartItem: CartItem): void {
		this.cart.items.push(cartItem);
		cartItem.expiry = this.getExpiryTime(this._expiryTime);
		if (!this.cart.saleId) {
			this.setSaleId(cartItem.ticket.saleId);
		}
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.updateProduct(cartItem, 1);
		this.onCartChangeEvent.next('addToCart');
	}

	public removeFromCart(ticketId: number): void {
		const cartItemIndex = this.cart.items.findIndex(x => getTicketId(x.ticket) === ticketId);
		let removedItem = this.cart.items.splice(cartItemIndex, 1);
		if (!this.cart.items.length) {
			this.clearCart();
		}
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.updateProduct(removedItem[0], -1);
		this.onCartChangeEvent.next('removeFromCart');
	}

	public addMemberShipFeeToTicket(ticketId: number) {
		if (!this.cart.ticketIdsWithMembershipFee.includes(ticketId)) {
			this.cart.ticketIdsWithMembershipFee.push(ticketId);
		}
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('addMemberShipFeeToTicket');
	}

	public removeMemberShipFeeToTicket(ticketId: number) {
		if (this.cart.ticketIdsWithMembershipFee.includes(ticketId)) {
			this.cart.ticketIdsWithMembershipFee.forEach((value, index) => {
				if (value === ticketId) {
					this.cart.ticketIdsWithMembershipFee.splice(index, 1);
				}
			});
		}
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('removeMemberShipFeeToTicket');
	}

	public getSaleTotal(request: SaleTotalRequest): Observable<ApiResponse<SaleTotalResponse>> {
		return this.saleHttpService.getSaleTotal(request);
	}

	public setFanToCartItem(fanInformation: FanAndPriceResponse, soldTicketId: number) {
		const fan = new FanInformation();
		fan.lastName = fanInformation.surname;
		fan.firstName = fanInformation.name;
		fan.dateOfBirth = fanInformation.dob;
		fan.ticketPrice = fanInformation.price;
		fan.fanCardId = fanInformation.id;
		fan.nationalId = fanInformation.cy_id;
		fan.packagePrice = fanInformation.packagePrice;
		fan.isChild = fanInformation.isChild;
		fan.isRegisteredFan = fanInformation.isRegisteredFan;
		fan.simpleTicketId = fanInformation.simpleTicketId;
		const item = this.cart.items.find(d => getTicketId(d.ticket) === soldTicketId);
		this.setFanDispalyName(fan);
		item.fanInformation = fan;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('setFanToCartItem');
	}
	private setFanDispalyName(fan: FanInformation) {
		if (fan.fanCardId) {
			fan.displayInfo = fan.fanCardId;
		} else {
			let length = fan.nationalId.length;
			let kept = 3;
			let removed = length - kept;
			let str = '';
			if (removed > 0) str += "*".repeat(removed);
			str += fan.nationalId.substr(-kept);
			fan.displayInfo = "Child: " + str;
		}

	}

	public setSeason(season: SeasonModel){
		this.cart.season = season;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('setSeason');
	}

	public getSeason(): SeasonModel{
		return this.cart.season
	}

	public clearSeason() {
		delete this.cart.season;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.setSeasonTicketTypeId(0);
		this.onCartChangeEvent.next('clearSeason');
	}

	public setEvent(event: EventModel) {
		this.cart.event = event;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('setEvent');
	}

	public getCart(): Cart {
		return this.cart;
	}

	public getEvent(): EventModel {
		return this.cart.event;
	}

	public getCartItemsEvent(): Number {
		return this.cart.items.length > 0 ? this.cart.items[0].ticket.eventId : 0;
	}

	public clearEvent() {
		delete this.cart.event;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('clearEvent');
	}

	public setOrderId(orderId: string): void {
		this.cart.orderId = orderId;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('setOrderId');
	}

	public isEmpty() {
		return !this.cart.items.length;
	}

	private getExpiryTime(min: number): number {
		const now = new Date();
		const expiryTime = new Date(now.getTime() + min * 60000);
		return +expiryTime;
	}

	public loadCart(teamCode: string): Observable<any> {
		const result$ = new BehaviorSubject<any>(null);
		this.checkCart(teamCode).subscribe(result =>
			result$.next(result)
		);
		setInterval(() => {
			this.checkCart(teamCode).subscribe(result =>
				result$.next(result)
			);
		}
			, 10 * 1000);
		return result$;
	}

	private checkCart(teamCode: string): Observable<Cart> {
		return this.getRouteParameters(this.route.snapshot).pipe(map(r => {
			const identityId = this.cookiesService.get(`identityId-${teamCode}`);
			this.cartSubKey = `${r['team']}-${identityId}`;
			let cart = <Cart>JSON.parse(localStorage.getItem(`${this.cartSubKey}-cart`));
			if (!cart) {
				cart = new Cart(Guid.newGuid());
				localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(cart));
			} else {
				let hasDeletedAnything = false;

				if (cart.items.length>0 && cart.items[0].expiry <= +new Date()) {
					cart.items.forEach((item, index) => {
						cart.items.splice(index, 1);
						hasDeletedAnything = true;
						this.updateProduct(item, -1);
					});
				}

				if (!cart.items.length) {
					if (hasDeletedAnything) {
						cart.saleId = null;
						cart.ticketIdsWithMembershipFee = [];
						cart.isLocked = false;
						cart.LockerTabId = null;
						cart.isCheckedOutAndPaid = false;
						this.toastService.showWarning(new ToastMsg({ detail: 'The cart has been erased due to the limited time of tickets/seat reservation. ' }));
						cart.lockExpiryTime = null;
						this.router.navigate([`${teamCode}/main`]);
					}
				}
				if (hasDeletedAnything) {
					localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(cart));
				}
			}
			this.cart = cart;
			return cart;
		}));
	}

	public loadCartFromServer() {
		if (this.cart.saleId) {
			this.saleHttpService.getTicketsInCart(this.cart.saleId)
				.subscribe(result => {
					if (result.isSuccess) {
						result.data.results.forEach(item => {
							if (this.cart.items.findIndex(d => getTicketId(d.ticket)) === -1) {
								this.addToCart(new CartItem(item));
							}
						});
					}
				});
		}
	}

	public clearCart() {
		this.cart = new Cart(Guid.newGuid());
		this.setSeasonTicketTypeId(0);
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('clearCart');
	}

	public getCartProductId() {
		// return first item product id as cart should not contain multiple products
		return this.cart.items.length > 0 ? this.cart.items[0].ticket.productId : 0;
	}

	public setSEPTicketsBaseCount(value) {
		this.cart.ticketsCountSEP = value;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('SEPChanged');
	}

	public setSingleEventPackageProducts(products: SingleEventPackageProduct[]) {
		this.cart.singleEventPackageProducts = new SingleEventPackageProducts(products);
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
		this.onCartChangeEvent.next('SEPChanged');
	}

	public isValidSEPSale(): boolean {
		if (this.cart.items[0].ticket.productType === ProductTypeEnum.SingleEventPackage) {
			if (this.cart.items.length % this.cart.ticketsCountSEP !== 0) {
				return false;
			}
		}
		return true;

	}

	public getTargetPackagesCount() {
		return Math.floor((this.cart.items.length / this.cart.ticketsCountSEP) + 1);
	}

	public updateTicketFanRegisterationInfo(soldTicketId: number, customerId: number) {
		const item = this.cart.items.find(d => getTicketId(d.ticket) === soldTicketId);
		if (item) {
			item.fanInformation.isRegisteredFan = true;
			item.fanInformation.customerId = customerId;
			this.onCartChangeEvent.next('updateFanRegistrationInfo');
		}
	}

	public setTicketInformationToCartItem(ticket: ITicket, fan: IFan) {
		let item = this.cart.items.find(d => getTicketId(d.ticket) === ticket.soldTicketId);
		if (!item) {
			item = this.cart.items.find(d => getTicketId(d.ticket) === -1);
		}

		if (item) {

			item.ticket.companyRegNo = ticket.companyRegNo;
			item.ticket.companyVatNo = ticket.companyRegNo;
			item.ticket.customerId = ticket.customerId;
			item.ticket.description = ticket.description;
			item.ticket.discountNote = ticket.discountNote;
			item.ticket.discountReasonId = ticket.discountReasonId;
			item.ticket.fan = fan;
			item.ticket.fanCardId = ticket.fanCardId;
			item.ticket.fanDob = ticket.fanDob;
			item.ticket.fanIdNumber = ticket.fanIdNumber;
			item.ticket.fanName = ticket.fanName;
			item.ticket.fanSurname = ticket.fanSurname;
			item.ticket.isChild = ticket.isChild;
			item.ticket.isFriendlyMatchTicket = false;
			item.ticket.isPartialPayment = ticket.isPartialPayment;
			item.ticket.koaRule = ticket.koaRule;
			item.ticket.newPrice = ticket.newPrice;
			item.ticket.order = ticket.order;
			item.ticket.originalPrice = ticket.originalPrice;
			item.ticket.packageId = 0;
			item.ticket.prereservedSaleId = ticket.prereservedSaleId;
			item.ticket.previousTicketId = ticket.previousTicketId;
			item.ticket.productId = ticket.productId;
			item.ticket.productSaleId = ticket.productSaleId;
			item.ticket.productType = ProductTypeEnum.SeasonTicket;
			item.ticket.saleId = ticket.saleId;
			item.ticket.saleStatus = SaleStatusEnum.New;
			item.ticket.season = ticket.season;
			item.ticket.seatId = ticket.seatId;
			item.ticket.seatNumber = ticket.seatNumber;
			item.ticket.seatRow = ticket.seatRow;
			item.ticket.sectionDescription = ticket.sectionDescription;
			item.ticket.sectionId = ticket.sectionId;
			item.ticket.simpleTicketId = ticket.simpleTicketId;
			item.ticket.soldTicketId = ticket.soldTicketId;
			item.ticket.specialProductId = ticket.specialProductId;
			item.ticket.standDescription = ticket.standDescription;
			item.ticket.standId = ticket.standId;
			item.ticket.status = SoldTicketStatuses.None;
			item.ticket.ticketId = ticket.soldTicketId;
			item.ticket.venueId = ticket.venueId;
			item.ticket.seasonTicketTypeId = ticket.seasonTicketTypeId;
			this.onCartChangeEvent.next('setTicketInformationToCartItem');
		}


	}

	public updateTicketWithRenewInformation(item: CartItem) {
		this.setSaleId(item.ticket.saleId);
		this.setTicketInformationToCartItem(item.ticket, item.fanInformation);
		this.updateTicketFanRegisterationInfo(item.ticket.soldTicketId, item.fanInformation.customerId);
		let fan = new FanAndPriceResponse();
		fan.customerId = item.fanInformation.customerId;
		fan.dob = item.fanInformation.dateOfBirth;
		fan.email = item.fanInformation.email;
		fan.id = item.fanInformation.fanCardId;
		fan.name = item.fanInformation.firstName;
		fan.isChild = item.fanInformation.isChild;
		fan.isRegisteredFan = item.fanInformation.isRegisteredFan;
		fan.surname = item.fanInformation.lastName;
		fan.cy_id = item.fanInformation.nationalId;
		fan.packagePrice = 0;
		fan.price = item.fanInformation.ticketPrice;
		this.setFanToCartItem(fan, item.ticket.soldTicketId);
	}

	public isFanAChild(nationalId: string): Observable<ApiResponse<boolean>> {
		const request = new NationalIdRequest();
		request.NationalId = nationalId;
		return this.saleHttpService.isFanAChild(request);
	}


	public locateOldTicket(request: FindPreviousSeasonTicketForRenewalRequest): Observable<ApiResponse<SoldTicketDisplayResponse>> {
		return this.saleHttpService.locateOldTicket(request);
	}

	public renewSeasonTicket(renewRequest: RenewSeasonTicketRequest): Observable<ApiResponse<RenewSoldTicketResponse>> {
		renewRequest.seasonTicketTypeId = this.getSeasonTicketTypeId();
		return this.saleHttpService.renewSeasonTicket(renewRequest);
	}

	private getRouteParameters(route: ActivatedRouteSnapshot) {
		const result = this.getParamsRecursively(route);
		return of(result);
	}

	private getParamsRecursively(route: ActivatedRouteSnapshot) {
		let paramsMap = { ...route.params };
		for (let i = 0; i < route.children.length; i++) {
			paramsMap = { ...paramsMap, ...this.getParamsRecursively(route.children[i]) };
		}
		return paramsMap;
	}

	private setSaleId(saleId: number): void {
		this.cart.saleId = saleId;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
	}

	public setCheckoutAndPaidForCart(saleId: number){
		if(!this.cart || this.cart.saleId !== saleId) return;
		this.cart.isCheckedOutAndPaid = true;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
	}

	public isCartLockedForChangesFromCurrentTab() : boolean{
		if (this.cart.isLocked && this.cart.LockerTabId == this.getCurrentTabId()){
			this.unlockCart();
		}

		if(this.isLockExpired()){
			this.unlockCart();
		}

		if(!this.cart.isLocked || this.cart.LockerTabId == this.getCurrentTabId()){
			return false;
		}

		return true;
	}

	private isLockExpired(): boolean{
		return this.cart.isLocked && this.cart.lockExpiryTime < Date.now()
	}

	public lockCart(){
		this.cart.isLocked = true;
		this.cart.lockExpiryTime = this.getLockExiryTime()
		this.generateNewTabId();
		this.cart.LockerTabId = this.getCurrentTabId();
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
	}
	
	private getLockExiryTime() {
		let d1 = new Date();
		return d1.setMinutes(d1.getMinutes() + this.lockExpiryInMinutes);
	}

	public unlockCart(){
		this.cart.isLocked = false;
		this.cart.LockerTabId = null;
		this.cart.lockExpiryTime = null;
		localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
	}

	setSeasonTicketTypeId(seasonTicketTypeId: number) { this.selectedSeasonTicketTypeId = seasonTicketTypeId; }
	getSeasonTicketTypeId() { return this.selectedSeasonTicketTypeId; }

	private generateNewTabId(){
		sessionStorage.currentTabId = Math.random();
	}

	private getCurrentTabId(){
		if(!sessionStorage.currentTabId){
			this.generateNewTabId();
		}
		return sessionStorage.currentTabId;
	}
}

export function getTicketId(ticket: ITicket) {
	if (ticket.soldTicketId) {
		return ticket.soldTicketId;
	} else {
		return ticket.ticketId;
	}
}
