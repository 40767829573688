/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauth-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared-layout-components/main-header/main-header.component.ngfactory";
import * as i3 from "../shared-layout-components/main-header/main-header.component";
import * as i4 from "../../../core/services/team.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/services/authentication.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../main-module/services/cart/cart.service";
import * as i10 from "../../../core/services/toast.service";
import * as i11 from "../../../main-module/services/portals/portal-service";
import * as i12 from "../../../main-module/services/sales/ticket-sale.service";
import * as i13 from "../../../core/services/get-json-data.service";
import * as i14 from "primeng-lts/api";
import * as i15 from "./unauth-layout.component";
var styles_UnauthLayoutComponent = [i0.styles];
var RenderType_UnauthLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthLayoutComponent, data: {} });
export { RenderType_UnauthLayoutComponent as RenderType_UnauthLayoutComponent };
export function View_UnauthLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "main-header", [], null, null, null, i2.View_MainHeaderComponent_0, i2.RenderType_MainHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.MainHeaderComponent, [i4.TeamService, i5.TranslateService, i6.ActivatedRoute, i7.AuthenticationService, i6.Router, i8.Title, i9.CartService, i10.ToastService, i11.PortalService, i1.Renderer2, i12.TicketSaleService, i13.GetJsonDataService, i14.ConfirmationService], { team: [0, "team"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "main-container "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.team; _ck(_v, 2, 0, currVal_1); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.teamCode + "-skin"); _ck(_v, 0, 0, currVal_0); }); }
export function View_UnauthLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ticketing-unauth-layout", [], null, null, null, View_UnauthLayoutComponent_0, RenderType_UnauthLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i15.UnauthLayoutComponent, [i6.ActivatedRoute, i5.TranslateService, i7.AuthenticationService, i6.Router, i6.ActivatedRoute, i4.TeamService, i8.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthLayoutComponentNgFactory = i1.ɵccf("ticketing-unauth-layout", i15.UnauthLayoutComponent, View_UnauthLayoutComponent_Host_0, {}, {}, []);
export { UnauthLayoutComponentNgFactory as UnauthLayoutComponentNgFactory };
