import { FileRequest } from "./file-request";

export class RegistrationRequest {

	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	isGdprAccepted: boolean;
	buildingNumber: string;
	buildingName: string;
	mobile: string;
	nationalId: string;
	fanCardId: string;
	company: boolean;
	companyName: string;
	companyPhone: string;
	companyEmail: string;
	companyRegVatNo: string;
	dateOfBirth: Date;
	suspended: boolean;
	suspendedFrom: Date;
	suspendedTo: Date;
	countryId: number;
	district: string;
	municipality: string;
	postalCode: string;
	street: string;
	sex: string;
	gdprAcceptanceLastUpdate: Date;
	gdprAcceptanceFileName: string;
	isTermsAndConditionsAccepted: boolean;
	isMarketingAccepted: boolean;
	isTicketTermsAndConditionsAccepted: boolean;
	isThirdPartyAccepted: boolean;
	parentalConsentFile: FileRequest;

	constructor(data?: RegistrationRequest) {

		if (data) {
			Object.assign(this, data);
		}
	}
}


