import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {LoginRequest} from '../../../../view-models/login-request';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamService} from '../../../core/services/team.service';
import {ITeamTemplate} from '../../../core/team-configuration/ITeamTemplate';
import {TeamTemplateComponent} from '../../../core/generic/team-template.component';
import {decode} from 'js-base64';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';

@Component({
	selector: 'ticketing-auth-page',
	templateUrl: './auth-page.component.html',
	styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent extends TeamTemplateComponent implements OnInit {
	public form: FormGroup;
	public template: ITeamTemplate;
	public isLoading: boolean = false;
	public loadingText: string = 'Loading';

	constructor(
		fb: FormBuilder,
		templateService: TeamService,
		private authenticationService: AuthenticationService,
		private router: Router,
		private toastService: ToastService,
		private activatedRoute: ActivatedRoute
	) {
		super(templateService);
		this.form = fb.group({
			email: ['', Validators.required],
			password: ['', Validators.required]
		});
		this.template = templateService.teamTemplate;
	}

	ngOnInit() {
		this.authenticationService.isLoggedIn().subscribe(isLoggedIn => {
			if (isLoggedIn) {
				this.router.navigate([`${this.template.teamId}/main`]);
			}
		});
	}

	public submit() {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		this.isLoading = true;

		const request = <LoginRequest>this.form.getRawValue();
		this.authenticationService.login(request).subscribe(async (loginResult) => {
				if (!loginResult.isSuccess) {
					this.isLoading = false;
					return;
				}
				if (!this.authenticationService.isValidatedAccount(this.template.teamId)) {
					await this.router.navigate([`${this.template.teamId}/main/change-password`]);
					return;
				}

				this.authenticationService.checkCustomersDataValidation()
					.subscribe((async customerDataValidationResult => {
						if (!customerDataValidationResult.isSuccess) {
							this.toastService.showError(new ToastMsg({ detail: customerDataValidationResult.errorMessage }));
						}
						if (customerDataValidationResult.data && customerDataValidationResult.data.length > 0) {
							const message = customerDataValidationResult.data.map(x => x.fieldName).join(', ');
							this.toastService.showWarning(new ToastMsg({ detail: message }));
							this.toastService.showWarning(new ToastMsg({ detail: 'There are fields that are not set in your profile, please consider going to profile and edit them' }));

							await this.router.navigate([`${this.template.teamId}/main/profile`]);
						}
						else {
							await this.router.navigate([`${this.template.teamId}/main`]);
						}
						this.isLoading = false;
					}));
			});
	}

	register() {
		this.router.navigate(['steps'], {relativeTo: this.activatedRoute.parent});
	}

	forgotPassword() {
		this.router.navigate(['forgot-password'], {relativeTo: this.activatedRoute.parent});
	}
}
