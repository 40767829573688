<div [formGroup]="form">
	<div class="form-group form-group-reverded">
		<p-dropdown [options]="countries" [(ngModel)]="selectedCountry.countryId" formControlName="countryId" optionLabel="name" optionValue="countryId" [filter]="true" placeholder="Please select country" [disabled]="isLoadingCountries" [dropdownIcon]="formHS.dropdownSpinnerIcon(isLoadingCountries)">
			<ng-template pTemplate="selectedItem">
				<div class="country-item country-item-value d-flex align-items-center" *ngIf="selectedCountry">
					<img [src]="'assets/img/countries/'+ selectedCountry.code.toLowerCase() +'.svg'" [class]="'mr-2 flag flag-' + selectedCountry.code.toLowerCase()" height="16"/>
					<div>{{selectedCountry.name}}</div>
				</div>
			</ng-template>
			<ng-template let-country pTemplate="item">
				<div class="country-item d-flex align-items-center">
							<img [src]="'assets/img/countries/'+ country.code.toLowerCase() +'.svg'" [class]="'mr-2 flag flag-' + country.code.toLowerCase()" height="16"/>
					<div>{{country.name}}</div>
				</div>
			</ng-template>
		</p-dropdown>
		<label>{{'pages.registration.country' | translate}} <span class="font-weight-bold">*</span></label>
		<validator-info [model]="form.controls.countryId"></validator-info>
	</div>

	<!-- Manual Address -->
	<div class="manual-address" *ngIf="isManualAddress">

		<div class="form-group form-group-reverded">

			<input pInputText type="text" class="form-control"
							formControlName="street"
							id="street"
				[placeholder]="'pages.registration.address' | translate">
			<label for="street">
				{{'pages.registration.address' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.street"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<input pInputText type="text" class="form-control"
							formControlName="district"
							id="district"
				[placeholder]="'pages.registration.city' | translate">
			<label for="district">
				{{'pages.registration.city' | translate}}
				<span class="text-danger font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.district"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<input pInputText type="text" class="form-control"
							formControlName="municipality"
							id="municipality"
				[placeholder]="'pages.registration.province' | translate">
			<label for="municipality">
				{{'pages.registration.province' | translate}}
				<span class="text-danger font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.municipality"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<input pInputText type="text" class="form-control"
							formControlName="postalCode"
							id="postalCode"
				[placeholder]="'pages.registration.zipCode' | translate">
			<label for="postalCode">
				{{'pages.registration.zipCode' | translate}}
				<span class="text-danger font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.postalCode"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<input pInputText type="text"
							formControlName="buildingNumber" class="form-control" id="buildingNo" placeholder="21">
			<label for="buildingNo">
				{{'pages.registration.number' | translate}}
				<span class="text-danger font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.buildingNumber"></validator-info>
		</div>
	</div>
	<!-- NOT Manual Address -->
	<div class="manual-address" *ngIf="!isManualAddress">
		<div class="form-group form-group-reverded">
			<p-dropdown [options]="cities" [(ngModel)]="selectedCity" 
			placeholder="{{'pages.registration.Please_select' | translate}}" 
			formControlName="district">
				<ng-template let-city pTemplate="item">
					<div>{{city}}</div>
				</ng-template>
			</p-dropdown>
			<label>
				{{'pages.registration.district' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.district"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<p-dropdown [options]="municipalities" [(ngModel)]="selectedMunicipality" 
			    placeholder="{{'pages.registration.Please_select' | translate}}" formControlName="municipality">
				<ng-template let-municipality pTemplate="item">
					<div>{{municipality}}</div>
				</ng-template>
			</p-dropdown>
			<label>
				{{'pages.registration.municipality' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.municipality"></validator-info>
		</div>

		<div class="form-group form-group-reverded">

			<p-dropdown #postcodeDropdown [options]="postalCodes" [(ngModel)]="selectedPostalCode" 
				placeholder="{{'pages.registration.Please_select' | translate}}"  formControlName="postalCode" [filter]="true" [autoDisplayFirst]="false">
				<ng-template let-postalCode pTemplate="item">
					<div>{{postalCode}}</div>
				</ng-template>
			</p-dropdown>
			<label>
				{{'pages.registration.post_code' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.postalCode"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<p-dropdown [options]="streets" [(ngModel)]="selectedStreet" placeholder="{{'pages.registration.Please_select' | translate}}" formControlName="street">
				<ng-template let-street pTemplate="item">
					<div>{{street}}</div>
				</ng-template>
			</p-dropdown>
			<label>
				{{'pages.registration.street' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.street"></validator-info>
		</div>

		<div class="form-group form-group-reverded">
			<input pInputText type="text" [patterEntry]="numberAndCharactersRegexpPattern"
							formControlName="buildingNumber" class="form-control" id="buildingNo" placeholder="{{'pages.registration.Please_enter' | translate}}" >
			<label for="buildingNo">
				{{'pages.registration.number' | translate}}
				<span class="font-weight-bold">*</span>
			</label>
			<validator-info [model]="form.controls.buildingNumber"></validator-info>
		</div>

		<div class="form-group ">
			<label for="buildingName" class="text-uppercase">
				{{'pages.registration.building' | translate}}
			</label>

			<input pInputText type="text" [patterEntry]="numberAndCharactersRegexpPattern" class="form-control"
							formControlName="buildingName" id="buildingName" placeholder="{{'pages.registration.Please_enter' | translate}}" >
		</div>
	</div>
</div>
