import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

const passwordComplexityPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-=+$^#{}()|'";:?/.>,<`~@!$%*?&_])[0-9A-Za-z\-=+$^#{}()|'";:?/.>,<`~@!$%*?&_].{7,}/;

export function passwordComplexity(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const result = passwordComplexityPattern.test(control.value);
		if (control.value && control.value.length && !result) {
			return {
				password: `
           The password must be at least eight (8) characters long. <br/>
           The password must contain at least one (1) uppercase <br/>
           and at least one (1) lowercase letter. <br/>
           The password must contain at least one (1) number. <br/>
           The password must contain at least one (1) symbol. <br/>
      `
			};
		}
	};
}
