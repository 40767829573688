/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng-lts/progressspinner/primeng-lts-progressspinner.ngfactory";
import * as i3 from "primeng-lts/progressspinner";
import * as i4 from "./spinner-loading.component";
var styles_SpinnerLoadingComponent = [i0.styles];
var RenderType_SpinnerLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerLoadingComponent, data: {} });
export { RenderType_SpinnerLoadingComponent as RenderType_SpinnerLoadingComponent };
export function View_SpinnerLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "overlay"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p-progressSpinner", [["class", "row"]], null, null, null, i2.View_ProgressSpinner_0, i2.RenderType_ProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i3.ProgressSpinner, [], { style: [0, "style"] }, null), i1.ɵpod(3, { width: 0, height: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " .."]))], function (_ck, _v) { var currVal_1 = _ck(_v, 3, 0, "60px", "60px"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.loadingText; _ck(_v, 5, 0, currVal_2); }); }
export function View_SpinnerLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spinner-loading", [], null, null, null, View_SpinnerLoadingComponent_0, RenderType_SpinnerLoadingComponent)), i1.ɵdid(1, 49152, null, 0, i4.SpinnerLoadingComponent, [], null, null)], null, null); }
var SpinnerLoadingComponentNgFactory = i1.ɵccf("spinner-loading", i4.SpinnerLoadingComponent, View_SpinnerLoadingComponent_Host_0, { isLoading: "isLoading", loadingText: "loadingText" }, {}, []);
export { SpinnerLoadingComponentNgFactory as SpinnerLoadingComponentNgFactory };
