var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamService } from '../../../core/services/team.service';
import { TeamTemplateComponent } from '../../../core/generic/team-template.component';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';
export class AuthPageComponent extends TeamTemplateComponent {
    constructor(fb, templateService, authenticationService, router, toastService, activatedRoute) {
        super(templateService);
        this.authenticationService = authenticationService;
        this.router = router;
        this.toastService = toastService;
        this.activatedRoute = activatedRoute;
        this.isLoading = false;
        this.loadingText = 'Loading';
        this.form = fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.template = templateService.teamTemplate;
    }
    ngOnInit() {
        this.authenticationService.isLoggedIn().subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.router.navigate([`${this.template.teamId}/main`]);
            }
        });
    }
    submit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        const request = this.form.getRawValue();
        this.authenticationService.login(request).subscribe((loginResult) => __awaiter(this, void 0, void 0, function* () {
            if (!loginResult.isSuccess) {
                this.isLoading = false;
                return;
            }
            if (!this.authenticationService.isValidatedAccount(this.template.teamId)) {
                yield this.router.navigate([`${this.template.teamId}/main/change-password`]);
                return;
            }
            this.authenticationService.checkCustomersDataValidation()
                .subscribe(((customerDataValidationResult) => __awaiter(this, void 0, void 0, function* () {
                if (!customerDataValidationResult.isSuccess) {
                    this.toastService.showError(new ToastMsg({ detail: customerDataValidationResult.errorMessage }));
                }
                if (customerDataValidationResult.data && customerDataValidationResult.data.length > 0) {
                    const message = customerDataValidationResult.data.map(x => x.fieldName).join(', ');
                    this.toastService.showWarning(new ToastMsg({ detail: message }));
                    this.toastService.showWarning(new ToastMsg({ detail: 'There are fields that are not set in your profile, please consider going to profile and edit them' }));
                    yield this.router.navigate([`${this.template.teamId}/main/profile`]);
                }
                else {
                    yield this.router.navigate([`${this.template.teamId}/main`]);
                }
                this.isLoading = false;
            })));
        }));
    }
    register() {
        this.router.navigate(['steps'], { relativeTo: this.activatedRoute.parent });
    }
    forgotPassword() {
        this.router.navigate(['forgot-password'], { relativeTo: this.activatedRoute.parent });
    }
}
