<div class="content-container">
	<div class="container">

		<div class="row align-items-center justify-content-center">
			<form [formGroup]="form" class="registration-form px-4 pt-4 mb-4 bg-white border border-1">

				<!-- Header -->
				<div class="register-header d-flex mb-4">
					<img [src]="logoPath" width="60">
					<div class="header-content">
						<h2 class="pt-2 text-uppercase">{{'pages.registration.address_information' | translate}}</h2>
					</div>
				</div>

				<!--Address -->
				<ticketing-address [form]="form"></ticketing-address>

				<div class="d-flex align-items-center mb-2">
					<p-inputSwitch [(ngModel)]="isCompany" formControlName="company" id="company-address"></p-inputSwitch>
					<label class="form-check-label checkbox-container" for="company-address">
						{{'pages.registration.company' | translate}}
					</label>
				</div>

				<div *ngIf="isCompany">
					<div class="form-group form-group-reverded">

						<input pInputText type="text" class="form-control" formControlName="companyName" id="company-name"
							placeholder="Company name">
						<label for="company-name">
							{{'pages.registration.company_name' | translate}}
						</label>
						<validator-info [model]="form.controls.companyName"></validator-info>
					</div>

					<div class="form-group form-group-reverded">
						<input pInputText type="text" class="form-control" id="vatNo"
							formControlName="companyRegVatNo" placeholder="{{'pages.registration.Registration_VAT_Number' | translate}}">
						<label for="vatNo">{{'pages.registration.companyRegVatNo' | translate}}</label>
						<validator-info [model]="form.controls.companyRegVatNo"></validator-info>
					</div>

					<div class="form-group form-group-reverded">
						<input pInputText type="text" class="form-control" formControlName="companyPhone"
							[patterEntry]="phoneRegexpPattern" id="company-phone" placeholder="77000000">
						<label for="company-phone">{{'pages.registration.companyPhone' | translate}}</label>
						<validator-info [model]="form.controls.companyPhone"></validator-info>
					</div>

					<div class="form-group form-group-reverded">
						<input pInputText type="email" class="form-control" formControlName="companyEmail" id="company-email"
							placeholder="info@example.com">
						<label for="company-email">
							{{'pages.registration.company_email'|translate}}
						</label>
						<validator-info [model]="form.controls.companyEmail"></validator-info>
					</div>
				</div>

				<!--Actions -->
				<div class="form-group form-actions">
					<button pButton pRipple type="button" label="{{'pages.registration.COMPLETE_REGISTRATION' | translate}}" class="p-button-primary w-100 mb-2" (click)="submit()"></button>
					<button pButton pRipple type="button" label="{{'buttons.back'|translate}}" class="p-button-primary w-100" (click)="previousItem()"></button>
				</div>

				<div class="form-footer">
					<i class="fa fa-lock"></i>{{'pages.registration.Secure_registration' | translate}}
				</div>
			</form>
		</div>
	</div>
</div>

<spinner-loading [isLoading]="isLoading" [loadingText]="loadingText"></spinner-loading>