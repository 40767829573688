<div *ngIf="model.invalid && (model.dirty || model.touched)">
	<div>
    <small class="p-error" *ngIf="model.errors.required">
      {{'pages.registration.validation.validation_Required'| translate}}
		</small>
	</div>
	<small class="p-error" *ngIf="model.errors.untilToday">
    {{model.errors.untilToday}}
  </small>
	<small class="p-error" *ngIf="model.errors.maxlength">
    Max length reached
  </small>
	<small class="p-error" *ngIf="model.errors.nonZero">
    Value should be equal or greater then zero.
  </small>
	<small class="p-error" *ngIf="model.errors.email">
    Invalid Email.
  </small>
	<small class="p-error password-val-options" *ngIf="model.errors.password"
				innerHtml="{{model.errors.password}}"
	>
  </small>
	<small class="p-error" *ngIf="model.errors.mustMatch">
      Fields must match
  </small>
	<small class="p-error" *ngIf="model.errors.minlength">
     Minimal length should be {{model.errors.minlength.requiredLength}}
  </small>
	<small class="p-error" *ngIf="model.errors.min">
    Minimal value should be  {{model.errors.min.min}}
  </small>
	<small class="p-error" *ngIf="model.errors.minDate">
    {{model.errors.minDate}}
  </small>
	<small class="p-error" *ngIf="model.errors.max">
     Maximum value should not be greater then {{model.errors.max.max}}
  </small>
	<small class="p-error" *ngIf="model.errors.pattern">
     Unexpected characters found.
  </small>
	<small class="p-error" *ngIf="model.errors.nationalIdExists">
    {{'pages.registration.nationalid_required' | translate}}
 </small>
 <small class="p-error" *ngIf="model.errors.emailExists">
  {{'pages.registration.emailExists' | translate}}
 </small>
</div>
