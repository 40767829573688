import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TeamTemplateComponent} from '../../../core/generic/team-template.component';
import {TeamService} from '../../../core/services/team.service';
import {passwordComplexity} from '../../../core/validators/password-validator';
import {passwordMatcher} from '../../../core/validators/confirm-password-validator';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthenticationServiceHttp} from '../../../../http-services/authentication-http.service';
import {CheckTokenRequest} from '../../../../view-models/check-token-request';
import {SendForgotPasswordTokenRequest} from '../../../../view-models/send-forgot-password-token-request';
import {TranslateService} from '@ngx-translate/core';
import {ChangePasswordViaTokenRequest} from '../../../../view-models/change-password-via-token-request';
import {Location} from '@angular/common';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';

@Component({
	selector: 'app-forgot-password-page',
	templateUrl: './forgot-password-page.component.html',
	styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent extends TeamTemplateComponent {
	public eForm: FormGroup;
	public pForm: FormGroup;
	public step: 'email_confirmation' | 'pending_state' | 'password_input' | 'invalid_token' = 'email_confirmation';
	public submitBtnDisabled = false;

	constructor(
		templateService: TeamService,
		fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private authenticationHttpService: AuthenticationServiceHttp,
    private toastService: ToastService,
		private translateService: TranslateService,
		private router: Router,
		private location: Location
	) {
		super(templateService);
		this.eForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
		});

		this.pForm = fb.group({
			token: ['', [Validators.required]],
			newPassword: ['', [Validators.required, passwordComplexity()]],
			passwordConfirm: ['', [Validators.required, passwordMatcher.bind(this)]]
		});
	}

	ngOnInit() {
		this.activatedRoute.queryParamMap.subscribe(r => {
			const token = r.get('token');
			if (token) {
				this.authenticationHttpService.checkToken(new CheckTokenRequest(token)).subscribe(tokenResult => {
					if (tokenResult.isSuccess === true) {
						this.step = 'password_input';
						this.pForm.controls['token'].patchValue(token);
					} else {
						this.step = 'invalid_token';
					}
				});
			}
		});
	}

	public eFormSubmit() {
		if (!this.eForm.valid) {
			this.eForm.markAllAsTouched();
		} else {
			const raw = <SendForgotPasswordTokenRequest>this.eForm.getRawValue();
			this.authenticationHttpService.sendForgotPasswordLink(raw).subscribe(result => {
				if (!result.isSuccess) {
					this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));

				} else {
					this.eForm.reset();
					const toastMessage = this.translateService.instant('pages.forgot_password.success_send_email_message');
					this.toastService.showSuccess(new ToastMsg({ detail: toastMessage }));
					setTimeout(() => this.router.navigate([`/${this.teamCode}`]), 3000);
				}
			});
		}
	}

	public pFormSubmit() {
		if (!this.pForm.valid) {
			this.pForm.markAllAsTouched();
		} else {
			const raw = <ChangePasswordViaTokenRequest>this.pForm.getRawValue();
			this.authenticationHttpService.changePasswordViaToken(raw).subscribe(r => {
				if (!r.isSuccess) {
					this.toastService.showError(new ToastMsg({ detail: r.errorMessage }));
				} else {
					const toastMessage = this.translateService.instant('pages.forgot_password.success_change_password_message');
					this.toastService.showSuccess(new ToastMsg({ detail: toastMessage }));
					this.router.navigate([`/${this.teamCode}`]);
				}
			});
		}
	}

	public back() {
		this.location.back();
	}
}
