import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import {environment} from 'src/environments/environment';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {
	encryptSecretKey = environment.DataIntegrityKey;

	constructor() {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var ogBody = request.body;
		if (request.headers.get('Obscure') != null) {
			// encrypt data
			let key;
			for (key in ogBody) {
				if (ogBody.hasOwnProperty(key)) {
					ogBody[key] = this.encryptData(ogBody[key]);
				}
			}
			request = request.clone({
				body: ogBody
			});
		}

		var seed_hash = sha256(JSON.stringify(request.body)).toString(CryptoJS.enc.Hex);
		var packet = this.encryptData(seed_hash);

		request = request.clone({
			setHeaders: {
				RequestHandler: packet
			}
		});

		return next.handle(request);
	}

	encryptData(msg) {
		var keySize = 256;
		var salt = CryptoJS.lib.WordArray.random(16);
		var key = CryptoJS.PBKDF2(this.encryptSecretKey, salt, {
			keySize: keySize / 32,
			iterations: 100
		});

		var iv = CryptoJS.lib.WordArray.random(128 / 8);

		var encrypted = CryptoJS.AES.encrypt(msg, key, {
			iv: iv,
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		});

		var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));

		return result;
	}


}
