/* tslint:disable:no-bitwise */
export class Guid {
	private static mask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

	public static newGuid(): string {
		let d = new Date().getTime();
		let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
		return Guid.mask.replace(/[xy]/g, function (c) {
			let r = Math.random() * 16;
			if (d > 0) {
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

	public static empty() {
		return Guid.mask.replace(/([x4])/g, '0');
	}
}
