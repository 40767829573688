import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class InstalmentPaymentHttpService {
    constructor(http) {
        this.http = http;
        this.coreApiKey = 'core-api';
    }
    getPaymentInstalments(totalAmount) {
        return this.http.get(`${this.coreApiKey}/ticketing/instalments/getTicketInstalments?totalAmount=${totalAmount}`);
    }
    initPaymentByInstalment(orderId, request) {
        return this.http.post(`${this.coreApiKey}/Ticketing/Instalments/StartPaymentByInstalment?orderId=${orderId}`, request);
    }
    getCurrentUserInstalments() {
        return this.http.get(`${this.coreApiKey}/ticketing/instalments/GetCurrentUserInstalments`);
    }
}
InstalmentPaymentHttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstalmentPaymentHttpService_Factory() { return new InstalmentPaymentHttpService(i0.ɵɵinject(i1.HttpClient)); }, token: InstalmentPaymentHttpService, providedIn: "root" });
