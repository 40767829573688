import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, ActivationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng-lts/api';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';
import { CartService } from 'src/app/modules/main-module/services/cart/cart.service';
import { PortalService } from 'src/app/modules/main-module/services/portals/portal-service';
import { TicketSaleService } from 'src/app/modules/main-module/services/sales/ticket-sale.service';
import { PortalSettings } from 'src/app/view-models/portal-management/portal-settings';
import { SocialMedia } from 'src/app/view-models/social-media';
import { SocialMediaResponse } from 'src/app/view-models/social-media-response';
import { SponsorResponse } from 'src/app/view-models/sponsor-response';
import { LayoutShared } from '../../shared/layout.shared';
import { AuthPageComponent } from '../../../../auth-module/pages/auth-page/auth-page.component';
import { GetJsonDataService } from 'src/app/modules/core/services/get-json-data.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent extends LayoutShared implements OnInit {
	@Input() team: ITeamTemplate;
	public portalSettings: PortalSettings;
	public title: string;
	public isUserEditOptionsDisplayed: boolean = false;
	public template: ITeamTemplate;
	public navbarCollapsed = true;
	public isShowLoyaltyPage: boolean;
	public sponsors: SponsorResponse[];
	public socialMedia: SocialMedia = {} as SocialMedia;
	public socialMediaResponse: SocialMediaResponse[];
	public canShowPricingRulesPage: boolean;
	public isCompany: boolean = false;
	public display: boolean = false;
	public items: MenuItem[];
	public isLoggedIn: boolean = false;
	public styleColors: string = '';
	public selectedPage: string = 'home';

	@ViewChild('navnav', {static: true}) private lowerNav: ElementRef;
	@ViewChild('toggleButton', {static: true}) toggleButton: ElementRef;
	@ViewChild('menu', {static: false}) menu: ElementRef;

  constructor(
		public templateService: TeamService,
		protected translateService: TranslateService,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private router: Router,
		private pageTitle: Title,
		private cartService: CartService,
		private toastService: ToastService,
		private portalService: PortalService,
		private renderer: Renderer2,
		private saleService: TicketSaleService,
		private getJsonDataService: GetJsonDataService,
		private confirmationService: ConfirmationService,
	) {
		super(translateService, templateService);
		this.team = <ITeamTemplate>route.snapshot.data['teamTemplate'];
		this.router.events.subscribe((event: ActivationEnd) => {
			if (event.snapshot && event.snapshot.data.title) {
				this.title = event.snapshot.data.title;
				const localized = this.translateService.instant(this.title);
				pageTitle.setTitle(localized);
			}
		});
		this.template = templateService.teamTemplate;
		this.isShowLoyaltyPage = portalService.canShowLoyaltyPage(this.template.portalId);
		this.canShowPricingRulesPage = portalService.canShowPricingRulesPage(this.template.portalId);
	}

	public get getSelectedPage() {
		return this.selectedPage;
	}

  ngOnInit() {
		this.onLanguageChange();
		if(this.isUserLoggedIn()) {
			this.loadPortalSettings();
			this.showMenuHeaderBar();
			this.loadCart();
			this.checkToClosePopUpMenu();
			this.loadUserDropdownItems();
		}
		this.teamTemplateColors();
  }

	checkToClosePopUpMenu() {
		this.renderer.listen('window', 'click', (e: Event) => {
			if (this.toggleButton && e.target !== this.toggleButton.nativeElement) {
				this.isUserEditOptionsDisplayed = false;
			}
		});
	}

	public isUserLoggedIn() {
		this.isLoggedIn = this.authenticationService.isLoggedInPortal(this.team.teamId) ? true : false;
		return this.isLoggedIn;
	}

	displayEditOptions() {
		this.isUserEditOptionsDisplayed = !this.isUserEditOptionsDisplayed;
	}

	emptyCartBeforeRedirect(urlPage: string) {
		this.display = false;

		let doRoute = ()=>{
			this.router.navigate([urlPage], { relativeTo: this.route.parent })
			this.selectedPage = urlPage;
		}
		const cart = this.cartService.getCart();
		if (!cart || cart.items.length == 0) {
			doRoute();
			return;
		}

		//at least one ticket in the cart, opens clear cart dialog
		this.clearCart(doRoute);
	}

	private loadUserDropdownItems() {
		this.items = [
			{
				label: this.translateService.instant('layout.menu.editProfile'),
				icon: 'pi pi-user-edit',
				routerLink: 'profile',
			},
			{
				label: this.translateService.instant('layout.menu.changePassword'),
				icon: 'pi pi-lock',
				routerLink: 'change-password',
			},
			{
				separator: true
			},
			{
				label: this.translateService.instant('layout.menu.logout'),
				icon: 'pi pi-sign-out',
				command: () => {
					this.logout();
				},
			}
		];
	}

	private showMenuHeaderBar() {
		this.lowerNav.nativeElement.classList.add(this.team.teamId);
	}

	private onLanguageChange() {
		this.translateService.onLangChange.subscribe(r => {
			this.team = <ITeamTemplate>this.route.snapshot.data['teamTemplate'];
			const data = this.getDataRecursively(this.route.snapshot);
			const localized = this.translateService.instant(data.title);
			this.pageTitle.setTitle(localized);
			this.title = localized;
		});
	}

	private loadPortalSettings() {
		this.portalSettings = this.portalService.getPortalSettings();
	}

	private loadCart() {
		this.cartService.loadCart(this.teamCode).subscribe(r => { });
	}


	private getDataRecursively(route: ActivatedRouteSnapshot) {
		let dataMap = {...route.data};
		for (let i = 0; i < route.children.length; i++) {
			dataMap = {...dataMap, ...this.getDataRecursively(route.children[i])};
		}
		return dataMap;
	}

	async logout() {
		this.authenticationService.logout().subscribe(r => {
			location.href = `/${this.template.teamId}/login`;
			this.portalService.resetPortalSettings();
		});
	}

	get isLoginPage(): boolean {
		return this.route.children[0].component == AuthPageComponent;
	}

	teamTemplateColors() {
		const style = document.createElement('style');
		this.getJsonDataService.getTeamThemeColors(this.team.teamId).subscribe(data => {
			data.colors.forEach((element: any) => {
				Object.entries(element).forEach((array: any) => {
					typeof array[0]!='object' ?	this.styleColors+= '--team-'+array[0]+':'+array[1]+';' : '';
				});
			});
			style.innerHTML= ':root {'+this.styleColors+'}';
			document.head.appendChild(style);
		});
	}

	register() {
		this.router.navigate(['steps'], {relativeTo: this.route.parent});
	}

	public clearCart(callback) {
		let ticketCount = this.cartService.getCart().items.length;
		if (ticketCount == 0) {
			this.toastService.showError(new ToastMsg({ detail: 'The cart is empty' }));
			return;
		}

		this.confirmationService.confirm({
			message: ticketCount == 1 ? this.translateService.instant('layout.menu.clear_cart_confirmation_content_single')
																: this.translateService.instant('layout.menu.clear_cart_confirmation_content_multiple', { ticketCount: ticketCount }),
			header: this.translateService.instant('layout.menu.clear_cart_confirmation_title'),
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.saleService.clearCart();
				if (callback) callback();
				this.toastService.showSuccess(new ToastMsg({ detail: "Cleared Cart Successfully" }));
			}
		});
	}

	public async login() {
		await this.router.navigate([`/${this.team.teamId}/main`]);
	}
}
