export enum PortalIdEnum {
	OtherTeam = 1,
	Omonoia = 2,
	Apoel = 3,
	Olympiakos = 6,
	Aek = 7,
	Paphos = 8,
	Paeek = 10,
	Doxa = 11,
	Kop = 12,
}
