import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function passwordMatcher(control: FormControl): { [s: string]: string } {
	if (
		this.pForm &&
		(control.value !== this.pForm.controls.newPassword.value)
	) {
		return {
			password: `
      Passwords must match. <br/>
 `
		};
	}
	return null;
}
