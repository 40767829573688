import {Injectable, OnInit} from '@angular/core';
import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {event} from 'jquery';
import {CookieService} from 'ngx-cookie-service';
import {Observable, Observer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthenticationService} from '../modules/core/services/authentication.service';
import {SignalRSettings} from './model/signalr-settings';

export class SignalrHub {

	private _connection: HubConnection;
	private _hostUrl: string;
	private _data: any;
	private _onUpdateSeat: any;
	private _token: string;
	private _keyword: string = '/SeatMapHub';

	constructor(
		settings: SignalRSettings,
		private authenticationService?: AuthenticationService
	) {
		this._hostUrl = settings.url ? settings.url + this._keyword : environment.coreUrl + this._keyword;
		this._data = settings.data ? settings.data : {};
		this._onUpdateSeat = settings.onUpdateSeat ? settings.onUpdateSeat : null;
	}

	public SetUpConnection(): Observable<boolean> {
		const obs = new Observable((ob: Observer<boolean>) => {
			this._connection = new HubConnectionBuilder()
				.withUrl(this._hostUrl, {accessTokenFactory: () => this._token})
				.withAutomaticReconnect()
				.configureLogging(environment.production ? LogLevel.Error : LogLevel.Debug)
				.build();

			if (this._connection) {
				ob.next(true);
			} else {
				ob.next(false);
			}
		});

		return obs;
	}

	public setUpNotificationHandler() {
		if (this._onUpdateSeat) {
			this._connection.on('UpdateSeats', this._onUpdateSeat);
		}

	}

	public start() {
		this._connection.start()
			.then(async result => {
					if (this._data) {
						await this.addToGroup();
					}
				},
				error => {
					setTimeout(() => this.start(), 3000);
				});
	}

	private async addToGroup() {
		try {
			await this._connection.invoke('AddToGroup', this._data);
		} catch (err) {
		}
	}
}
