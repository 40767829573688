import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import {TeamService} from './services/team.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TokenInterceptor} from './interceptors/token-interceptor';
import {AuthenticationService} from './services/authentication.service';
import {AuthenticationServiceHttp} from '../../http-services/authentication-http.service';
import {ValidatorInfoComponent} from './validator-info/validator-info.component';
import {CommonModule} from '@angular/common';
import {ApiResponseInterceptor} from './interceptors/api-response.interceptor';
import {PortalIdInterceptor} from './interceptors/portal-id.interceptor';
import {BaseUrlInterceptor} from './interceptors/base-url.interceptor';
import {
	DigitOnlyDirective,
	PatternEntryDirective,
	UppercaseDirective
} from './directives/upper-cased-value-caser.directive';
import {NationalIdValidator} from './validators/national-id-validator';
import { EmailValidator } from './validators/email-validator';
import {ProfileServiceHttp} from 'src/app/http-services/profile-service-http.service';
import {SignalRHubService} from './services/signalr-service';
import { SanitizeUrlPipe } from './generic/sanitize';
import {DialogService} from 'primeng-lts/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
	imports: [NgxSpinnerModule, CommonModule, TranslateModule],
	declarations: [ValidatorInfoComponent, UppercaseDirective, DigitOnlyDirective, PatternEntryDirective, SanitizeUrlPipe],
	exports: [NgxSpinnerModule, ValidatorInfoComponent, UppercaseDirective, PatternEntryDirective],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: PortalIdInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},

		AuthenticationService,
		AuthenticationServiceHttp,
		NationalIdValidator,
		EmailValidator,
		ProfileServiceHttp,
		SignalRHubService,
		DialogService
	]
})
export class CoreModule {
}
