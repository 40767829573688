import { ToastService } from 'src/app/modules/core/services/toast.service';
import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import {Observable, of, throwError, Subscriber, Observer} from 'rxjs';
import {Router} from '@angular/router';
import {map, catchError, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../../../view-models/api-response';
import {ApiError} from '../../../view-models/api-error';
import {AuthenticationService} from '../services/authentication.service';
import {TeamService} from '../services/team.service';
import { ToastMsg } from '../models/ngprime/toastMsg';

interface CallerRequest {
	subscriber: Subscriber<any>;
	failedRequest: HttpRequest<any>;
}


@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private http: HttpClient,
		private auth: AuthenticationService,
		private toastService: ToastService,
		private teamService: TeamService
	) {
	}

	private refreshInProgress = false;
	private requests: CallerRequest[] = [];

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const observable = new Observable<HttpEvent<any>>((subscriber) => {
			const originalRequestSubscription = next.handle(req).pipe(
				map((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						if (this.CloudflareIPLock(event)) {
							this.auth.logout();
							this.router.navigate(['/']);
							this.toastService.showError(new ToastMsg({ detail: 'You have been blocked for too many requests to the Platform. Please try again in 30 minutes.', life: 10000 }));
							return event;
						}
						if (event.status === 401) {
							this.auth.logout();
							this.router.navigate(['/']);
							return event;
						}
						if (event.status === 200) {
							const apiResult = <ApiResponse<any>>event.body;
							if (apiResult) {
								switch (+apiResult.statusCode) {
									case -1:
										return throwError(apiResult.errorMessage);
									case 5:
										try {
											const parsed = JSON.parse(apiResult.errorMessage);
											const validationError = new ApiResponse<any>();
											validationError.errorMessage = parsed.AllErrors[0];
											return event.clone({body: validationError});
										} catch (e) {
											return event;
										}
									case 7:
										return throwError('Страница не найдена');
									case 401 :
										this.handleNeedSignOutError();
										return throwError(apiResult.errorMessage);
									case 500:// <= TicketingCoreException
										this.toastService.showError(new ToastMsg({ detail: apiResult.errorMessage }));
										return event;
									default:
										return event;
								}
							}
						}
					}
					return event;
				})
				,
				catchError((error) => {
					this.handleUnauthorizedError(subscriber, req);
					return of(error);
				})
			).subscribe(
				(response) => {
					subscriber.next(response);
				},
				(error) => {
					if (error.status == 401) {
						this.handleUnauthorizedError(subscriber, req);
					}
				}
			);

			return () => {
				originalRequestSubscription.unsubscribe();
			};
		});
		return observable;

	}

	private handleNeedSignOutError() {
		this.toastService.showError(new ToastMsg({ detail: 'Unauthorized access you will be redirected to login page.' }));
		setTimeout(() => {
			this.auth.logout().subscribe(isLoggedOut => {
				location.href = `/${this.teamService.teamTemplate.teamId}/login`;
			});
		}, 4000);

		// this.toastrService.error('Unauthorized access you will be redirected to login page.').onHidden.subscribe(() => {
		// 	this.auth.logout().subscribe(isLoggedOut => {
		// 		location.href = `/${this.teamService.teamTemplate.teamId}/login`;
		// 	});
		// });
	}

	handleAuthError(err: HttpErrorResponse): Observable<any> {
		return throwError(err);
	}

	private CloudflareIPLock(event): boolean {
		return event.status === 429;
	}


	private handleUnauthorizedError(subscriber: Subscriber<any>, request: HttpRequest<any>) {
		this.toastService.showError(new ToastMsg({ detail: 'Unauthorized access you will be redirected to login page.' }));
		setTimeout(() => {
			this.auth.logout().subscribe(isLoggedOut => {
				location.href = `/${this.teamService.teamTemplate.teamId}/login`;
			});
		}, 1000);

		// this.toastrService.error('Unauthorized access you will be redirected to login page.').onShown.subscribe(() => {
		// 	this.auth.logout().subscribe(isLoggedOut => {
		// 		location.href = `/${this.teamService.teamTemplate.teamId}/login`;
		// 	});
		// });
	}


	private repeatFailedRequests(authHeader) {
		this.requests.forEach((c) => {
			const requestWithNewToken = c.failedRequest.clone({
				headers: c.failedRequest.headers.set('Authorization', `Bearer ${authHeader}`)
			});
			this.repeatRequest(requestWithNewToken, c.subscriber);
		});
		this.requests = [];
	}

	private repeatRequest(requestWithNewToken: HttpRequest<any>, subscriber: Subscriber<any>) {
		this.http.request(requestWithNewToken).subscribe((res) => {
				subscriber.next(res);
			},
			(err) => {
				if (err.status === 401) {
					// this.auth.logout();
				}
				subscriber.error(err);
			},
			() => {
				subscriber.complete();
			});
	}


}





