import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { LayoutShared } from '../shared/layout.shared';
import { PrimeNGConfig } from 'primeng-lts/api'

interface Lang {
	name: string,
	code: string
}

@Component({
  selector: 'ticketing-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})

export class LanguageSwitcherComponent extends LayoutShared implements OnInit {

	languages: Lang[];

  selectedLanguage: Lang;

  constructor(
		private primengConfig: PrimeNGConfig,
		templateService: TeamService,
		translateService: TranslateService) {
		super(translateService, templateService);
		this.languages = [
			{name: 'EN', code: 'en'},
			{name: 'EL', code: 'cy'},
			{name: 'RU', code: 'ru'},
		];
	}

	onChange(event) {
		this.changeLocality(event.value.code);
	}

  ngOnInit() {
		this.primengConfig.ripple = true;
		this.selectedLanguage = this.languages.find(({ code }) => code === this.translateService.currentLang);
  }

	isLanguageActive(culture: string) {
		return this.translateService.currentLang === culture;
	}

}
