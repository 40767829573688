import { ExtraOptions } from '@angular/router';
import { SpecifyTeamComponent } from './modules/core/specify-team/specify-team.component';
import { AuthenticationGuard } from './modules/core/guards/authentication-guard';
const ɵ0 = () => import("./modules/auth-module/auth.module.ngfactory").then(m => m.AuthModuleNgFactory), ɵ1 = () => import("./modules/public/public.module.ngfactory").then(m => m.PublicModuleNgFactory), ɵ2 = () => import("./modules/main-module/main.module.ngfactory").then(m => m.MainModuleNgFactory);
const routes = [
    {
        path: '',
        component: SpecifyTeamComponent
    },
    {
        path: ':team',
        loadChildren: ɵ0,
    },
    {
        path: ':team/tickets',
        loadChildren: ɵ1,
    },
    {
        path: ':team/main',
        canLoad: [AuthenticationGuard],
        loadChildren: ɵ2,
    }
];
const routesConfiguration = {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
    paramsInheritanceStrategy: 'always'
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
