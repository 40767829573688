import { TeamTemplateFactory } from '../team-configuration/team-template-factory.service';
import * as i0 from "@angular/core";
import * as i1 from "../team-configuration/team-template-factory.service";
export class TeamService {
    constructor(teamTemplateFactory) {
        this.teamTemplateFactory = teamTemplateFactory;
        this.initialized = false;
    }
    getTeamInfoByTemplateName(template) {
        return this.teamTemplate = this.teamTemplateFactory.create(template);
    }
    setAcquiringSystem(acSystem) {
        this.teamTemplate.acquiringSystems = [acSystem];
    }
}
TeamService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeamService_Factory() { return new TeamService(i0.ɵɵinject(i1.TeamTemplateFactory)); }, token: TeamService, providedIn: "root" });
