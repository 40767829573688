import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Colors } from "../interfaces/theme-colors.interface";

@Injectable({
  providedIn: 'root'
})

export class GetJsonDataService {

  constructor( private http: HttpClient ) { }

  getTeamThemeColors(teamCode: string): Observable<Colors> {
    return this.http.get<Colors>(`/assets/styles/team-theme-colors/${teamCode}.json`);//${teamCode}
  }
}
