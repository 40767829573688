import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {promise} from 'selenium-webdriver';
import {ProfileServiceHttp} from 'src/app/http-services/profile-service-http.service';

@Injectable()
export class EmailValidator {
	constructor(private profileService: ProfileServiceHttp) {
	}

	public uniqueEmail(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return this.profileService.isEmailTaken(control.value)
				.pipe(
					map(res => {
						if (!res.isSuccess) {
							return {'emailExists': true};
						}
						return null;
					})
				).pipe(first());
		};
	}
}
