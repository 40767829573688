import * as i0 from "@angular/core";
export class FormHelpersService {
    constructor() { }
    /**
   * Makes Prime dropdown have spin icon on load
   * @param boolen
   * @returns Icon Class for PrimeNG
   */
    dropdownSpinnerIcon(isLoading) {
        return isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down';
    }
}
FormHelpersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormHelpersService_Factory() { return new FormHelpersService(); }, token: FormHelpersService, providedIn: "root" });
