<div class="main-container">

		<div class="card form-card p-4" *ngIf="step!='invalid_token'">
			<form *ngIf="step=='email_confirmation'" [formGroup]="eForm" (ngSubmit)="eFormSubmit()">
				<div class="form-header mb-2">
					<div class="team-logo text-center">
						<img [src]="logoPath" width="60">
					</div>
					<div class="header-content">
						<h2 class="pt-2 text-uppercase">Forgot Password</h2>
						<p>{{'pages.forgot_password.title' | translate}}</p>
					</div>
				</div>
				<div class="form-content">
					<div class="form-group">
						<label for="email">{{'pages.forgot_password.email' | translate}}</label>
						<input pInputText type="email" class="form-control" id="email" formControlName="email" placeholder="E-mail"/>
						<validator-info [model]="eForm.controls.email"></validator-info>
					</div>
					<div class="button-group">
						<button pButton pRipple type="submit" label="{{'buttons.ok'|translate}}" class="p-button-primary w-100 mb-2"></button>
						<button pButton pRipple type="button" label="{{'buttons.back'|translate}}" class="p-button-outlined w-100" (click)="back()"></button>
					</div>
				</div>
			</form>

			<form *ngIf="step=='password_input'" [formGroup]="pForm" (ngSubmit)="pFormSubmit()">
				<div class="form-header mb-2">
					<div class="team-logo text-center">
						<img [src]="logoPath" width="60">
					</div>
					<div class="header-content">
						<h2 class="pt-2 text-uppercase">Forgot Password</h2>
						<p>{{'pages.forgot_password.password_title' | translate}}</p>
					</div>
				</div>
				<div class="form-content">
					<div class="form-group">
						<label for="newPassword">{{'pages.reset_password.fields.newPassword' | translate}}</label>
						<input pInputText type="password" class="form-control" id="newPassword" formControlName="newPassword" placeholder="New password"/>
						<validator-info [model]="pForm.controls.newPassword"></validator-info>
					</div>
					<div class="form-group">
						<label for="passwordConfirm">{{'pages.reset_password.fields.confirmPassword' | translate}}</label>
						<input pInputText type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm" placeholder="Confirm password"/>
						<validator-info [model]="pForm.controls.passwordConfirm"></validator-info>
					</div>
				</div>
				<div class="button-group">
					<button pButton pRipple type="submit" label="{{'buttons.save'|translate}}" class="p-button-primary w-100 mb-2"></button>
				</div>
			</form>
		</div>
		<div *ngIf="step=='invalid_token'" class="alert alert-danger d-flex align-items-center" role="alert">
			<i class="pi pi-exclamation-triangle pr-1"></i>
			<div>
				{{'pages.forgot_password.expired_token_message'|translate}}
			</div>
		</div>
</div>
