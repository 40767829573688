import { ToastService } from 'src/app/modules/core/services/toast.service';
import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TeamService} from '../services/team.service';
import { ToastMsg } from '../models/ngprime/toastMsg';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		public auth: AuthenticationService,
		private toastService: ToastService,
		private router: Router,
		private teamService: TeamService
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.indexOf('i18n') > -1) {
			return next.handle(request);
		}

		return this.auth.getAuthData()
			.pipe(switchMap(r => {
				var url = request.url.toLowerCase();
				if (r.isLoggedIn) {
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${r.token}`
						}
					});
				} else if (!url.includes('identity/customer/login')
					&& !url.includes('ticketing/clientprinting/')
					&& !url.includes('ticketing/portals/getcustomerpublicportalsettings')
					&& !url.includes('identity/customer/validateprofile')
					&& !url.includes('addresses/getcountries')
					&& !url.includes('addresses/getaddresses')
					&& !url.includes('customer/isnationalidtaken')
					&& !url.includes('customer/isemailtaken')
					&& !url.includes('identitymanagement/customer/registration')
					&& !url.includes('identity/customer/forgotpassword')
					&& !url.includes('identity/customer/checkforgotpasswordtoken')
					&& !url.includes('identity/customer/changepasswordviatoken')
					&& !url.includes('identity/customer/validatelink')
					&& !url.includes('teams/getsponsors')
					&& !url.includes('teams/getteamssocialmedia')
					&& !url.includes('identity/customer/validatelink')
					&& !url.includes('assets/styles/')
					&& !url.includes('identity/customer/setpassword')) {

					this.toastService.showError(new ToastMsg({ detail: 'Unauthorized access you will be redirected to login page.' }));
					setTimeout(() => {
						this.auth.logout();
						this.router.navigate([`/${this.teamService.teamTemplate.teamId}/login`]);
					}, 1000);
					return throwError('Invalid or missing Token.');
				}
				return next.handle(request);
			}));
	}
}
