import { PaymentSystems } from '../Enum/PaymentSystems';
import {ITeamTemplate} from '../ITeamTemplate';

export class OtherTemplate implements ITeamTemplate {
	public teamName = 'Other Team';
	public teamId = 'otherTeam';
	public portalId = 1;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: 'red'
	};
}



export class OmonoiaTemplate implements ITeamTemplate {
	public teamName = 'Omonoia';
	public teamId = 'omonoia';
	public portalId = 2;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#00703C'
	};
}

export class ApoelTemplate implements ITeamTemplate {
	public teamName = 'Apoel';
	public teamId = 'apoel';
	public portalId = 3;
	public acquiringSystems = [PaymentSystems.Six];
	colors: { primary: string } = {
		primary: '#04488E'
	};
}

export class OlympiakosTemplate implements ITeamTemplate {
	public teamName = 'Olympiakos';
	public teamId = 'olympiakos';
	public portalId = 6;
	public acquiringSystems = [PaymentSystems.PBT];
	colors: { primary: string } = {
		primary: '#096F43'
	};
}

export class AekTemplate implements ITeamTemplate {
	public teamName = 'Aek';
	public teamId = 'aek';
	public portalId = 7;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#007A49'
	};
}

export class PaeekTemplate implements ITeamTemplate {
	public teamName = 'PAEEK';
	public teamId = 'paeek';
	public portalId = 10;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#2B2B2B'
	};
}

export class DoxaTemplate implements ITeamTemplate {
	public teamName = 'DOXA';
	public teamId = 'doxa';
	public portalId = 11;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#136239'
	};
}

export class KopTemplate implements ITeamTemplate {
	public teamName = 'CFA';
	public teamId = 'cfa';
	public portalId = 12;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#285c9f'
	};
}

export class SalaminaTemplate implements ITeamTemplate {
	public teamName = 'Salamina';
	public teamId = 'salamina';
	public portalId = 14;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#e1201a'
	};
}

export class AkritasTemplate implements ITeamTemplate {
	public teamName = 'Akritas';
	public teamId = 'akritas';
	public portalId = 18;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#00703C'
	};
}

export class GuestTemplate implements ITeamTemplate {
	public teamName = 'Guest';
	public teamId = 'guest';
	public portalId = 24;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#00703C'
	};
}

export class OthellosTemplate implements ITeamTemplate {
	public teamName = 'Othellos';
	public teamId = 'othellos';
	public portalId = 26;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#00703C'
	};
}

export class AezTemplate implements ITeamTemplate {
	public teamName = 'AEZ';
	public teamId = 'aez';
	public portalId = 27;
	public acquiringSystems = [PaymentSystems.JCC];
	colors: { primary: string } = {
		primary: '#00703C'
	};
}