export class FileRequest {
  fileName: string;
  file: string | undefined;

  constructor(data?: FileRequest) {
   if (data) {
    Object.assign(this, data);
   }
  }
}
