import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.scss']
})
export class SpinnerLoadingComponent {

	@Input() isLoading: boolean;
  @Input() loadingText: string;

  constructor() {
  }
}
