import {Directive, ElementRef, HostListener} from '@angular/core';
import {Input} from '@angular/core';

@Directive({
	selector: '[uppercase]'
})
export class UppercaseDirective {
	constructor(public el: ElementRef) {
		// el.nativeElement.classList.add('upper-cased-value');
	}

	@HostListener('input', ['$event'])
	onInputChange($event) {
		this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
	}
}


@Directive({
	selector: '[digitOnly]'
})
export class DigitOnlyDirective {
	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste'
	];
	inputElement: HTMLElement;

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (
			this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
			(e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
			(e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
			(e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
			(e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
			(e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
			(e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
			(e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
			(e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
		) {
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if (
			(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
			(e.keyCode < 96 || e.keyCode > 105)
		) {
			if (!e.ctrlKey) {
				e.preventDefault();
			}

		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const pastedInput: string = event.clipboardData
			.getData('text/plain')
			.replace(/\D/g, ''); // get a digit-only string
		document.execCommand('insertText', false, pastedInput);
	}

	@HostListener('drop', ['$event'])
	onDrop(event: any) {
		event.preventDefault();
		const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
		this.inputElement.focus();
		document.execCommand('insertText', false, textData);
	}
}


@Directive({
	selector: '[patterEntry]'
})
export class PatternEntryDirective {
	@Input() patterEntry: string;
	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste'
	];
	inputElement: HTMLElement;

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;

	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (
			this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
			(e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
			(e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
			(e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
			(e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
			(e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
			(e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
			(e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
			(e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
		) {
			// let it happen, don't do anything
			return;
		}
		const regex = new RegExp(this.patterEntry);
		if (regex.exec(e.key)) {
			if (e.ctrlKey) {
				e.preventDefault();
			}
			return;
		}
		e.preventDefault();
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const pastedInput: string = event.clipboardData
			.getData('text/plain')
			.replace(/\D/g, ''); // get a digit-only string
		document.execCommand('insertText', false, pastedInput);
	}

	@HostListener('drop', ['$event'])
	onDrop(event: any) {
		event.preventDefault();
		const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
		this.inputElement.focus();
		document.execCommand('insertText', false, textData);
	}
}

