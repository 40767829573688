import { Injectable } from '@angular/core';
import { AuthenticationServiceHttp } from '../../../http-services/authentication-http.service';
import { LoginRequest } from '../../../view-models/login-request';
import { map, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TeamService } from './team.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { ApiResponse } from '../../../view-models/api-response';
import { InvalidField, LoginResponse } from '../../../view-models/login-response';
import { Base64 } from 'base64-string';
import { RefreshTokenRequest } from '../../../view-models/refreshToken-request';
import { UserIdleService } from './idle-service';
import { TicketSaleService } from '../../main-module/services/sales/ticket-sale.service';
import { decode } from 'js-base64';
import { PlatformService } from './platform.service';
import { InactivityDialogComponent } from '../../shared-module/components/inactivity-dialog/inactivity-dialog.component';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { ToastMsg } from '../models/ngprime/toastMsg';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private identifierSchema = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
	private isAccountValidatedClaimId = 'IsAccountValidated';

	idleTimerLeft: string;
	secondTimerLeft: string;
	timeRemain: number;
	private timerStarted: boolean;
	private Sub :Subscription;
	private Sub2 :Subscription;

	constructor(
		private authenticationServiceHttp: AuthenticationServiceHttp,
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private userIdleService: UserIdleService,
		private platformService: PlatformService,
		private teamService: TeamService,
		private saleService: TicketSaleService,
		public dialogService: DialogService,
		private translateService: TranslateService,
	) {
	}

	public isLoggedIn(): Observable<boolean> {
		return this.route.paramMap
			.pipe(switchMap((paramMap) => {
				const team = paramMap.get('team');
				const token = this.cookieService.get(`token-${team}`);
				const result = token;
				return result ? of(true) : of(false);
			}));
	}

	public getAuthData(): Observable<{ isLoggedIn: boolean, token: string }> {
		if (this.route.firstChild) {
			// first child because this.route.component = AppComponent (root)
			// so here we get the first child of it - should be either main-layout\unauth-layout.
			return this.route.firstChild.paramMap
				.pipe(switchMap((paramMap) => {
					const team = paramMap.get('team');
					const token = this.cookieService.get(`token-${team}`);
					const result = token;
					if (result) {
						this.initTimer(team);
						return of({ isLoggedIn: true, token: token });
					} else {
						return of({ isLoggedIn: false, token: '' });
					}
				}));
		}
	}


	public isLoggedInPortal(teamCode: string): string | boolean {
		const token = this.cookieService.get(`token-${teamCode}`);
		return (!!token) && !this.tokenExpired(token);
	}

	public isValidatedAccount(teamCode: string): boolean {
		const token = this.cookieService.get(`token-${teamCode}`);
		return this.getIsAccountValidated(token);
	}

	public checkCustomersDataValidation(): Observable<ApiResponse<InvalidField[]>> {
		return this.authenticationServiceHttp.checkCustomersDataValidity();
	}


	public login(loginRequest: LoginRequest): Observable<ApiResponse<LoginResponse>> {
		this.saleService.clearCart();
		let teamCode = 'NONE';
		return this.route.firstChild.paramMap
			.pipe(switchMap((paramMap) => {
				teamCode = paramMap.get('team');
				return this.authenticationServiceHttp.login(loginRequest);
			}),
				map(result => {
					if (result.isSuccess) {
						const domain = window.location.hostname;
						this.setCookies(result, teamCode, domain);
						this.initTimer(teamCode);
					} else {
						this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));
					}
					return result;
				}));
	}

	private getIdentityId(jwt: string): number {
		return +this.getClaim(jwt, this.identifierSchema);
	}


	public getIsAccountValidated(jwt: string): boolean {

		return this.getClaim(jwt, this.isAccountValidatedClaimId) == 'True';
	}

	private getClaim(jwt: string, claimId: string): any {
		const payload = jwt.split('.')[1];
		const modifiedPayload = `${payload}==`;
		const enc = new Base64();
		const b64 = enc.decode(modifiedPayload);
		const payloadObject = JSON.parse(b64);
		return payloadObject[claimId];
	}

	private tokenExpired(token: string) {
		const expiry = (JSON.parse(decode(token.split('.')[1]))).exp;
		return (Math.floor((new Date).getTime() / 1000)) >= expiry;
	}


	public logout(): Observable<boolean> {
		let teamCode = 'NONE';
		return this.route.firstChild.paramMap
			.pipe(switchMap((paramMap) => {
				teamCode = paramMap.get('team');
				const domain = window.location.hostname;
				this.deleteCookies(teamCode, domain);
				this.saleService.clearCart();
				return of(true);
			}));
	}


	deleteCookies(teamCode: string, domain: string) {
		if (this.platformService.getBrowserName() != 'safari') {
			this.cookieService.delete(`token-${teamCode}`, '*', domain, true, 'Strict');
			this.cookieService.delete(`token-${teamCode}`, '/', domain, true, 'Strict');
			this.cookieService.delete(`token-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
			this.cookieService.delete(`refreshToken-${teamCode}`, '*', domain, true, 'Strict');
			this.cookieService.delete(`refreshToken-${teamCode}`, '/', domain, true, 'Strict');
			this.cookieService.delete(`refreshToken-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
			this.cookieService.delete(`identityId-${teamCode}`, '*', domain, true, 'Strict');
			this.cookieService.delete(`identityId-${teamCode}`, '/', domain, true, 'Strict');
			this.cookieService.delete(`identityId-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
		} else {
			this.cookieService.delete(`token-${teamCode}`, '*');
			this.cookieService.delete(`token-${teamCode}`, '/');
			this.cookieService.delete(`token-${teamCode}`, `/${teamCode}`);
			this.cookieService.delete(`refreshToken-${teamCode}`, '*');
			this.cookieService.delete(`refreshToken-${teamCode}`, '/');
			this.cookieService.delete(`refreshToken-${teamCode}`, `/${teamCode}`);
			this.cookieService.delete(`identityId-${teamCode}`, '*');
			this.cookieService.delete(`identityId-${teamCode}`, '/');
			this.cookieService.delete(`identityId-${teamCode}`, `/${teamCode}`);
		}
	}

	private setCookies(result: ApiResponse<LoginResponse>, teamCode: string, domain: string) {
		if (this.platformService.getBrowserName() != 'safari') {
			this.cookieService.set(`token-${teamCode}`, result.data.token,
				new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
			const identityId = this.getIdentityId(result.data.token);
			this.cookieService.set(`refreshToken-${teamCode}`, result.data.refreshToken, new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
			this.cookieService.set(`identityId-${teamCode}`, identityId.toString(), new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
		} else {
			this.cookieService.set(`token-${teamCode}`, result.data.token,
				new Date(result.data.accessTokenExpiration), `/${teamCode}`);
			const identityId = this.getIdentityId(result.data.token);
			this.cookieService.set(`refreshToken-${teamCode}`, result.data.refreshToken, new Date(result.data.accessTokenExpiration), `/${teamCode}`);
			this.cookieService.set(`identityId-${teamCode}`, identityId.toString(), new Date(result.data.accessTokenExpiration), `/${teamCode}`);
		}
	}


	public refreshToken(): Observable<ApiResponse<LoginResponse>> {
		let teamCode = 'NONE';

		return this.route.firstChild.paramMap
			.pipe(switchMap((paramMap) => {
				teamCode = paramMap.get('team');
				const refreshTokenRequest: RefreshTokenRequest = new RefreshTokenRequest({ refreshToken: this.cookieService.get(`refreshToken-${teamCode}`) });
				return this.authenticationServiceHttp.refreshToken(refreshTokenRequest);
			}),
				map(result => {
					if (result.isSuccess) {
						const domain = window.location.hostname;
						this.setCookies(result, teamCode, domain);
						this.timerStarted = false;
						this.initTimer(teamCode);
					} else {
						this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));
					}
					return result;
				}));
	}

	private getTokenTimeout(teamCode: string) {
		// parse json object from base64 encoded jwt token
		const token = this.cookieService.get(`token-${teamCode}`);
		const jwtToken = JSON.parse(decode(token.split('.')[1]));

		// set a timeout to refresh the token a minute before it expires
		const expires = new Date(jwtToken.exp * 1000);
		return Math.round((((expires.getTime() - Date.now()) % 86400000) % 3600000) / 60000) - 2;
	}

	initTimer(teamCode: string): void {
		if (!this.timerStarted) {
			this.userIdleService.USER_IDLE_TIMER_VALUE_IN_MIN = this.getTokenTimeout(teamCode);
			// Watcher on timer
			this.userIdleService.initilizeSessionTimeout();
			if(this.Sub) this.Sub.unsubscribe();
			this.Sub = this.userIdleService.userIdlenessChecker.subscribe((status: string) => {
				this.initiateFirstTimer(status);
			});

			if(this.Sub2) this.Sub2.unsubscribe();
			this.Sub2 = this.userIdleService.secondLevelUserIdleChecker.subscribe((status: string) => {
				this.initiateSecondTimer(status);
			});
			this.timerStarted = true;
		}
	}

	initiateFirstTimer = (status: string) => {
		switch (status) {
			case 'INITIATE_TIMER':
				break;

			case 'RESET_TIMER':
				break;

			case 'STOPPED_TIMER':
				this.showSendTimerDialog();
				break;
			default:
				break;
		}
	};

	initiateSecondTimer = (status: string) => {
		switch (status) {
			case 'INITIATE_SECOND_TIMER':
				break;

			case 'SECOND_TIMER_STARTED':
				break;

			case 'SECOND_TIMER_STOPPED':
				this.triggerLogout();
				break;

			default:
				this.secondTimerLeft = status;
				break;
		}
	};

	showSendTimerDialog() {
		this.dialogService.open(InactivityDialogComponent, {
      header: this.translateService.instant('shared_components.inactivity_dialog.header'),
      width: '520px',
      data: this.secondTimerLeft || 'a few',
      contentStyle: { "overflow": "auto" },
      dismissableMask: false,
    }).onClose.subscribe(response => {
      response ? this.continue() : this.triggerLogout();
    });
	}

	continue(): void {
		UserIdleService.runSecondTimer = false;
		this.refreshToken().subscribe();
		this.Sub.unsubscribe();
		this.Sub2.unsubscribe();
	}

	triggerLogout(): void {
		this.timerStarted = false;
		UserIdleService.runTimer = false;
		UserIdleService.runSecondTimer = false;
		this.saleService.clearCart();
		this.logout().subscribe(isLoggedOut => {
			location.href = `/${this.teamService.teamTemplate.teamId}/login`;
		});
		this.Sub.unsubscribe();
		this.Sub2.unsubscribe();
	}
}
