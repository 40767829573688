/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/primeng-lts/card/primeng-lts-card.ngfactory";
import * as i3 from "primeng-lts/card";
import * as i4 from "./success.component";
import * as i5 from "../../../../core/services/team.service";
import * as i6 from "../../../../core/services/registration.service";
import * as i7 from "@angular/router";
var styles_SuccessComponent = [i0.styles];
var RenderType_SuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessComponent, data: {} });
export { RenderType_SuccessComponent as RenderType_SuccessComponent };
export function View_SuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "main-container py-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "p-card", [["class", "success-card px-4"]], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(2, 1097728, null, 3, i3.Card, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { headerFacet: 0 }), i1.ɵqud(335544320, 2, { footerFacet: 0 }), i1.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i1.ɵeld(6, 0, null, 1, 1, "span", [["class", "header mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Success!"])), (_l()(), i1.ɵeld(8, 0, null, 1, 1, "span", [["class", "icon mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "pi pi-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, 1, 1, "p", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Success! You now have now joined our Football Ticketing Service."])), (_l()(), i1.ɵeld(12, 0, null, 1, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We've sent you a confirmation email containing your account details. Please follow the link and set your password. These can be changed anytime, by visiting your My Account section."]))], null, null); }
export function View_SuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "success", [], null, null, null, View_SuccessComponent_0, RenderType_SuccessComponent)), i1.ɵdid(1, 114688, null, 0, i4.SuccessComponent, [i5.TeamService, i6.RegistrationService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessComponentNgFactory = i1.ɵccf("success", i4.SuccessComponent, View_SuccessComponent_Host_0, {}, {}, []);
export { SuccessComponentNgFactory as SuccessComponentNgFactory };
