import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RadioControlRegistry } from 'primeng-lts/radiobutton';
import { PortalServiceHttp } from 'src/app/http-services/portal-http-service';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';
import { RegistrationService } from 'src/app/modules/core/services/registration.service';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';
import { untilToday } from 'src/app/modules/core/validators/date-validators';
import { RegistrationRequest } from 'src/app/view-models/registration-request';
import { FileRequest } from 'src/app/view-models/file-request';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';

@Component({
  selector: 'ticketing-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['../registration-form.scss', './profile-info.component.scss'],
	providers: [RadioControlRegistry]
})
export class ProfileInfoComponent extends TeamTemplateComponent implements OnInit {
	public template: ITeamTemplate;
	public form: FormGroup;
	public phoneRegexpPattern = /(^[+]{0,1})([\d])*$/;
	public isCfa: boolean = false;
	public isBackPressed: any;
	public currentRegistration: RegistrationRequest;
	public selectedValue: string;
	public isParentalConsentEnabled: boolean = false;

  constructor(
		private router: Router,
		private registrationService: RegistrationService,
		templateService: TeamService,
		private formBuilder: FormBuilder,
		private portalServiceHttp: PortalServiceHttp,
		private cookieService: CookieService,
		private toastService: ToastService,
		private translateService: TranslateService
		) {
			super(templateService);
			this.template = templateService.teamTemplate;
		}

	get isFormUploaded() {
		
		if (this.form.controls['parentalConsentFile'].value == null) 
		if (this.isParentalConsentEnabled && this.calculateDiff(this.form.controls.dateOfBirth.value) < 6574) 
		{
			return false;
		}
		return true;
	}

  ngOnInit() {
		this.loadDataIfExist();
		this.initForm();
		this.checkIfValidRedirection();
		this.isCfa = this.template.teamName == 'CFA';
		this.registrationService.setIndex(1);
		this.getIsCustomerParentalConsentEnabled();
	}

	previousItem() {
		this.router.navigate([`${this.template.teamId}/steps/registration`]);
  }

	nextItem() {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		this.updateRegisterInformation();
		this.router.navigate([`${this.template.teamId}/steps/address-information`]);
  }
  onFileSelect(event) {
	
		if (this.calculateDiff(this.form.controls.dateOfBirth.value) < 6574 && !this.form.controls['parentalConsentFile'].value) {
			const text = this.translateService.instant('shared_components.cart.messages.next_step');
			this.toastService.showWarning(new ToastMsg({ detail: text }));
			return;
		}
	
				return;
  }

	onFileUpload(event: any) {
		for (let file of event.files) {
      let fileReader = new FileReader();
      let fileName = new Date().toDateString() + "-" + file.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
				if (!fileReader.result) {
					this.toastService.showError(new ToastMsg({ detail: 'File did not uploaded correctly' }));
					return;
				}

        var file: FileRequest = new FileRequest({
					fileName: fileName,
					file: fileReader.result.toString().replace('data:', '').replace(/^.+,/, ''),
				});

				this.form.controls['parentalConsentFile'].patchValue(file);
				this.toastService.showSuccess(new ToastMsg({ detail: 'File saved successfully' }));
			};
		}
  }

	calculateDiff(dateSent){
		let currentDate = new Date();
		dateSent = new Date(dateSent);

		//returns the difference of the Two dates in days
		return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
	 }

	 loadDocuments(documentName: string) {
		let path = `/assets/attachments/${this.teamName}/${documentName}`;
		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.target = '_blank';
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}

	private getIsCustomerParentalConsentEnabled() {
		this.portalServiceHttp.GetPublicPortalSettings().subscribe((response): void => {
			if (!response.isSuccess) return;
			let IsCustomerParentalConsentEnabled = response.data.results.find(x => x.code === 'IsCustomerParentalConsentEnabled');
			this.isParentalConsentEnabled = IsCustomerParentalConsentEnabled ? IsCustomerParentalConsentEnabled.value == 'true' : false;
		});
	}


	private initForm() {
		this.form = this.formBuilder.group({
			sex: [this.currentRegistration.sex, [Validators.required]],
			mobile: [this.currentRegistration.mobile, [Validators.required, Validators.pattern(/(^[+]{0,1})([\d])*$/)]],
			phone: [this.currentRegistration.phone],
			dateOfBirth: [this.currentRegistration.dateOfBirth, [Validators.required, untilToday()]],
			parentalConsentFile: [null],
		});
  }

	private updateRegisterInformation() {
		if (this.isParentalConsentEnabled) {
			if (this.calculateDiff(this.form.controls.dateOfBirth.value) < 6574 && !this.form.controls['parentalConsentFile'].value) {
				const field = this.translateService.instant('pages.registration.parentalConsentForm');
				const message = this.translateService.instant('pages.registration.validation.required', {field: field});
				this.toastService.showWarning(new ToastMsg({ detail: message }));
				return;
			}
		}

		this.registrationService.updateInfo(this.form.value);
	}

	private loadDataIfExist() {
		if (this.registrationService.getRegistrationInformation()) {
			this.currentRegistration = this.registrationService.getRegistrationInformation();
		}
		else {
			this.currentRegistration = new RegistrationRequest();
		}
	}

	private checkIfValidRedirection() {
		if(!this.registrationService.getRegistrationInformation()) { this.previousItem(); }
	}
}
