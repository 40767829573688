import { AuthenticationServiceHttp } from '../../../http-services/authentication-http.service';
import { map, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TeamService } from './team.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { Base64 } from 'base64-string';
import { RefreshTokenRequest } from '../../../view-models/refreshToken-request';
import { UserIdleService } from './idle-service';
import { TicketSaleService } from '../../main-module/services/sales/ticket-sale.service';
import { decode } from 'js-base64';
import { PlatformService } from './platform.service';
import { InactivityDialogComponent } from '../../shared-module/components/inactivity-dialog/inactivity-dialog.component';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { ToastMsg } from '../models/ngprime/toastMsg';
import * as i0 from "@angular/core";
import * as i1 from "../../../http-services/authentication-http.service";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
import * as i4 from "./toast.service";
import * as i5 from "./idle-service";
import * as i6 from "./platform.service";
import * as i7 from "./team.service";
import * as i8 from "../../main-module/services/sales/ticket-sale.service";
import * as i9 from "primeng-lts/dynamicdialog";
import * as i10 from "@ngx-translate/core";
export class AuthenticationService {
    constructor(authenticationServiceHttp, cookieService, route, toastService, userIdleService, platformService, teamService, saleService, dialogService, translateService) {
        this.authenticationServiceHttp = authenticationServiceHttp;
        this.cookieService = cookieService;
        this.route = route;
        this.toastService = toastService;
        this.userIdleService = userIdleService;
        this.platformService = platformService;
        this.teamService = teamService;
        this.saleService = saleService;
        this.dialogService = dialogService;
        this.translateService = translateService;
        this.identifierSchema = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
        this.isAccountValidatedClaimId = 'IsAccountValidated';
        this.initiateFirstTimer = (status) => {
            switch (status) {
                case 'INITIATE_TIMER':
                    break;
                case 'RESET_TIMER':
                    break;
                case 'STOPPED_TIMER':
                    this.showSendTimerDialog();
                    break;
                default:
                    break;
            }
        };
        this.initiateSecondTimer = (status) => {
            switch (status) {
                case 'INITIATE_SECOND_TIMER':
                    break;
                case 'SECOND_TIMER_STARTED':
                    break;
                case 'SECOND_TIMER_STOPPED':
                    this.triggerLogout();
                    break;
                default:
                    this.secondTimerLeft = status;
                    break;
            }
        };
    }
    isLoggedIn() {
        return this.route.paramMap
            .pipe(switchMap((paramMap) => {
            const team = paramMap.get('team');
            const token = this.cookieService.get(`token-${team}`);
            const result = token;
            return result ? of(true) : of(false);
        }));
    }
    getAuthData() {
        if (this.route.firstChild) {
            // first child because this.route.component = AppComponent (root)
            // so here we get the first child of it - should be either main-layout\unauth-layout.
            return this.route.firstChild.paramMap
                .pipe(switchMap((paramMap) => {
                const team = paramMap.get('team');
                const token = this.cookieService.get(`token-${team}`);
                const result = token;
                if (result) {
                    this.initTimer(team);
                    return of({ isLoggedIn: true, token: token });
                }
                else {
                    return of({ isLoggedIn: false, token: '' });
                }
            }));
        }
    }
    isLoggedInPortal(teamCode) {
        const token = this.cookieService.get(`token-${teamCode}`);
        return (!!token) && !this.tokenExpired(token);
    }
    isValidatedAccount(teamCode) {
        const token = this.cookieService.get(`token-${teamCode}`);
        return this.getIsAccountValidated(token);
    }
    checkCustomersDataValidation() {
        return this.authenticationServiceHttp.checkCustomersDataValidity();
    }
    login(loginRequest) {
        this.saleService.clearCart();
        let teamCode = 'NONE';
        return this.route.firstChild.paramMap
            .pipe(switchMap((paramMap) => {
            teamCode = paramMap.get('team');
            return this.authenticationServiceHttp.login(loginRequest);
        }), map(result => {
            if (result.isSuccess) {
                const domain = window.location.hostname;
                this.setCookies(result, teamCode, domain);
                this.initTimer(teamCode);
            }
            else {
                this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));
            }
            return result;
        }));
    }
    getIdentityId(jwt) {
        return +this.getClaim(jwt, this.identifierSchema);
    }
    getIsAccountValidated(jwt) {
        return this.getClaim(jwt, this.isAccountValidatedClaimId) == 'True';
    }
    getClaim(jwt, claimId) {
        const payload = jwt.split('.')[1];
        const modifiedPayload = `${payload}==`;
        const enc = new Base64();
        const b64 = enc.decode(modifiedPayload);
        const payloadObject = JSON.parse(b64);
        return payloadObject[claimId];
    }
    tokenExpired(token) {
        const expiry = (JSON.parse(decode(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
    logout() {
        let teamCode = 'NONE';
        return this.route.firstChild.paramMap
            .pipe(switchMap((paramMap) => {
            teamCode = paramMap.get('team');
            const domain = window.location.hostname;
            this.deleteCookies(teamCode, domain);
            this.saleService.clearCart();
            return of(true);
        }));
    }
    deleteCookies(teamCode, domain) {
        if (this.platformService.getBrowserName() != 'safari') {
            this.cookieService.delete(`token-${teamCode}`, '*', domain, true, 'Strict');
            this.cookieService.delete(`token-${teamCode}`, '/', domain, true, 'Strict');
            this.cookieService.delete(`token-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
            this.cookieService.delete(`refreshToken-${teamCode}`, '*', domain, true, 'Strict');
            this.cookieService.delete(`refreshToken-${teamCode}`, '/', domain, true, 'Strict');
            this.cookieService.delete(`refreshToken-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
            this.cookieService.delete(`identityId-${teamCode}`, '*', domain, true, 'Strict');
            this.cookieService.delete(`identityId-${teamCode}`, '/', domain, true, 'Strict');
            this.cookieService.delete(`identityId-${teamCode}`, `/${teamCode}`, domain, true, 'Strict');
        }
        else {
            this.cookieService.delete(`token-${teamCode}`, '*');
            this.cookieService.delete(`token-${teamCode}`, '/');
            this.cookieService.delete(`token-${teamCode}`, `/${teamCode}`);
            this.cookieService.delete(`refreshToken-${teamCode}`, '*');
            this.cookieService.delete(`refreshToken-${teamCode}`, '/');
            this.cookieService.delete(`refreshToken-${teamCode}`, `/${teamCode}`);
            this.cookieService.delete(`identityId-${teamCode}`, '*');
            this.cookieService.delete(`identityId-${teamCode}`, '/');
            this.cookieService.delete(`identityId-${teamCode}`, `/${teamCode}`);
        }
    }
    setCookies(result, teamCode, domain) {
        if (this.platformService.getBrowserName() != 'safari') {
            this.cookieService.set(`token-${teamCode}`, result.data.token, new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
            const identityId = this.getIdentityId(result.data.token);
            this.cookieService.set(`refreshToken-${teamCode}`, result.data.refreshToken, new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
            this.cookieService.set(`identityId-${teamCode}`, identityId.toString(), new Date(result.data.accessTokenExpiration), `/${teamCode}`, domain, true, 'Strict');
        }
        else {
            this.cookieService.set(`token-${teamCode}`, result.data.token, new Date(result.data.accessTokenExpiration), `/${teamCode}`);
            const identityId = this.getIdentityId(result.data.token);
            this.cookieService.set(`refreshToken-${teamCode}`, result.data.refreshToken, new Date(result.data.accessTokenExpiration), `/${teamCode}`);
            this.cookieService.set(`identityId-${teamCode}`, identityId.toString(), new Date(result.data.accessTokenExpiration), `/${teamCode}`);
        }
    }
    refreshToken() {
        let teamCode = 'NONE';
        return this.route.firstChild.paramMap
            .pipe(switchMap((paramMap) => {
            teamCode = paramMap.get('team');
            const refreshTokenRequest = new RefreshTokenRequest({ refreshToken: this.cookieService.get(`refreshToken-${teamCode}`) });
            return this.authenticationServiceHttp.refreshToken(refreshTokenRequest);
        }), map(result => {
            if (result.isSuccess) {
                const domain = window.location.hostname;
                this.setCookies(result, teamCode, domain);
                this.timerStarted = false;
                this.initTimer(teamCode);
            }
            else {
                this.toastService.showError(new ToastMsg({ detail: result.errorMessage }));
            }
            return result;
        }));
    }
    getTokenTimeout(teamCode) {
        // parse json object from base64 encoded jwt token
        const token = this.cookieService.get(`token-${teamCode}`);
        const jwtToken = JSON.parse(decode(token.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        return Math.round((((expires.getTime() - Date.now()) % 86400000) % 3600000) / 60000) - 2;
    }
    initTimer(teamCode) {
        if (!this.timerStarted) {
            this.userIdleService.USER_IDLE_TIMER_VALUE_IN_MIN = this.getTokenTimeout(teamCode);
            // Watcher on timer
            this.userIdleService.initilizeSessionTimeout();
            if (this.Sub)
                this.Sub.unsubscribe();
            this.Sub = this.userIdleService.userIdlenessChecker.subscribe((status) => {
                this.initiateFirstTimer(status);
            });
            if (this.Sub2)
                this.Sub2.unsubscribe();
            this.Sub2 = this.userIdleService.secondLevelUserIdleChecker.subscribe((status) => {
                this.initiateSecondTimer(status);
            });
            this.timerStarted = true;
        }
    }
    showSendTimerDialog() {
        this.dialogService.open(InactivityDialogComponent, {
            header: this.translateService.instant('shared_components.inactivity_dialog.header'),
            width: '520px',
            data: this.secondTimerLeft || 'a few',
            contentStyle: { "overflow": "auto" },
            dismissableMask: false,
        }).onClose.subscribe(response => {
            response ? this.continue() : this.triggerLogout();
        });
    }
    continue() {
        UserIdleService.runSecondTimer = false;
        this.refreshToken().subscribe();
        this.Sub.unsubscribe();
        this.Sub2.unsubscribe();
    }
    triggerLogout() {
        this.timerStarted = false;
        UserIdleService.runTimer = false;
        UserIdleService.runSecondTimer = false;
        this.saleService.clearCart();
        this.logout().subscribe(isLoggedOut => {
            location.href = `/${this.teamService.teamTemplate.teamId}/login`;
        });
        this.Sub.unsubscribe();
        this.Sub2.unsubscribe();
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthenticationServiceHttp), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i4.ToastService), i0.ɵɵinject(i5.UserIdleService), i0.ɵɵinject(i6.PlatformService), i0.ɵɵinject(i7.TeamService), i0.ɵɵinject(i8.TicketSaleService), i0.ɵɵinject(i9.DialogService), i0.ɵɵinject(i10.TranslateService)); }, token: AuthenticationService, providedIn: "root" });
