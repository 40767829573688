import { Subject } from "rxjs";
import { RegistrationRequest } from "src/app/view-models/registration-request";
import * as i0 from "@angular/core";
export class RegistrationService {
    constructor() {
        this.currentActiveIndex = 0;
        this.isRegistrationComplete = false;
        this.indexSubject = new Subject();
        this.indexObservable = this.indexSubject.asObservable();
    }
    updateInfo(registrationRequest) {
        this.registrationInformation = Object.assign({}, this.registrationInformation, registrationRequest);
    }
    clearInformation() {
        this.registrationInformation = new RegistrationRequest();
    }
    getRegistrationInformation() {
        return this.registrationInformation;
    }
    setCurrentActiveIndex(activeIndex) {
        this.currentActiveIndex = activeIndex;
    }
    getCurrentActiveIndex() {
        return this.currentActiveIndex;
    }
    setIndex(index) {
        this.indexSubject.next(index);
    }
    getIndexAsObservable() {
        return this.indexObservable;
    }
    getIsRegistrationComplete() {
        return this.isRegistrationComplete;
    }
    setIsRegistrationComplete(isCompleted) {
        this.isRegistrationComplete = isCompleted;
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(); }, token: RegistrationService, providedIn: "root" });
