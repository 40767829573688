import { Guid } from '../../../core/types/guid';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Cart } from './cart';
import { CartItem } from './cartItem';
import { map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { FanInformation } from '../../models/season-ticket-info';
import { FanAndPriceResponse } from '../../../../view-models/create-ticket.request';
import { SalesHttpService } from '../../../../http-services/ticket-sales-http.service';
import { ProductTypeEnum } from '../../models/product-type-enum';
import { CookieService } from 'ngx-cookie-service';
import { SaleStatusEnum } from '../../models/sale-status-enum';
import { SoldTicketStatuses } from '../../models/soldTicket-status-enum';
import { NationalIdRequest } from 'src/app/view-models/NationalIdRequest';
import { SingleEventPackageProducts } from 'src/app/view-models/products-response';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../../http-services/ticket-sales-http.service";
import * as i3 from "../../../core/services/toast.service";
import * as i4 from "ngx-cookie-service";
export class CartService {
    constructor(route, saleHttpService, toastService, cookiesService, router) {
        this.route = route;
        this.saleHttpService = saleHttpService;
        this.toastService = toastService;
        this.cookiesService = cookiesService;
        this.router = router;
        this.onCartChangeEvent = new BehaviorSubject(null);
        this._expiryTime = 10;
        this.lockExpiryInMinutes = 10;
        this.selectedSeasonTicketTypeId = 0;
    }
    getProductType() {
        if (this.isEmpty() || !this.cart.items[0].ticket) {
            return ProductTypeEnum.SimpleTicket;
        }
        return this.cart.items[0].ticket.productType;
    }
    updateProduct(cartItem, changeAmount = 1) {
        if (this.cart == null
            || this.cart.singleEventPackageProducts == null
            || this.cart.singleEventPackageProducts.Products == null
            || cartItem.fanInformation == null)
            return;
        let products = this.cart.singleEventPackageProducts.Products;
        let product = products.find(p => p.simpleTicketId == cartItem.fanInformation.simpleTicketId);
        if (product) {
            if (product.soldTicketId == cartItem.ticket.soldTicketId) {
                //this ticket has already been counted
                return;
            }
            product.soldTicketId = cartItem.ticket.soldTicketId;
            product.currentCount += changeAmount;
            this.validateProducts();
        }
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
    }
    validateProducts() {
        if (this.cart == null || this.cart.singleEventPackageProducts == null || this.cart.singleEventPackageProducts.Products == null) {
            return;
        }
        let ticketCount = 0, packageCount = 0, packageCommon = 1;
        this.cart.singleEventPackageProducts.Products.forEach(p => {
            ticketCount += p.currentCount;
            packageCount += p.productCount;
            packageCommon *= p.productCount;
        });
        if (ticketCount % packageCount != 0) {
            this.cart.singleEventPackageProducts.IsValid = false;
            return;
        }
        this.cart.singleEventPackageProducts.IsValid = true;
        let item0 = this.cart.singleEventPackageProducts.Products[0];
        let commonRatio = item0.currentCount * packageCommon / item0.productCount;
        this.cart.singleEventPackageProducts.Products.forEach(product => {
            let currentRatio = product.currentCount * packageCommon / product.productCount;
            if (currentRatio != commonRatio) {
                this.cart.singleEventPackageProducts.IsValid = false;
                return;
            }
        });
    }
    addToCart(cartItem) {
        this.cart.items.push(cartItem);
        cartItem.expiry = this.getExpiryTime(this._expiryTime);
        if (!this.cart.saleId) {
            this.setSaleId(cartItem.ticket.saleId);
        }
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.updateProduct(cartItem, 1);
        this.onCartChangeEvent.next('addToCart');
    }
    removeFromCart(ticketId) {
        const cartItemIndex = this.cart.items.findIndex(x => getTicketId(x.ticket) === ticketId);
        let removedItem = this.cart.items.splice(cartItemIndex, 1);
        if (!this.cart.items.length) {
            this.clearCart();
        }
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.updateProduct(removedItem[0], -1);
        this.onCartChangeEvent.next('removeFromCart');
    }
    addMemberShipFeeToTicket(ticketId) {
        if (!this.cart.ticketIdsWithMembershipFee.includes(ticketId)) {
            this.cart.ticketIdsWithMembershipFee.push(ticketId);
        }
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('addMemberShipFeeToTicket');
    }
    removeMemberShipFeeToTicket(ticketId) {
        if (this.cart.ticketIdsWithMembershipFee.includes(ticketId)) {
            this.cart.ticketIdsWithMembershipFee.forEach((value, index) => {
                if (value === ticketId) {
                    this.cart.ticketIdsWithMembershipFee.splice(index, 1);
                }
            });
        }
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('removeMemberShipFeeToTicket');
    }
    getSaleTotal(request) {
        return this.saleHttpService.getSaleTotal(request);
    }
    setFanToCartItem(fanInformation, soldTicketId) {
        const fan = new FanInformation();
        fan.lastName = fanInformation.surname;
        fan.firstName = fanInformation.name;
        fan.dateOfBirth = fanInformation.dob;
        fan.ticketPrice = fanInformation.price;
        fan.fanCardId = fanInformation.id;
        fan.nationalId = fanInformation.cy_id;
        fan.packagePrice = fanInformation.packagePrice;
        fan.isChild = fanInformation.isChild;
        fan.isRegisteredFan = fanInformation.isRegisteredFan;
        fan.simpleTicketId = fanInformation.simpleTicketId;
        const item = this.cart.items.find(d => getTicketId(d.ticket) === soldTicketId);
        this.setFanDispalyName(fan);
        item.fanInformation = fan;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('setFanToCartItem');
    }
    setFanDispalyName(fan) {
        if (fan.fanCardId) {
            fan.displayInfo = fan.fanCardId;
        }
        else {
            let length = fan.nationalId.length;
            let kept = 3;
            let removed = length - kept;
            let str = '';
            if (removed > 0)
                str += "*".repeat(removed);
            str += fan.nationalId.substr(-kept);
            fan.displayInfo = "Child: " + str;
        }
    }
    setSeason(season) {
        this.cart.season = season;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('setSeason');
    }
    getSeason() {
        return this.cart.season;
    }
    clearSeason() {
        delete this.cart.season;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.setSeasonTicketTypeId(0);
        this.onCartChangeEvent.next('clearSeason');
    }
    setEvent(event) {
        this.cart.event = event;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('setEvent');
    }
    getCart() {
        return this.cart;
    }
    getEvent() {
        return this.cart.event;
    }
    getCartItemsEvent() {
        return this.cart.items.length > 0 ? this.cart.items[0].ticket.eventId : 0;
    }
    clearEvent() {
        delete this.cart.event;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('clearEvent');
    }
    setOrderId(orderId) {
        this.cart.orderId = orderId;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('setOrderId');
    }
    isEmpty() {
        return !this.cart.items.length;
    }
    getExpiryTime(min) {
        const now = new Date();
        const expiryTime = new Date(now.getTime() + min * 60000);
        return +expiryTime;
    }
    loadCart(teamCode) {
        const result$ = new BehaviorSubject(null);
        this.checkCart(teamCode).subscribe(result => result$.next(result));
        setInterval(() => {
            this.checkCart(teamCode).subscribe(result => result$.next(result));
        }, 10 * 1000);
        return result$;
    }
    checkCart(teamCode) {
        return this.getRouteParameters(this.route.snapshot).pipe(map(r => {
            const identityId = this.cookiesService.get(`identityId-${teamCode}`);
            this.cartSubKey = `${r['team']}-${identityId}`;
            let cart = JSON.parse(localStorage.getItem(`${this.cartSubKey}-cart`));
            if (!cart) {
                cart = new Cart(Guid.newGuid());
                localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(cart));
            }
            else {
                let hasDeletedAnything = false;
                if (cart.items.length > 0 && cart.items[0].expiry <= +new Date()) {
                    cart.items.forEach((item, index) => {
                        cart.items.splice(index, 1);
                        hasDeletedAnything = true;
                        this.updateProduct(item, -1);
                    });
                }
                if (!cart.items.length) {
                    if (hasDeletedAnything) {
                        cart.saleId = null;
                        cart.ticketIdsWithMembershipFee = [];
                        cart.isLocked = false;
                        cart.LockerTabId = null;
                        cart.isCheckedOutAndPaid = false;
                        this.toastService.showWarning(new ToastMsg({ detail: 'The cart has been erased due to the limited time of tickets/seat reservation. ' }));
                        cart.lockExpiryTime = null;
                        this.router.navigate([`${teamCode}/main`]);
                    }
                }
                if (hasDeletedAnything) {
                    localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(cart));
                }
            }
            this.cart = cart;
            return cart;
        }));
    }
    loadCartFromServer() {
        if (this.cart.saleId) {
            this.saleHttpService.getTicketsInCart(this.cart.saleId)
                .subscribe(result => {
                if (result.isSuccess) {
                    result.data.results.forEach(item => {
                        if (this.cart.items.findIndex(d => getTicketId(d.ticket)) === -1) {
                            this.addToCart(new CartItem(item));
                        }
                    });
                }
            });
        }
    }
    clearCart() {
        this.cart = new Cart(Guid.newGuid());
        this.setSeasonTicketTypeId(0);
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('clearCart');
    }
    getCartProductId() {
        // return first item product id as cart should not contain multiple products
        return this.cart.items.length > 0 ? this.cart.items[0].ticket.productId : 0;
    }
    setSEPTicketsBaseCount(value) {
        this.cart.ticketsCountSEP = value;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('SEPChanged');
    }
    setSingleEventPackageProducts(products) {
        this.cart.singleEventPackageProducts = new SingleEventPackageProducts(products);
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
        this.onCartChangeEvent.next('SEPChanged');
    }
    isValidSEPSale() {
        if (this.cart.items[0].ticket.productType === ProductTypeEnum.SingleEventPackage) {
            if (this.cart.items.length % this.cart.ticketsCountSEP !== 0) {
                return false;
            }
        }
        return true;
    }
    getTargetPackagesCount() {
        return Math.floor((this.cart.items.length / this.cart.ticketsCountSEP) + 1);
    }
    updateTicketFanRegisterationInfo(soldTicketId, customerId) {
        const item = this.cart.items.find(d => getTicketId(d.ticket) === soldTicketId);
        if (item) {
            item.fanInformation.isRegisteredFan = true;
            item.fanInformation.customerId = customerId;
            this.onCartChangeEvent.next('updateFanRegistrationInfo');
        }
    }
    setTicketInformationToCartItem(ticket, fan) {
        let item = this.cart.items.find(d => getTicketId(d.ticket) === ticket.soldTicketId);
        if (!item) {
            item = this.cart.items.find(d => getTicketId(d.ticket) === -1);
        }
        if (item) {
            item.ticket.companyRegNo = ticket.companyRegNo;
            item.ticket.companyVatNo = ticket.companyRegNo;
            item.ticket.customerId = ticket.customerId;
            item.ticket.description = ticket.description;
            item.ticket.discountNote = ticket.discountNote;
            item.ticket.discountReasonId = ticket.discountReasonId;
            item.ticket.fan = fan;
            item.ticket.fanCardId = ticket.fanCardId;
            item.ticket.fanDob = ticket.fanDob;
            item.ticket.fanIdNumber = ticket.fanIdNumber;
            item.ticket.fanName = ticket.fanName;
            item.ticket.fanSurname = ticket.fanSurname;
            item.ticket.isChild = ticket.isChild;
            item.ticket.isFriendlyMatchTicket = false;
            item.ticket.isPartialPayment = ticket.isPartialPayment;
            item.ticket.koaRule = ticket.koaRule;
            item.ticket.newPrice = ticket.newPrice;
            item.ticket.order = ticket.order;
            item.ticket.originalPrice = ticket.originalPrice;
            item.ticket.packageId = 0;
            item.ticket.prereservedSaleId = ticket.prereservedSaleId;
            item.ticket.previousTicketId = ticket.previousTicketId;
            item.ticket.productId = ticket.productId;
            item.ticket.productSaleId = ticket.productSaleId;
            item.ticket.productType = ProductTypeEnum.SeasonTicket;
            item.ticket.saleId = ticket.saleId;
            item.ticket.saleStatus = SaleStatusEnum.New;
            item.ticket.season = ticket.season;
            item.ticket.seatId = ticket.seatId;
            item.ticket.seatNumber = ticket.seatNumber;
            item.ticket.seatRow = ticket.seatRow;
            item.ticket.sectionDescription = ticket.sectionDescription;
            item.ticket.sectionId = ticket.sectionId;
            item.ticket.simpleTicketId = ticket.simpleTicketId;
            item.ticket.soldTicketId = ticket.soldTicketId;
            item.ticket.specialProductId = ticket.specialProductId;
            item.ticket.standDescription = ticket.standDescription;
            item.ticket.standId = ticket.standId;
            item.ticket.status = SoldTicketStatuses.None;
            item.ticket.ticketId = ticket.soldTicketId;
            item.ticket.venueId = ticket.venueId;
            item.ticket.seasonTicketTypeId = ticket.seasonTicketTypeId;
            this.onCartChangeEvent.next('setTicketInformationToCartItem');
        }
    }
    updateTicketWithRenewInformation(item) {
        this.setSaleId(item.ticket.saleId);
        this.setTicketInformationToCartItem(item.ticket, item.fanInformation);
        this.updateTicketFanRegisterationInfo(item.ticket.soldTicketId, item.fanInformation.customerId);
        let fan = new FanAndPriceResponse();
        fan.customerId = item.fanInformation.customerId;
        fan.dob = item.fanInformation.dateOfBirth;
        fan.email = item.fanInformation.email;
        fan.id = item.fanInformation.fanCardId;
        fan.name = item.fanInformation.firstName;
        fan.isChild = item.fanInformation.isChild;
        fan.isRegisteredFan = item.fanInformation.isRegisteredFan;
        fan.surname = item.fanInformation.lastName;
        fan.cy_id = item.fanInformation.nationalId;
        fan.packagePrice = 0;
        fan.price = item.fanInformation.ticketPrice;
        this.setFanToCartItem(fan, item.ticket.soldTicketId);
    }
    isFanAChild(nationalId) {
        const request = new NationalIdRequest();
        request.NationalId = nationalId;
        return this.saleHttpService.isFanAChild(request);
    }
    locateOldTicket(request) {
        return this.saleHttpService.locateOldTicket(request);
    }
    renewSeasonTicket(renewRequest) {
        renewRequest.seasonTicketTypeId = this.getSeasonTicketTypeId();
        return this.saleHttpService.renewSeasonTicket(renewRequest);
    }
    getRouteParameters(route) {
        const result = this.getParamsRecursively(route);
        return of(result);
    }
    getParamsRecursively(route) {
        let paramsMap = Object.assign({}, route.params);
        for (let i = 0; i < route.children.length; i++) {
            paramsMap = Object.assign({}, paramsMap, this.getParamsRecursively(route.children[i]));
        }
        return paramsMap;
    }
    setSaleId(saleId) {
        this.cart.saleId = saleId;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
    }
    setCheckoutAndPaidForCart(saleId) {
        if (!this.cart || this.cart.saleId !== saleId)
            return;
        this.cart.isCheckedOutAndPaid = true;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
    }
    isCartLockedForChangesFromCurrentTab() {
        if (this.cart.isLocked && this.cart.LockerTabId == this.getCurrentTabId()) {
            this.unlockCart();
        }
        if (this.isLockExpired()) {
            this.unlockCart();
        }
        if (!this.cart.isLocked || this.cart.LockerTabId == this.getCurrentTabId()) {
            return false;
        }
        return true;
    }
    isLockExpired() {
        return this.cart.isLocked && this.cart.lockExpiryTime < Date.now();
    }
    lockCart() {
        this.cart.isLocked = true;
        this.cart.lockExpiryTime = this.getLockExiryTime();
        this.generateNewTabId();
        this.cart.LockerTabId = this.getCurrentTabId();
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
    }
    getLockExiryTime() {
        let d1 = new Date();
        return d1.setMinutes(d1.getMinutes() + this.lockExpiryInMinutes);
    }
    unlockCart() {
        this.cart.isLocked = false;
        this.cart.LockerTabId = null;
        this.cart.lockExpiryTime = null;
        localStorage.setItem(`${this.cartSubKey}-cart`, JSON.stringify(this.cart));
    }
    setSeasonTicketTypeId(seasonTicketTypeId) { this.selectedSeasonTicketTypeId = seasonTicketTypeId; }
    getSeasonTicketTypeId() { return this.selectedSeasonTicketTypeId; }
    generateNewTabId() {
        sessionStorage.currentTabId = Math.random();
    }
    getCurrentTabId() {
        if (!sessionStorage.currentTabId) {
            this.generateNewTabId();
        }
        return sessionStorage.currentTabId;
    }
}
CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.SalesHttpService), i0.ɵɵinject(i3.ToastService), i0.ɵɵinject(i4.CookieService), i0.ɵɵinject(i1.Router)); }, token: CartService, providedIn: "root" });
export function getTicketId(ticket) {
    if (ticket.soldTicketId) {
        return ticket.soldTicketId;
    }
    else {
        return ticket.ticketId;
    }
}
