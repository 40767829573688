import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RegistrationRequest } from "src/app/view-models/registration-request";

@Injectable({
	providedIn: 'root'
})
export class RegistrationService {
	private registrationInformation: RegistrationRequest;
	private currentActiveIndex: number = 0;
	private isRegistrationComplete: boolean = false;

	private indexSubject: Subject<number> = new Subject<number>();
  private indexObservable: Observable<number> = this.indexSubject.asObservable();

	constructor() { }

	public updateInfo(registrationRequest: RegistrationRequest) {
		this.registrationInformation = {...this.registrationInformation, ...registrationRequest};
	}

	public clearInformation() {
		this.registrationInformation = new RegistrationRequest();
	}

	public getRegistrationInformation() {
    return this.registrationInformation;
  }

	public setCurrentActiveIndex(activeIndex: number) {
    this.currentActiveIndex = activeIndex;
  }

	public getCurrentActiveIndex() {
    return this.currentActiveIndex;
  }

	public setIndex(index: number) {
    this.indexSubject.next(index);
  }

	public getIndexAsObservable() {
    return this.indexObservable;
  }

	public getIsRegistrationComplete() {
    return this.isRegistrationComplete;
  }

	public setIsRegistrationComplete(isCompleted: boolean) {
    this.isRegistrationComplete = isCompleted;
  }
}
