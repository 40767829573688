import { ApoelTemplate, OmonoiaTemplate, OlympiakosTemplate, AekTemplate, OtherTemplate, PaeekTemplate, DoxaTemplate, KopTemplate, SalaminaTemplate, AkritasTemplate, GuestTemplate, OthellosTemplate, AezTemplate } from './templates/omonoia-template';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class TeamTemplateFactory {
    constructor(router) {
        this.router = router;
    }
    create(teamCode) {
        switch (teamCode.toLowerCase()) {
            case 'omonoia':
                return new OmonoiaTemplate();
            case 'apoel':
                return new ApoelTemplate();
            case 'olympiakos':
                return new OlympiakosTemplate();
            case 'aek':
                return new AekTemplate();
            case 'paeek':
                return new PaeekTemplate();
            case 'doxa':
                return new DoxaTemplate();
            case 'cfa':
                return new KopTemplate();
            case 'salamina':
                return new SalaminaTemplate();
            case 'other':
                return new OtherTemplate();
            case 'akritas':
                return new AkritasTemplate();
            case 'guest':
                return new GuestTemplate();
            case 'othellos':
                return new OthellosTemplate();
            case 'aez':
                return new AezTemplate();
            default: {
                this.router.navigate(['']);
                return;
            }
        }
    }
}
TeamTemplateFactory.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeamTemplateFactory_Factory() { return new TeamTemplateFactory(i0.ɵɵinject(i1.Router)); }, token: TeamTemplateFactory, providedIn: "root" });
