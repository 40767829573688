import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

	constructor(@Inject('BASE_API_URL') private baseUrl: string,
							@Inject('CORE_API_URL') private coreUrl: string) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let newUrl;
		if (request.url.toLowerCase().startsWith('core-api')) {
			newUrl = this.coreUrl;
			if (!request.url.startsWith('/')) {
				newUrl += '/';
			}
			newUrl += request.url.toLowerCase()
				.replace('core-api/', '');
		} else {
			newUrl = this.baseUrl;
			if (!request.url.startsWith('/')) {
				newUrl += '/';
			}
			newUrl += request.url.toLowerCase();
		}
		if (request.url.indexOf('assets') > -1) {
			return next.handle(request);
		}

		const apiReq = request.clone({url: newUrl});
		return next.handle(apiReq);
	}
}
