import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse, IApiResponse} from '../view-models/api-response';
import {ProfileInfo, ProfileUpdateInfoRequest} from '../view-models/profile-info';
import {NationalIdRequest} from '../view-models/NationalIdRequest';
import {EmailRequest} from '../view-models/email-request';

@Injectable()
export class ProfileServiceHttp {
	private coreApiKey = 'core-api';

	constructor(private http: HttpClient) {

	}

	public getProfileInformation(): Observable<ApiResponse<ProfileInfo>> {
		return this.http.get<ApiResponse<ProfileInfo>>(`${this.coreApiKey}/Ticketing/IdentityManagement/customer/getProfile`);
	}

	public updateProfileInformation(request: ProfileUpdateInfoRequest): Observable<IApiResponse> {
		return this.http.put<IApiResponse>(`${this.coreApiKey}/Ticketing/IdentityManagement/customer/updateProfile`, request);
	}

	public isNationalIdTaken(nationalId: string): Observable<ApiResponse<boolean>> {
		const request = new NationalIdRequest();
		request.NationalId = nationalId;
		const result = this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/ticketing/identitymanagement/customer/isnationalidtaken`, request);
		return result;
	}

	public isEmailTaken(email: string): Observable<ApiResponse<any>> {
		var emailRequest: EmailRequest = new EmailRequest({ email: email });
		return this.http.post<ApiResponse<any>>(`${this.coreApiKey}/ticketing/identitymanagement/customer/IsEmailTaken`, emailRequest);
	}
}
