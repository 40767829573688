export class RefreshTokenRequest {

	public refreshToken: string;

	constructor(data?: RefreshTokenRequest) {

		if (data) {
			Object.assign(this, data);
		}
	}
}


