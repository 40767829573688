import {TeamService} from '../services/team.service';

export class TeamTemplateComponent {
	public logoPath: string;
	public teamName: string;
	public teamCode: string;
	public colors: {
		primary: string;
	};

	constructor(protected templateService: TeamService) {
		this.teamName = templateService.teamTemplate.teamName;
		this.teamCode = templateService.teamTemplate.teamId;
		// TODO replace by TeamAssetsService
		this.logoPath = `assets/templates/${templateService.teamTemplate.teamId}/logos/${templateService.teamTemplate.teamId}.svg`;
	}
}
