import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'app';

	constructor(private translateService: TranslateService, private gtmService: GoogleTagManagerService, private router: Router) {
		this.router.events.forEach(item => {
			if (item instanceof NavigationEnd) {
				const gtmTag = {
					event: 'page',
					pageName: item.url
				};
				this.gtmService.pushTag(gtmTag);
			}
		});
		setTimeout(() => {
			const savedLocale = localStorage.getItem('language');
			translateService.setDefaultLang('en');
			if (savedLocale) {
				translateService.use(savedLocale);
			} else {
				translateService.setDefaultLang('en');
				translateService.use('en');
				localStorage.setItem('language', 'en');
			}
			this.translateService.onLangChange.subscribe(r => {
				localStorage.setItem('language', this.translateService.currentLang);
			});
		});
	}
}
