import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddressesServiceHttp } from 'src/app/http-services/addresses-http.service';
import { RegistrationServiceHttp } from 'src/app/http-services/registration-http.service';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';
import { AddressResponse, CountryResponse } from 'src/app/view-models/address-response';
import {uniq} from 'lodash';
import { RegistrationService } from 'src/app/modules/core/services/registration.service';
import { RegistrationRequest } from 'src/app/view-models/registration-request';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';

@Component({
  selector: 'ticketing-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['../registration-form.scss', './address-information.component.scss']
})
export class AddressInformationComponent  extends TeamTemplateComponent implements OnInit {
	public template: ITeamTemplate;
	public form: FormGroup;
	public phoneRegexpPattern = /(^[+]{0,1})([\d])*$/;
	public isManualAddressForm = false;
	public countries: CountryResponse[];
	public addresses: AddressResponse[];
	public municipalities: string[] = [];
	public postalCodes: string[] = [];
	public isCfa: boolean = false;
	public streets: string[] = [];
	public nameRegexpPattern = /^[A-Za-zΑ-Ωα-ωΆά Έέ Ήή Ίί Όό Ύύ ΏώΪϊ Ϋϋ""'\-\-]*$/;
	public currentRegistration: RegistrationRequest;
	public isCompany: boolean = false;
	public isLoading: boolean = false;
	public loadingText: string = 'Loading';
	public get cities() {
		if (this.addresses) {
			return uniq(this.addresses.map(d => d.district));
		}
		return [];
	}

  constructor(
		templateService: TeamService,
		private formBuilder: FormBuilder,
		private addressesServiceHttp: AddressesServiceHttp,
		private cd: ChangeDetectorRef,
		private registrationServiceHttp: RegistrationServiceHttp,
		private registrationService: RegistrationService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private router: Router,
		) {
			super(templateService);
			this.template = templateService.teamTemplate;
		}

		ngOnInit() {
			this.loadDataIfExist();
			this.initForm();
			this.checkIfValidRedirection();

			this.addressesServiceHttp.getCountries()
				.subscribe(r => {
					this.countries = r.data;
					this.cd.detectChanges();
				});

			this.form.controls['company'].valueChanges.subscribe(r => {
				if (!r) {
					this.form.controls['companyName'].setValue('');
					this.form.controls['companyPhone'].setValue('');
					this.form.controls['companyEmail'].setValue('');
					this.form.controls['companyName'].setValue('');
					this.form.controls['companyRegVatNo'].setValue('');
					this.form.controls['companyName'].disable();
					this.form.controls['companyPhone'].disable();
					this.form.controls['companyEmail'].disable();
					this.form.controls['companyName'].disable();
					this.form.controls['companyRegVatNo'].disable();
				} else {
					this.form.controls['companyName'].enable();
					this.form.controls['companyPhone'].enable();
					this.form.controls['companyEmail'].enable();
					this.form.controls['companyName'].enable();
					this.form.controls['companyRegVatNo'].enable();
				}
			});
			this.isCfa = this.template.teamName == 'CFA';

			this.registrationService.setIndex(2);
		}

	previousItem() {
		this.router.navigate([`${this.template.teamId}/steps/profile-info`]);
  }

	private initForm() {
		this.form = this.formBuilder.group({
			countryId: [this.currentRegistration.countryId, [Validators.required]],
			district: [this.currentRegistration.district, [Validators.required]],
			municipality: [this.currentRegistration.municipality, [Validators.required]],
			postalCode: [this.currentRegistration.postalCode, [Validators.required]],
			street: [this.currentRegistration.street, [Validators.required]],
			buildingNumber: [this.currentRegistration.buildingNumber, [Validators.required]],
			buildingName: [this.currentRegistration.buildingName],
			company: [this.currentRegistration.company || false],
			companyName: [{value: this.currentRegistration.companyName, disabled: true}, [Validators.required]],
			companyPhone: [{value: this.currentRegistration.companyPhone, disabled: true}, [Validators.required]],
			companyEmail: [{value: this.currentRegistration.companyEmail, disabled: true}, [Validators.required, Validators.email]],
			companyRegVatNo: [{value: this.currentRegistration.companyRegVatNo, disabled: true}, [Validators.required]]
		});
  }

	private loadDataIfExist() {
		if (this.registrationService.getRegistrationInformation()) {
			this.currentRegistration = this.registrationService.getRegistrationInformation();
		}
		else {
			this.currentRegistration = new RegistrationRequest();
		}
	}

	public submit() {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		this.isLoading = true;
		this.updateRegisterInformation();
		this.registerUser();
	}

	private updateRegisterInformation() {
		this.registrationService.updateInfo(this.form.value);
	}

	private registerUser() {
		var registrationInfo = this.registrationService.getRegistrationInformation();
		if(!this.validateAllRequiredInformationAreGiven(registrationInfo)) {
			this.isLoading = false;
			this.toastService.showError(new ToastMsg({ detail: 'Could not complete the registration. More information are required' }));
			return;
		}

		this.registrationServiceHttp.register(registrationInfo).subscribe(response => {
			this.isLoading = false;
			if (!response.isSuccess) {
				this.toastService.showError(new ToastMsg({ detail: response.errorMessage }));
			} else {
				const teamInfo: {
					teamName: string;
					teamId: string;
				} = this.templateService.teamTemplate;
				const message = this.translateService.instant(response.data.isAlreadyRegistered ?
					'pages.registration.messages.profileExist' :
					'pages.registration.messages.registrationSuccess');
				this.toastService.showSuccess(new ToastMsg({ detail: message }));
				this.registrationService.clearInformation();
				this.registrationService.setIsRegistrationComplete(true);
				this.router.navigate([`/${teamInfo.teamId}/success`]);
			}
		});
	}

	private validateAllRequiredInformationAreGiven(registrationInfo: RegistrationRequest) {
		if (registrationInfo.firstName &&
				registrationInfo.lastName &&
				registrationInfo.nationalId &&
				registrationInfo.email &&
				registrationInfo.mobile &&
				registrationInfo.dateOfBirth &&
				registrationInfo.sex &&
				registrationInfo.countryId &&
				registrationInfo.district &&
				registrationInfo.municipality &&
				registrationInfo.postalCode &&
				registrationInfo.street &&
				registrationInfo.buildingNumber
			) {
				return true;
			}
		return false;
	}

	private checkIfValidRedirection() {
		if(!this.registrationService.getRegistrationInformation()) { this.previousItem(); }
	}
}
