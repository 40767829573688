<div class="content-container">
	<div class="container">

		<div class="row align-items-center justify-content-center">
			<form [formGroup]="form" class="registration-form px-4 pt-4 mb-4 bg-white border border-1">

				<!-- Header -->
				<div class="register-header d-flex">
					<img [src]="logoPath" width="60">
					<div class="header-content">
						<h2 class="text-uppercase">{{'pages.registration.enter_contact_information' | translate}}</h2>
						<div class="login-account">
							{{'pages.registration.fill_data' | translate}}
						</div>
					</div>
				</div>

				<!--Contact Information -->
				<div class="form-group form-group-reverded">
					<input pInputText type="tel" [patterEntry]="phoneRegexpPattern" formControlName="mobile"
						class="form-control autosize-input" id="mobileNo" placeholder="+357 99123456">
					<label for="mobileNo">
						{{'pages.registration.mobile_number' | translate}}
						<span class="font-weight-bold">*</span>
					</label>
					<validator-info [model]="form.controls.mobile"></validator-info>
				</div>

				<div class="form-group">
					<label for="phoneNo">
						{{'pages.registration.phone_number' | translate}}
					</label>
					<input pInputText type="tel" [patterEntry]="phoneRegexpPattern" formControlName="phone"
						class="form-control autosize-input" id="phoneNo" placeholder="+357 99123456">
				</div>

				<!-- Date Of Birth -->
				<div class="form-group form-group-reverded">
					<input pInputText type="date" formControlName="dateOfBirth" class="form-control autosize-input" id="dob">
					<label for="dob">
						{{'pages.registration.dateOfBirth' | translate}}
						<span class="font-weight-bold">*</span>
					</label>
					<validator-info [model]="form.controls.dateOfBirth"></validator-info>
				</div>

				<!-- Parental Consent -->
				<div *ngIf="isParentalConsentEnabled && calculateDiff(form.controls.dateOfBirth.value) < 6574">
					<div class="form-group">
						<label class="mb-1">
							{{'pages.registration.parentalConsentForm' | translate}}
							<span class="text-danger font-weight-bold">*</span>
						</label>
						<div class="mb-2 small">
							<b>{{'pages.registration.steps' | translate}}</b>
							{{'pages.registration.parentalConsentSteps' | translate}}
						</div>
						<button pButton pRipple type="button" icon="pi pi-download" label="{{'pages.registration.downloadParentalConsentForm'|translate}}" class="p-button-primary p-button-download w-100 mb-2" (click)="loadDocuments('ParentalConsentForm.pdf')"></button>
						<p-fileUpload name="myfile" accept=".png, .jpg, .jpeg, .pdf"
						[maxFileSize]="1500000" [customUpload]="true" (uploadHandler)="onFileUpload($event)"
						(onSelect)="onFileSelect($event)"
						chooseLabel="{{'pages.registration.ChooseParentalConsentForm' | translate}}"
						uploadLabel="{{'pages.registration.UploadParentalConsentForm' | translate}}"
						cancelLabel="{{'pages.registration.CancelParentalConsentForm' | translate}}">
						</p-fileUpload>
					</div>
				</div>

				<!-- Gender -->
				<div class="form-group">
					<legend>{{'pages.registration.gender' | translate}}</legend>
					<p-radioButton name="sex" formControlName="sex" value="Female" 
					label="{{'pages.registration.gender_F' | translate}}" [(ngModel)]="selectedValue" inputId="female" class="d-inline-flex mr-4 my-2"></p-radioButton>
					<p-radioButton name="sex" formControlName="sex" value="Male" 
					label="{{'pages.registration.gender_M' | translate}}" [(ngModel)]="selectedValue" inputId="Male" class="d-inline-flex my-2"></p-radioButton>
					<p-radioButton name="sex" formControlName="sex" value="I'd rather not say" 
					label="{{'pages.registration.gender_hide' | translate}}" [(ngModel)]="selectedValue" inputId="not-specified" class="d-flex mt-2"></p-radioButton>
					<validator-info [model]="form.controls.sex"></validator-info>
				</div>

				<!--Actions -->
				<div class="form-group form-actions">
					<button pButton pRipple type="button" label="{{'buttons.next'|translate}}" class="p-button-primary w-100 mb-2" (click)="nextItem()" 
					[ngClass]="{ 'disable-button ': !isFormUploaded }"
					></button>
					<button pButton pRipple type="button" label="{{'buttons.back'|translate}}" class="p-button-primary w-100" (click)="previousItem()"></button>
				</div>
				<div class="form-footer">
					<i class="fa fa-lock"></i>{{'pages.registration.Secure_registration' | translate}}
				</div>
			</form>
		</div>
	</div>
</div>
