import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../view-models/api-response';
import {AddressResponse, CountryResponse} from '../view-models/address-response';
import {ListResponse} from '../view-models/list-response';
import {AddressRequest} from '../view-models/address-request';

@Injectable()
export class AddressesServiceHttp {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient) {
	}

	getAddresses(request: AddressRequest): Observable<ApiResponse<ListResponse<AddressResponse>>> {
		return this.http.post<ApiResponse<ListResponse<AddressResponse>>>(`${this.coreApiKey}/Ticketing/Addresses/GetAddresses`, request);
	}

	getCountries(): Observable<ApiResponse<CountryResponse[]>> {
		return this.http.get<ApiResponse<CountryResponse[]>>(`${this.coreApiKey}/ticketing/addresses/GetCountries`);
	}
}

