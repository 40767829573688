/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/primeng-lts/steps/primeng-lts-steps.ngfactory";
import * as i4 from "primeng-lts/steps";
import * as i5 from "@angular/router";
import * as i6 from "./registration-page.component";
import * as i7 from "../../../core/services/registration.service";
var styles_RegistrationPageComponent = [i0.styles];
var RenderType_RegistrationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationPageComponent, data: {} });
export { RenderType_RegistrationPageComponent as RenderType_RegistrationPageComponent };
export function View_RegistrationPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "registration-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "stepbar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "stepbar-progress"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "width": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } if (("activeIndexChange" === en)) {
        var pd_1 = (_co.setStepBarWidth() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Steps_0, i3.RenderType_Steps)), i1.ɵdid(9, 245760, null, 0, i4.Steps, [i5.Router, i5.ActivatedRoute, i1.ChangeDetectorRef], { activeIndex: [0, "activeIndex"], model: [1, "model"] }, { activeIndexChange: "activeIndexChange" }), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "stepbar"; var currVal_1 = _co.notFirstStep(); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 7, 0, _co.width); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.activeIndex; var currVal_4 = _co.items; _ck(_v, 9, 0, currVal_3, currVal_4); _ck(_v, 11, 0); }, null); }
export function View_RegistrationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ticketing-registration-page", [], null, null, null, View_RegistrationPageComponent_0, RenderType_RegistrationPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.RegistrationPageComponent, [i7.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationPageComponentNgFactory = i1.ɵccf("ticketing-registration-page", i6.RegistrationPageComponent, View_RegistrationPageComponent_Host_0, {}, {}, []);
export { RegistrationPageComponentNgFactory as RegistrationPageComponentNgFactory };
