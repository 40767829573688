import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule} from '@angular/router';
import {SpecifyTeamComponent} from './modules/core/specify-team/specify-team.component';
import {AuthenticationGuard} from './modules/core/guards/authentication-guard';
import {AccountValidationGuard} from './modules/core/guards/account-validation-guard';

const routes = [
	{
		path: '',
		component: SpecifyTeamComponent
	},
	{
		path: ':team',
		loadChildren: () => import('./modules/auth-module/auth.module').then(m => m.AuthModule),
	},
	{
		path: ':team/tickets',
		loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
	},
	{
		path: ':team/main',
		canLoad: [AuthenticationGuard],
		loadChildren: () => import('./modules/main-module/main.module').then(m => m.MainModule),
	}
];
const routesConfiguration: ExtraOptions = {
	anchorScrolling: 'enabled',
	scrollPositionRestoration: 'enabled',
	enableTracing: false,
	paramsInheritanceStrategy: 'always'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routesConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
