import {CartItem} from './cartItem';
import { SingleEventPackageProducts, SingleEventPackageProduct } from 'src/app/view-models/products-response';
import { EventModel } from 'src/app/view-models/event';
import { SeasonModel } from '../../models/season-model';

export class Cart {
	public id: string;
	public saleId: number;
	public items: CartItem[];
	public event: EventModel;
	public season: SeasonModel;
	public orderId: string;
	public ticketsCountSEP: number;
	public ticketIdsWithMembershipFee: number[] = [];
	public singleEventPackageProducts: SingleEventPackageProducts;
	public isCheckedOutAndPaid: boolean;
	public isLocked: boolean;
	public LockerTabId: string;
	public lockExpiryTime: number;
	
	constructor(_id: string) {
		this.id = _id;
		this.items = [];
		this.ticketIdsWithMembershipFee = [];
		this.ticketsCountSEP = 0;
		this.singleEventPackageProducts = new SingleEventPackageProducts(null);
		this.isCheckedOutAndPaid = false;
		this.isLocked = false;
		this.LockerTabId = null;
		this.lockExpiryTime = null;
	}
}
