import { PortalIdEnum } from '../../models/portal-id-enum';
import { PortalServiceHttp } from 'src/app/http-services/portal-http-service';
import { TeamService } from '../../../core/services/team.service';
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../../http-services/portal-http-service";
import * as i2 from "../../../core/services/team.service";
import * as i3 from "../../../core/services/authentication.service";
export class PortalService extends TeamTemplateComponent {
    constructor(portalServiceHttp, templateService, authenticationService) {
        super(templateService);
        this.portalServiceHttp = portalServiceHttp;
        this.authenticationService = authenticationService;
        this.portalSettings = {};
        this.portalCode = templateService.teamTemplate.portalId;
        this.portalSettingsText = localStorage.getItem(`${this.portalCode}-portal-settings`);
        this.template = templateService.teamTemplate;
        if (this.isUserLoggedIn()) {
            this.initPortalSettings(this.portalCode);
        }
    }
    isUserLoggedIn() {
        return this.authenticationService.isLoggedInPortal(this.template.teamId) ? true : false;
    }
    initPortalSettings(portalCode) {
        this.portalServiceHttp.GetPortalSettings().subscribe(portalSettingsResult => {
            if (portalSettingsResult.isSuccess) {
                const ShowSeasonTicketsPage = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'HideSeasonTicketsPage';
                });
                const showCancellationPolicyResult = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'HideConflictResolutionPolicy';
                });
                const showConflictResPolicyResult = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'HideConflictResolutionPolicy';
                });
                const showFooterGuide = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'ShowFooterGuide';
                });
                const showRenewSeasonTicketResult = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'ShowRenewSeasonTicket';
                });
                const showMarketingTooltip = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'ShowMarketingTooltip';
                });
                const paymentGateway = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'CUSTOMER_PAYMENT_GATEWAY';
                });
                const ShowMembershipPopup = portalSettingsResult.data.results.find(ps => {
                    return ps.code === 'ShowMembershipPopup';
                });
                this.portalSettings.canShowSeasonTicketsPage = ShowSeasonTicketsPage ? ShowSeasonTicketsPage.value == 'true' : true;
                this.portalSettings.showCancellationPolicy = showCancellationPolicyResult ? showCancellationPolicyResult.value == 'false' : false;
                this.portalSettings.showConflictResPolicy = showConflictResPolicyResult ? showConflictResPolicyResult.value == 'false' : false;
                this.portalSettings.showRenewSeasonTicket = showRenewSeasonTicketResult ? showRenewSeasonTicketResult.value == 'true' : true;
                this.portalSettings.showMarketingTooltip = showMarketingTooltip ? showMarketingTooltip.value == 'true' : false;
                this.portalSettings.showMembershipPopup = ShowMembershipPopup ? ShowMembershipPopup.value == 'true' : false;
                this.portalSettings.paymentGateway = paymentGateway.value;
                localStorage.setItem(`${portalCode}-portal-settings`, JSON.stringify(this.portalSettings));
                this.templateService.setAcquiringSystem(this.portalSettings.paymentGateway);
            }
        });
    }
    resetPortalSettings() {
        this.portalSettings = undefined;
    }
    getPortalSettings() {
        if (this.isUserLoggedIn()) {
            this.initPortalSettings(this.portalCode);
        }
        return this.portalSettings;
    }
    haveMembershipFee() {
        return this.portalSettings.showMembershipPopup;
    }
    canShowLoyaltyPage(portalId) {
        switch (portalId) {
            case PortalIdEnum.OtherTeam:
                return false;
            case PortalIdEnum.Omonoia:
                return false;
            case PortalIdEnum.Apoel:
                return true;
            case PortalIdEnum.Olympiakos:
                return false;
            case PortalIdEnum.Aek:
                return false;
            case PortalIdEnum.Paphos:
                return false;
            case PortalIdEnum.Paeek:
                return false;
            case PortalIdEnum.Doxa:
                return false;
            case PortalIdEnum.Kop:
                return false;
            default:
                return false;
        }
    }
    canShowInstalmentsOption(portalId) {
        switch (portalId) {
            case PortalIdEnum.OtherTeam:
                return false;
            case PortalIdEnum.Omonoia:
                return false;
            default:
                return false;
        }
    }
    canShowLegalNoticeOnFooter(portalId) {
        switch (portalId) {
            case PortalIdEnum.OtherTeam:
                return false;
            case PortalIdEnum.Apoel:
                return true;
            default:
                return false;
        }
    }
    canShowPricingRulesPage(portalId) {
        switch (portalId) {
            case PortalIdEnum.OtherTeam:
                return false;
            case PortalIdEnum.Omonoia:
                return false;
            case PortalIdEnum.Apoel:
                return false;
            case PortalIdEnum.Olympiakos:
                return false;
            case PortalIdEnum.Aek:
                return false;
            case PortalIdEnum.Paphos:
                return false;
            case PortalIdEnum.Paeek:
                return false;
            case PortalIdEnum.Doxa:
                return false;
            case PortalIdEnum.Kop:
                return false;
            default:
                return false;
        }
    }
}
PortalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortalService_Factory() { return new PortalService(i0.ɵɵinject(i1.PortalServiceHttp), i0.ɵɵinject(i2.TeamService), i0.ɵɵinject(i3.AuthenticationService)); }, token: PortalService, providedIn: "root" });
