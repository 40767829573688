import {Injectable, OnInit} from '@angular/core';
import {SignalRSettings} from '../../../infastructure/model/signalr-settings';
import {SignalrHub} from '../../../infastructure/signalr-hub';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class SignalRHubService {

	private hub: SignalrHub;

	constructor(
		private authenticationService: AuthenticationService
	) {

	}

	public startSignalRConnection(settings: SignalRSettings) {
		this.authenticationService.getAuthData().subscribe(res => {
			if (res.isLoggedIn) {
				this.hub = new SignalrHub(settings);
				this.hub.SetUpConnection().subscribe(isConnected => {
					if (isConnected) {
						this.hub.setUpNotificationHandler();
						this.hub.start();
					}
				});
			}
		});
	}
}
