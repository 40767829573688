import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function untilToday(message?: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		const date = new Date(value);
		const today = new Date();
		date.setHours(0, 0, 0, 0);
		today.setHours(0, 0, 0, 0);
		if (date > today) {
			return {untilToday: message ? message : 'Incorrect data'};
		}
	};
}

export function maxDate(minDateValue: Date, message?: string) {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		const date = new Date(value);
		if (date > minDateValue) {
			return {
				minDate: message ? message : 'Некорректная дата',
			};
		}
	};
}

export function minDate(minDateValue: Date, message?: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		const date = new Date(value);
		if (date < minDateValue) {
			return {
				minDate: message ? message : 'Некорректная дата',
			};
		}
	};
}
