import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormHelpersService {

  constructor() { }
  /**
 * Makes Prime dropdown have spin icon on load
 * @param boolen
 * @returns Icon Class for PrimeNG
 */
  public dropdownSpinnerIcon(isLoading: boolean){
    return isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down';
  }
}
