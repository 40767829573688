import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';
import { RegistrationService } from 'src/app/modules/core/services/registration.service';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';

@Component({
  selector: 'success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent extends TeamTemplateComponent implements OnInit {
	public template: ITeamTemplate;

  constructor(
		templateService: TeamService,
		private registrationService: RegistrationService,
		private router: Router
		) {
			super(templateService);
			this.template = templateService.teamTemplate;
		}

  ngOnInit() {
		this.validateNavigationToSuccessPage();
  }

	private validateNavigationToSuccessPage() {
		if (!this.registrationService.getIsRegistrationComplete()) {
			this.router.navigate([`${this.template.teamId}/login`]);
		}
		this.registrationService.setIsRegistrationComplete(false);
	}
}
