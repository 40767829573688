export class CreateTicketRequest {
	seatId: number;
	saleId: number;
	seatRow: number;
	seatNumber: number;
	venueId: number;
	standId: number;
	sectionId: number;
	productType: number;
	seasonId: number;
	eventId: number;
	productId: number;
}

export class AddFanToTicketAndGetPriceRequest {
	productType: number;
	seasonId: number;
	soldTicketId: number;
	fanCardId: string;
	nationalId: string;
	isAvailableBoxOffice: boolean;
	eventId: number;
	productId: number;
	seasonTicketTypeId?: number;
}

export class FanAndPriceResponse {
	error: string;
	message: string;
	id: string;
	name: string;
	surname: string;
	barcode: string;
	expire: string;
	dob: Date;
	price: number;
	cy_id: string;
	valid: boolean;
	isRegisteredFan: boolean;
	customerId: number;
	email: string;
	isAllowedPartialPayment: boolean;
	packagePrice: number;
	isChild: boolean;
	simpleTicketId: number;
}

export class CreateFriendlyMatchTicketRequest {
	seats: SeatInfoModel[];
	saleId: number;
	venueId: number;
	seasonId: number;
	eventId: number;
	productTypeId: number;
	productId: number;
}

export class SeatInfoModel {
	seatId: number;
	seatRow: number;
	seatNumber: number;
	standId: number;
	sectionId: number;
	venueId: number;
}
