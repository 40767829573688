import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../view-models/api-response';
import {PortalSettingsResponse} from '../view-models/portal-management/portal-settings-response';
import {SponsorResponse} from '../view-models/sponsor-response';
import {ListResponse} from '../view-models/list-response';
import {SocialMediaResponse} from '../view-models/social-media-response';

@Injectable()
export class PortalServiceHttp {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient) {
	}

	public GetPortalSettings(): Observable<ApiResponse<ListResponse<PortalSettingsResponse>>> {
		return this.http.get<ApiResponse<ListResponse<PortalSettingsResponse>>>(`${this.coreApiKey}/Ticketing/Portals/GetPortalRequiredSettings`);
	}

	public GetPublicPortalSettings(): Observable<ApiResponse<ListResponse<PortalSettingsResponse>>> {
		return this.http.get<ApiResponse<ListResponse<PortalSettingsResponse>>>(`${this.coreApiKey}/Ticketing/Portals/GetCustomerPublicPortalSettings`);
	}

	public GetSponsors(): Observable<ApiResponse<SponsorResponse[]>> {
		return this.http.get<ApiResponse<SponsorResponse[]>>(`${this.coreApiKey}/ticketing/teams/GetSponsors`);
	}

	public GetSocialMedia(): Observable<ApiResponse<SocialMediaResponse[]>> {
		return this.http.get<ApiResponse<SocialMediaResponse[]>>(`${this.coreApiKey}/ticketing/teams/GetTeamsSocialMedia`);
	}

}
