export class ApiResponse<T> implements IApiResponse {

	public data: T;

	public isSuccess: boolean;

	public errorMessage: string;

	public statusCode: number;

	constructor(data?: ApiResponse<T>) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export interface IApiResponse {
	errorMessage: string;
	isSuccess: boolean;
}

