import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS,} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {LoadingBarModule, LoadingBarService} from '@ngx-loading-bar/core';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {CoreModule, createTranslateLoader} from './modules/core/core.module';
import {SpecifyTeamComponent} from './modules/core/specify-team/specify-team.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SalesHttpService} from './http-services/ticket-sales-http.service';
import {CookieService} from 'ngx-cookie-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModule, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {RegistrationServiceHttp} from './http-services/registration-http.service';
import {EncryptionInterceptor} from './interceptors/encryption.interceptor';
import {UserIdleService} from './modules/core/services/idle-service';
import {PortalServiceHttp} from './http-services/portal-http-service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import { ToastModule } from 'primeng-lts/toast';
import { SharedModule } from './modules/shared-module/shared.module';
import { InactivityDialogComponent } from './modules/shared-module/components/inactivity-dialog/inactivity-dialog.component';
import { MessageService } from 'primeng-lts/api';

@NgModule({
	declarations: [
		AppComponent,
		SpecifyTeamComponent
	],
	imports: [
		BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		HttpClientModule,
		RadioButtonModule,
		FormsModule,
		AppRoutingModule,
		CoreModule,
		LoadingBarModule,
		BrowserAnimationsModule,
		LoadingBarRouterModule,
		NgbModule,
		ToastModule,
		SharedModule
	],
	providers: [SalesHttpService, LoadingBarService, CookieService, NgxSpinnerService, NgbTooltipConfig, RegistrationServiceHttp,
		{provide: 'googleTagManagerId', useValue: 'GTM-W597DPC'},
		{provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true},
		UserIdleService, PortalServiceHttp,
		CurrencyPipe, DatePipe, MessageService
	],
	bootstrap: [AppComponent],
	entryComponents: [InactivityDialogComponent]
})
export class AppModule {
}
