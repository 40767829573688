import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'validator-info',
	templateUrl: './validator-info.component.html',
	styleUrls: ['./validator-info.component.scss']
})
export class ValidatorInfoComponent implements OnInit {
	@Input() model: FormControl;
	@Input() patternErrorKey: string;
	@Input() requredErrorKey: string;

	constructor(
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
	}

}
