import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
export class AuthenticationGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        const url = `${state.url}`;
        const teamId = route.parent.paramMap.get('team');
        const redirectUrl = teamId ? `/${teamId}` : '/';
        return this.loadAuthenticationChecker(url, redirectUrl, teamId);
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
    canLoad(route, segments) {
        const url = `/${route.path}`;
        const teamCodePath = segments[0].path;
        const redirectUrl = url.indexOf(':team') > -1 ? `${teamCodePath}` : `/`;
        return this.loadAuthenticationChecker(url, redirectUrl, teamCodePath);
    }
    loadAuthenticationChecker(url, redirectUrl, teamCodePath) {
        if (this.authService.isLoggedInPortal(teamCodePath)) {
            return true;
        }
        this.router.navigate([redirectUrl]);
        return false;
    }
}
AuthenticationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AuthenticationGuard, providedIn: "root" });
