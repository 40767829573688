<spinner-loading [isLoading]="isLoading" [loadingText]="loadingText"></spinner-loading>

<div class="login-page">
	<div class="container">
		<div class="row justify-content-center align-items-center">
			<form [formGroup]="form" (ngSubmit)="submit()" class="login-form p-4 bg-white border border-1">

				<div class="login-header d-flex mb-4">
					<img [src]="logoPath" width="60">
					<div class="header-content">
						<h2 class="pt-2 text-uppercase">
							{{'pages.login.title'|translate: {team: teamName} }}
						</h2>
						<div class="create-account">
							{{'pages.login.create_account'|translate}}
							<a (click)="register()"	class="create-account-link link-cyan">
								{{'buttons.create_account'|translate}}
							</a>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="email" class="mb-1">{{'pages.login.fields.email'|translate}}</label>
					<input pInputText type="email" id="email" formControlName="email" 
					placeholder="{{'pages.login.fields.placeholder_email' | translate }}">

					<validator-info [model]="form.controls.email"></validator-info>
				</div>
				<div class="form-group">
					<label for="password" class="mb-1">{{'pages.login.fields.password'|translate}}</label>
					<p-password  type="password" id="password" formControlName="password" [toggleMask]="true" [feedback]="false" 
					placeholder="{{'pages.login.fields.placeholder_password' | translate }}"></p-password>
					<!-- <input type="password" class="form-control" id="password" formControlName="password" placeholder="Enter your password"> -->
					<validator-info [model]="form.controls.password"></validator-info>
					<div class="forgot-password text-right mb-2">
						<a (click)="forgotPassword()" class="forgot-password-link link-cyan">{{'buttons.forgot_password'|translate}}</a>
					</div>
				</div>

				<button pButton pRipple type="submit" label="{{'buttons.login'|translate}}" class="p-button-primary w-100"></button>
			</form>
		</div>
	</div>
</div>

