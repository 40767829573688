import { MainFooterComponent } from './components/shared-layout-components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/shared-layout-components/main-header/main-header.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {UnauthLayoutComponent} from './components/unauth-layout/unauth-layout.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CoreModule} from '../core/core.module';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import {DropdownModule} from 'primeng-lts/dropdown';
import {RippleModule} from 'primeng-lts/ripple';
import { FormsModule } from '@angular/forms';
import { FilterService, PrimeNGConfig , MenuItem} from 'primeng-lts/api';
import { SidebarModule } from 'primeng-lts/sidebar';
import { ButtonModule } from 'primeng-lts/button';
import { BadgeModule } from 'primeng-lts/badge';
import { TabMenuModule } from 'primeng-lts/tabmenu';
import { MenuModule } from 'primeng-lts/menu';
import { Stadium360LogoComponent } from './components/shared-layout-components/stadium360-logo/stadium360-logo.component';
import {ConfirmDialogModule} from 'primeng-lts/confirmdialog';
import { ConfirmationService } from 'primeng-lts/api';

@NgModule({
	declarations: [
		MainLayoutComponent,
		UnauthLayoutComponent,
		LanguageSwitcherComponent,
		MainHeaderComponent,
		MainFooterComponent,
		Stadium360LogoComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgxSpinnerModule,
		RouterModule,
		TranslateModule,
		CoreModule,
		NgbCollapseModule,
		DropdownModule,
		RippleModule,
		SidebarModule,
		ButtonModule,
		BadgeModule,
		TabMenuModule,
		MenuModule,
		ConfirmDialogModule
	],
	exports: [
		Stadium360LogoComponent
	],
	providers: [FilterService, PrimeNGConfig, ConfirmationService]
})
export class LayoutModule {
}
