import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ITeamTemplate} from '../../../core/team-configuration/ITeamTemplate';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {LayoutShared} from '../shared/layout.shared';
import {TeamService} from '../../../core/services/team.service';
import {AuthPageComponent} from '../../../auth-module/pages/auth-page/auth-page.component';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'ticketing-unauth-layout',
	templateUrl: './unauth-layout.component.html',
	styleUrls: ['./unauth-layout.component.scss']
})
export class UnauthLayoutComponent extends LayoutShared implements OnInit {

	public team: ITeamTemplate;

	constructor(
		private route: ActivatedRoute,
		protected translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		templateService: TeamService,
		private pageTitle: Title
	) {
		super(translateService, templateService);
		this.team = <ITeamTemplate>route.snapshot.data['teamTemplate'];
		this.router.events.subscribe((event: ActivationEnd) => {
			if (event.snapshot && event.snapshot.data.title) {
				const title = event.snapshot.data.title;
				pageTitle.setTitle(title);
			}
		});
	}

	ngOnInit() {

	}

	get isLoginPage(): boolean {
		return this.route.children[0].component == AuthPageComponent;
	}

	isLanguageActive(culture: string) {
		return this.translateService.currentLang === culture;
	}

	register() {
		this.router.navigate(['steps'], {relativeTo: this.activatedRoute.parent});
	}

	public async login() {
		await this.router.navigate([`/${this.team.teamId}/main`]);
	}
}
