import {TranslateService} from '@ngx-translate/core';
import {TeamTemplateComponent} from '../../../core/generic/team-template.component';
import {TeamService} from '../../../core/services/team.service';

export abstract class LayoutShared extends TeamTemplateComponent {
	protected constructor(
		protected translateService: TranslateService,
		templateService: TeamService) {
		super(templateService);
	}

	public changeLocality(locality: string) {
		this.translateService.use(locality);
	}
}
