import {finalize, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

	count = 0;

	constructor(private spinner: NgxSpinnerService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.method !== 'GET' && !req.url.includes('identity/customer/refresh')) {
			this.spinner.show().then(r => {
				this.count++;
			});
		}
		return next.handle(req)
			.pipe(tap(), finalize(() => {
					this.count--;
					if (this.count <= 0) {
						this.count = 0;
						this.spinner.hide();
					}
				})
			);
	}
}
