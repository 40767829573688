import {Injectable} from '@angular/core';
import {TeamTemplateFactory} from '../team-configuration/team-template-factory.service';
import {ITeamTemplate} from '../team-configuration/ITeamTemplate';

@Injectable({providedIn: 'root'})
export class TeamService {
	public teamTemplate: ITeamTemplate;
	public initialized = false;

	constructor(private teamTemplateFactory: TeamTemplateFactory) {
	}

	public getTeamInfoByTemplateName(template: string): ITeamTemplate {
		return this.teamTemplate = this.teamTemplateFactory.create(template);
	}

	public setAcquiringSystem(acSystem: string): void {
		this.teamTemplate.acquiringSystems = [acSystem];
	}
}


