import {IFan, ITicket} from '../sales/i-ticket';
import {FanInformation} from '../../models/season-ticket-info';

export class CartItem {
	public ticket: ITicket;
	public fanInformation: IFan;
	public expiry: number;

	constructor(ticket: ITicket) {
		this.ticket = ticket;
	}
}
