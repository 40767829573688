import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TeamTemplateComponent} from '../../../core/generic/team-template.component';
import {TeamService} from '../../../core/services/team.service';
import {passwordComplexity} from '../../../core/validators/password-validator';
import {passwordMatcher} from '../../../core/validators/confirm-password-validator';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthenticationServiceHttp} from '../../../../http-services/authentication-http.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {FormControl} from '@angular/forms';
import {SetPasswordRequest} from 'src/app/view-models/set-password-request';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';

@Component({
	selector: 'app-set-password-page',
	templateUrl: './set-password-page.component.html',
	styleUrls: ['./set-password-page.component.scss']
})
export class SetPasswordPageComponent extends TeamTemplateComponent {
	private linkCode: string;
	public pForm: FormGroup;
	public isInvalidLink = false;

	constructor(
		templateService: TeamService,
		fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private authenticationHttpService: AuthenticationServiceHttp,
		private toastService: ToastService,
		private translateService: TranslateService,
		private router: Router,
		private location: Location
	) {
		super(templateService);

		this.pForm = fb.group({
			newPassword: ['', [Validators.required, passwordComplexity()]],
			passwordConfirm: ['', [Validators.required, passwordMatcher.bind(this)]]
		});
	}

	ngOnInit() {
		this.activatedRoute.queryParamMap.subscribe(r => {
			const token = r.get('SID');
			if (token) {
				this.authenticationHttpService.validateLink(token).subscribe(tokenResult => {
					if (tokenResult.isSuccess === true && tokenResult.data == true) {
						this.linkCode = token;

					} else {
						this.isInvalidLink = true;
					}
				});
			} else {
				this.isInvalidLink = true;
			}
		});
	}

	public pFormSubmit() {
		if (!this.pForm.valid) {
			this.pForm.markAllAsTouched();
		} else {
			const formValues = this.pForm.getRawValue();
			const setPasswordRequest = new SetPasswordRequest();
			setPasswordRequest.password = formValues.newPassword;
			setPasswordRequest.linkCode = this.linkCode;

			this.authenticationHttpService.setPassword(setPasswordRequest).subscribe((async r => {
				if (!r.isSuccess) {
					this.toastService.showError(new ToastMsg({ detail: r.errorMessage }));
				} else {
					const text = this.translateService.instant('pages.set_password.success_set_message');
					this.toastService.showSuccess(new ToastMsg({ detail: text }));

					await this.router.navigate([`/${this.teamCode}`]).then(() => {
						window.location.reload();
					});
				}
			}));
		}
	}
}
