<header>
	<!--Upper Menu-->
	<nav class="navbar navbar-dark text-white justify-content-between p-4">

		<div class="d-flex align-items-center justify-content-between w-100">
			<div class="header-left">
				<button pButton type="text" (click)="display = true" icon="pi pi-bars" label="{{'Menu' | translate}}" class="btn-menu d-xl-none p-button-text text-uppercase p-0 text-white" title="{{'Menu' | translate}}"></button>
				<a class="navbar-brand d-none d-xl-block">
					<stadium360-logo class="d-inline-block align-middle brand-log"></stadium360-logo>
				</a>
				</div>
				<div class="w-100 text-center">
					<img [src]="logoPath" width="60">
					<!-- <h1 class="d-none d-md-inline text-uppercase teamName pl-2 mt-2">{{'title' |translate:{teamName: team.teamName} }}</h1> -->
				</div>
				<div class="header-right d-flex align-items-center justify-content-end">
					<ticketing-language-switcher class="d-none d-xl-block mx-1"></ticketing-language-switcher>
					<a *ngIf="!isLoggedIn" (click)="register()"	class="btn btn-link-white text-uppercase mx-1 d-none d-md-block">
						{{'pages.registration.registration_modal.registerBtnTxt'|translate}}
					</a>
					<button	*ngIf="!isLoggedIn" class="btn btn-white-outline d-flex align-items-center text-uppercase mx-1" 
					(click)="login()" type="button">{{'pages.registration.registration_modal.login'|translate}} <i class="fa fa-user pl-1"></i></button>

					<div *ngIf="isLoggedIn" class="dropdown-wrapper position-relative ml-1">
						<button pButton pRipple icon="pi pi-user" iconPos="right" label="My Account" class="btn p-button-text p-0 text-white btn-account" (click)="menu.toggle($event)"></button>
						<p-menu #menu [popup]="true" [model]="items"></p-menu>
					</div>
				</div>
			</div>

	</nav>

	<div *ngIf="isLoggedIn && isUserEditOptionsDisplayed" class="user-edit-options" #menu>
		<div class="edit-options box arrow-top">
			<a [routerLink]="'profile'">{{'layout.menu.editProfile' | translate}}</a>
			<a [routerLink]="'change-password'">{{'layout.menu.changePassword' | translate}}</a>
			<a (click)="clearCart()">{{'layout.menu.clearCart' | translate}}</a>
			<hr/>
			<a (click)="logout()">{{'layout.menu.logout' | translate}}</a>
		</div>
	</div>

	<!-- Mobile Menu -->
	<p-sidebar [(visible)]="display" class="main-menu-sidebar d-xl-none">
		<ticketing-language-switcher></ticketing-language-switcher>
		<ul *ngIf="isLoggedIn" class="navbar-nav text-uppercase pl-2">
			<li class="nav-item" [ngClass]="{'active': selectedPage == 'home'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('home')" >{{'layout.home' | translate}} <span class="sr-only">(current)</span></a>
			</li>
			<li class="nav-item" *ngIf="isCompany" routerLinkActive="active">
				<a class="nav-link" [routerLink]="'transfer-company-tickets'">{{'layout.transferCompanyTickets' | translate}}</a>
			</li>
			<li class="nav-item" *ngIf="canShowPricingRulesPage" [ngClass]="{'active': selectedPage == 'prices'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('prices')">{{'layout.prices' | translate}}</a>
			</li>
			<li class="nav-item" *ngIf="isShowLoyaltyPage" [ngClass]="{'active': selectedPage == 'loyalty'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('loyalty')">{{'layout.loyalty' | translate}}</a>
			</li>
			<li class="nav-item" [ngClass]="{'active': selectedPage == 'purchases'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('purchases')">{{'layout.menu.myPurchases' | translate}}</a>
			</li>
			<li class="nav-item" [ngClass]="{'active': selectedPage == 'reservations'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('reservations')">{{'layout.reservations' | translate}}</a>
			</li>
			<li class="nav-item" [ngClass]="{'active': selectedPage == 'invoice-receipts'}">
				<a class="nav-link" (click)="emptyCartBeforeRedirect('invoice-receipts')">{{'layout.invoiceReceipts' | translate}}</a>
			</li>
		</ul>
		<div *ngIf="isLoggedIn" class="bottom-menu-container text-uppercase px-2 py-4">
			<a (click)="logout()">{{'layout.menu.logout' | translate}}</a>
		</div>
	</p-sidebar>

	<!--Lower Menu-->
	<nav #navnav class="team-navbar navbar navbar-expand-lg justify-content-center main-menu "  [ngClass]="isLoggedIn ?'d-none d-xl-block': 'd-none'">
		<div *ngIf="isLoggedIn" class="collapse navbar-collapse justify-content-center" [ngbCollapse]="navbarCollapsed" id="navbarNav">
			<ul class="navbar-nav text-uppercase">
				<li class="nav-item" [ngClass]="{'active': selectedPage == 'home'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('home')" >{{'layout.home' | translate}} <span
						class="sr-only">(current)</span></a>
				</li>
				<li class="nav-item" *ngIf="isCompany" routerLinkActive="active">
					<a class="nav-link pointer" [routerLink]="'transfer-company-tickets'">{{'layout.transferCompanyTickets' | translate}}</a>
				</li>
				<li class="nav-item" *ngIf="canShowPricingRulesPage" [ngClass]="{'active': selectedPage == 'prices'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('prices')">{{'layout.prices' | translate}}</a>
				</li>
				<li class="nav-item" *ngIf="isShowLoyaltyPage" [ngClass]="{'active': selectedPage == 'loyalty'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('loyalty')">{{'layout.loyalty' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': selectedPage == 'purchases'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('purchases')">{{'layout.menu.myPurchases' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': selectedPage == 'reservations'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('reservations')">{{'layout.reservations' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': selectedPage == 'invoice-receipts'}">
					<a class="nav-link pointer" (click)="emptyCartBeforeRedirect('invoice-receipts')">{{'layout.invoiceReceipts' | translate}}</a>
				</li>
			</ul>
		</div>
	</nav>
</header>

<p-confirmDialog class="h-auto" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined" acceptButtonStyleClass="p-button-primary"></p-confirmDialog>
