import { Injectable } from '@angular/core';
import { MessageService } from 'primeng-lts/api';
import { ToastSeverity } from '../models/ngprime/severity.static';
import { ToastMsg } from '../models/ngprime/toastMsg';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  life: number = 6000; // 6 Seconds

  constructor(private messageService: MessageService) { }

  showSuccess(input: ToastMsg) {
    input.summary = input.summary ? input.summary : 'Success';
    this.messageService.add(new ToastMsg({ severity: ToastSeverity.Success, summary: input.summary, detail: input.detail, life: this.life }));
  }

  showError(input: ToastMsg) {
    input.summary = input.summary ? input.summary : 'Server Error';
    this.messageService.add(new ToastMsg({ severity: ToastSeverity.Error, summary: input.summary, detail: input.detail, life: this.life }));
  }

  showInfo(input: ToastMsg) {
    input.summary = input.summary ? input.summary : 'Information';
    this.messageService.add(new ToastMsg({ severity: ToastSeverity.Info, summary: input.summary, detail: input.detail, life: this.life }));
  }

  showWarning(input: ToastMsg) {
    input.summary = input.summary ? input.summary : 'Warning';
    this.messageService.add(new ToastMsg({ severity: ToastSeverity.Warning, summary: input.summary, detail: input.detail, life: this.life }));
  }
}
