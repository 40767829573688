import {Component, OnDestroy} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'src/app/modules/core/services/idle-service';

@Component({
	selector: 'inactivity-dialog',
	templateUrl: './inactivity-dialog.component.html',
	styleUrls: ['./inactivity-dialog.component.scss']
})
export class InactivityDialogComponent implements OnDestroy {
	timer: string;
	private timerSub: Subscription;

	constructor(
		public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
		private userIdleService: UserIdleService,
	) {
		this.timerSub = this.userIdleService.secondLevelUserIdleChecker.subscribe((status: string) => {
			this.initiateSecondTimer(status);
		});
	}

	closeDialog(isStayLoggedIn: boolean) {
		this.timerSub.unsubscribe();
		this.dialogRef.close(isStayLoggedIn);
  }

	initiateSecondTimer = (status: string) => {
		switch (status) {
			default:
				status == "SECOND_TIMER_STARTED" ? this.timer = "2:00"
																				 : this.timer = status;
				break;
		}
	};

	ngOnDestroy() {
		this.timerSub.unsubscribe();
	}
}
