import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../view-models/api-response';
import {LoginRequest} from '../view-models/login-request';
import {InvalidField, LoginResponse} from '../view-models/login-response';
import {ResetPassword} from '../view-models/reset-password';
import {CheckTokenRequest} from '../view-models/check-token-request';
import {SendForgotPasswordTokenRequest} from '../view-models/send-forgot-password-token-request';
import {ChangePasswordViaTokenRequest} from '../view-models/change-password-via-token-request';
import {SetPasswordRequest} from '../view-models/set-password-request';
import {RefreshTokenRequest} from '../view-models/refreshToken-request';

@Injectable()
export class AuthenticationServiceHttp {
	private coreApiKey = 'core-api';

	constructor(protected http: HttpClient) {
	}

	public login(request: LoginRequest): Observable<ApiResponse<LoginResponse>> {
		let headers = new HttpHeaders();
		headers = headers.append('Obscure', 'true');

		return this.http.post<ApiResponse<LoginResponse>>(`${this.coreApiKey}/Ticketing/Identity/customer/login`, request, {
			headers: headers
		});
	}

	public refreshToken(request: RefreshTokenRequest): Observable<ApiResponse<LoginResponse>> {
		return this.http.post<ApiResponse<LoginResponse>>(`${this.coreApiKey}/Ticketing/Identity/customer/refresh`, request);
	}

	public resetPassword(requestViewModel: ResetPassword): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/Ticketing/Identity/customer/changePassword`, requestViewModel);
	}

	public sendForgotPasswordLink(request: SendForgotPasswordTokenRequest) {
		return this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/Ticketing/Identity/customer/forgotPassword`, request);
	}

	public checkToken(request: CheckTokenRequest): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/Ticketing/Identity/customer/checkForgotPasswordToken`, request);
	}

	public validateLink(linkCode: string): Observable<ApiResponse<boolean>> {
		return this.http.get<ApiResponse<boolean>>(`${this.coreApiKey}/Ticketing/Identity/customer/validateLink?code=${linkCode}`);
	}

	public changePasswordViaToken(request: ChangePasswordViaTokenRequest): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(`${this.coreApiKey}/Ticketing/Identity/customer/changePasswordViaToken`, request);
	}

	public setPassword(request: SetPasswordRequest): Observable<ApiResponse<any>> {
		return this.http.post<ApiResponse<any>>(`${this.coreApiKey}/Ticketing/Identity/customer/setPassword`, request);
	}

	public checkCustomersDataValidity(): Observable<ApiResponse<InvalidField[]>> {
		return this.http.get<ApiResponse<InvalidField[]>>(`${this.coreApiKey}/ticketing/identity/customer/validateProfile`);
	}
}


