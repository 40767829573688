/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inactivity-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "primeng-lts/button";
import * as i4 from "./inactivity-dialog.component";
import * as i5 from "primeng-lts/dynamicdialog";
import * as i6 from "../../../core/services/idle-service";
var styles_InactivityDialogComponent = [i0.styles];
var RenderType_InactivityDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InactivityDialogComponent, data: {} });
export { RenderType_InactivityDialogComponent as RenderType_InactivityDialogComponent };
export function View_InactivityDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "time-in-mins"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "p-button-primary mr-2"], ["pButton", ""], ["pRipple", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "p-button-danger"], ["pButton", ""], ["pRipple", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("buttons.continue")); _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("buttons.logout")); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("shared_components.inactivity_dialog.body")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.timer; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("shared_components.inactivity_dialog.body2")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("shared_components.inactivity_dialog.body3")); _ck(_v, 9, 0, currVal_3); }); }
export function View_InactivityDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "inactivity-dialog", [], null, null, null, View_InactivityDialogComponent_0, RenderType_InactivityDialogComponent)), i1.ɵdid(1, 180224, null, 0, i4.InactivityDialogComponent, [i5.DynamicDialogRef, i5.DynamicDialogConfig, i6.UserIdleService], null, null)], null, null); }
var InactivityDialogComponentNgFactory = i1.ɵccf("inactivity-dialog", i4.InactivityDialogComponent, View_InactivityDialogComponent_Host_0, {}, {}, []);
export { InactivityDialogComponentNgFactory as InactivityDialogComponentNgFactory };
