import { CurrencyPipe, DatePipe } from '@angular/common';
import { DataFormatsEnum } from './data-formats-enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class HelperService {
    constructor(datePipe, currrencyPipe) {
        this.datePipe = datePipe;
        this.currrencyPipe = currrencyPipe;
    }
    downloadFile(filename, content) {
        var element = document.createElement('a');
        element.setAttribute('href', `data:application/octet-stream;base64,${content}`);
        element.setAttribute('download', filename);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    getDateString(inputDate) {
        try {
            if (!inputDate)
                return '';
            const datetime = new Date(inputDate);
            const year = datetime.getFullYear();
            let month = datetime.getMonth() + 1;
            month = String(month).padStart(2, '0');
            let date = datetime.getDate();
            date = String(date).padStart(2, '0');
            return `${year}-${month}-${date}`;
        }
        catch (error) {
            return '';
        }
    }
    getDataFormating(value, dataFormat) {
        if (dataFormat === DataFormatsEnum.CURRENCY)
            return this.currrencyPipe.transform(value, DataFormatsEnum.CURRENCY);
        if (dataFormat === DataFormatsEnum.DATE)
            return this.datePipe.transform(value, DataFormatsEnum.DATE);
        if (typeof (dataFormat) === 'function') {
            return dataFormat(value);
        }
        return value;
    }
    getPositionFromNumber(value) {
        if (!value || Number.isNaN(value))
            return '';
        if (value === 1)
            return '1st';
        if (value === 2)
            return '2nd';
        if (value === 3)
            return '3rd';
        return `${value}th`;
    }
    isNullOrEmpty(string) { return string == null || string == '' ? true : false; }
}
HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.DatePipe), i0.ɵɵinject(i1.CurrencyPipe)); }, token: HelperService, providedIn: "root" });
