export class SingleEventPackageProductViewModel {
	singleEventPackageId: number;
	name: string;
	layoutId: number;
	onlineReleaseDateTime: Date;
	cutOffTime: Date;
	portalId: number;
	ticketsCount: number;
}

export class MultipleEventPackageProductViewModel {
	singleEventPackageId: number;
	name: string;
	layoutId: number;
	onlineReleaseDateTime: Date;
	cutOffTime: Date;
	portalId: number;
	ticketsCount: number;
}

export class SingleEventPackageProducts {
	public SingleEventPackageId: number;
	public IsValid: boolean;
	public Products: SingleEventPackageProduct[];
	constructor(products: SingleEventPackageProduct[]) {		
		this.IsValid = true;
		this.Products = new Array<SingleEventPackageProduct>();
		if (products && products.length > 0) {
			this.SingleEventPackageId = products[0].singleEventPackageId;
			products.forEach(p => {
				let item: SingleEventPackageProduct = new SingleEventPackageProduct();
				item.currentCount = 0;
				Object.assign(item, p);
				this.Products.push(item);
			})
		}
	}
}

export class SingleEventPackageProduct {
	singleEventPackageId: number;
	productName: string;
	simpleTicketId: number;
	koaRule: boolean;
	productCount: number;
	currentCount: number;
	soldTicketId: number;
}

export class SimpleTicketProduct {
	simpleTicketId: number;
	name: string;
}
