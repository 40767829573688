import { Message } from "primeng-lts/api";
import { ToastSeverity } from "./severity.static";

/**
 * Used by PrimeNG toast
 */
 export class ToastMsg implements Message {
  severity?: string;
  summary?: string;
  detail?: string;
  id?: any;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
  icon?: string;
  contentStyleClass?: string;
  styleClass?: string;

  constructor(data?: ToastMsg) {
    if (data) {
      Object.assign(this, data);
    }
  }

}
