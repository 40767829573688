import { Component, OnDestroy, OnInit} from '@angular/core';
import { MenuItem } from 'primeng-lts/api';
import { Subscription } from 'rxjs';
import { RegistrationService } from 'src/app/modules/core/services/registration.service';

@Component({
	selector: 'ticketing-registration-page',
	templateUrl: './registration-page.component.html',
	styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit, OnDestroy {
	public items: MenuItem[];
	public activeIndex: number = 0;
  public width: string = '0%';
	currentIndex: number;
	indexSubscription: Subscription;

	constructor(
		private registrationService: RegistrationService
	) { }

	ngOnInit() {
		this.loadSteps();
		this.getIndexObservable();
	}

	loadSteps() {
		this.items = [{
				label: 'Registration',
				routerLink: 'registration'
			},
			{
				label: 'Profile Info',
				routerLink: 'profile-info'
			},
			{
				label: 'Address Information',
				routerLink: 'address-information'
			}
		];
	}

	getTotalSteps() {
		return this.items.length;
	}

	getIndexObservable(): any {
		this.indexSubscription = this.registrationService.getIndexAsObservable().subscribe(result => {
			this.currentIndex = result;
			this.setStepBarWidth();
		});
  	}

	setStepBarWidth() {
		this.width = ((this.currentIndex/this.getTotalSteps())*100).toString() + '%';
	}

	ngOnDestroy() {
		this.indexSubscription.unsubscribe();
	}

	notFirstStep() {
		if(this.currentIndex < 1)
			return 'hide';
		return '';
	}
}
