/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/primeng-lts/dropdown/primeng-lts-dropdown.ngfactory";
import * as i4 from "primeng-lts/dropdown";
import * as i5 from "primeng-lts/api";
import * as i6 from "@angular/forms";
import * as i7 from "./language-switcher.component";
import * as i8 from "../../../core/services/team.service";
import * as i9 from "@ngx-translate/core";
var styles_LanguageSwitcherComponent = [i0.styles];
var RenderType_LanguageSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageSwitcherComponent, data: {} });
export { RenderType_LanguageSwitcherComponent as RenderType_LanguageSwitcherComponent };
function View_LanguageSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "lang-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["height", "22"], ["width", "22"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("assets/img/flag-" + _co.selectedLanguage.code) + ".svg"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.selectedLanguage.name; _ck(_v, 3, 0, currVal_1); }); }
function View_LanguageSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSwitcherComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLanguage; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LanguageSwitcherComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "lang-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["height", "22"], ["width", "22"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (("assets/img/flag-" + _v.context.$implicit.code) + ".svg"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_LanguageSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "language-switcher pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "p-dropdown", [["optionLabel", "name"]], [[2, "p-inputwrapper-filled", null], [2, "p-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedLanguage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Dropdown_0, i3.RenderType_Dropdown)), i1.ɵdid(2, 13877248, null, 1, i4.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone, i5.FilterService, i5.PrimeNGConfig], { optionLabel: [0, "optionLabel"], options: [1, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Dropdown]), i1.ɵdid(5, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(7, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_LanguageSwitcherComponent_1)), i1.ɵdid(9, 16384, [[1, 4]], 0, i5.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_LanguageSwitcherComponent_3)), i1.ɵdid(11, 16384, [[1, 4]], 0, i5.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "name"; var currVal_10 = _co.languages; _ck(_v, 2, 0, currVal_9, currVal_10); var currVal_11 = _co.selectedLanguage; _ck(_v, 5, 0, currVal_11); var currVal_12 = "selectedItem"; _ck(_v, 9, 0, currVal_12); var currVal_13 = "item"; _ck(_v, 11, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).filled; var currVal_1 = (i1.ɵnov(_v, 2).focused || i1.ɵnov(_v, 2).overlayVisible); var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_LanguageSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ticketing-language-switcher", [], null, null, null, View_LanguageSwitcherComponent_0, RenderType_LanguageSwitcherComponent)), i1.ɵdid(1, 114688, null, 0, i7.LanguageSwitcherComponent, [i5.PrimeNGConfig, i8.TeamService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguageSwitcherComponentNgFactory = i1.ɵccf("ticketing-language-switcher", i7.LanguageSwitcherComponent, View_LanguageSwitcherComponent_Host_0, {}, {}, []);
export { LanguageSwitcherComponentNgFactory as LanguageSwitcherComponentNgFactory };
