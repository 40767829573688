import { MessageService } from 'primeng-lts/api';
import { ToastSeverity } from '../models/ngprime/severity.static';
import { ToastMsg } from '../models/ngprime/toastMsg';
import * as i0 from "@angular/core";
import * as i1 from "primeng-lts/api";
export class ToastService {
    constructor(messageService) {
        this.messageService = messageService;
        this.life = 6000; // 6 Seconds
    }
    showSuccess(input) {
        input.summary = input.summary ? input.summary : 'Success';
        this.messageService.add(new ToastMsg({ severity: ToastSeverity.Success, summary: input.summary, detail: input.detail, life: this.life }));
    }
    showError(input) {
        input.summary = input.summary ? input.summary : 'Server Error';
        this.messageService.add(new ToastMsg({ severity: ToastSeverity.Error, summary: input.summary, detail: input.detail, life: this.life }));
    }
    showInfo(input) {
        input.summary = input.summary ? input.summary : 'Information';
        this.messageService.add(new ToastMsg({ severity: ToastSeverity.Info, summary: input.summary, detail: input.detail, life: this.life }));
    }
    showWarning(input) {
        input.summary = input.summary ? input.summary : 'Warning';
        this.messageService.add(new ToastMsg({ severity: ToastSeverity.Warning, summary: input.summary, detail: input.detail, life: this.life }));
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.MessageService)); }, token: ToastService, providedIn: "root" });
