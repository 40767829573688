<svg width="546" height="84" viewBox="0 0 546 84" xmlns="http://www.w3.org/2000/svg">
	<path class="team-primary-color" d="M531.3 31.2L515.4 36.6C517.6 39.6 518.8 44.5 518.8 51.1C518.8 62.6 515.2 68.9 508.6 68.9C502 68.9 498.4 62.5 498.4 51C498.4 47.5 498.7 44.5 499.4 42L481.4 48.1V48.2C481.4 48.2 481.4 48.1 481.3 48.1L466 53.4C468 55 469.3 57.4 469.3 60.5C469.3 65.7 465.7 69.3 460.6 69.3C455.6 69.3 451.9 65.7 451.9 60.5C451.9 59.6 452 58.8 452.2 58.1L438.4 62.8L436 63.6L397.6 76.6C401.7 81 407.7 83.6 414.9 83.6C425.8 83.6 434.3 77.8 437.3 69C440.6 77.9 449.2 83.6 460.7 83.6C472.7 83.6 481.7 77.2 484.5 67.4C488.8 77.7 497.4 83.8 508.8 83.8C525.4 83.8 536.1 71 536.1 51.1C536 43.2 534.3 36.5 531.3 31.2Z" fill="#F6AD0F"/>
	<path d="M0 50.4H18.6C18.6 55.1 22.2 57.3 26.3 57.3C30.1 57.3 33.7 55.3 33.7 51.7C33.7 47.6 28.6 46.4 22.4 44.9C12.9 42.6 0.9 39.7 0.9 24.8C0.9 11.9 10.4 4.5 25.8 4.5C41.5 4.5 50.5 12.6 50.5 25.9H32.3C32.3 21.8 29.2 19.8 25.5 19.8C22.3 19.8 19.2 21.2 19.2 24.2C19.2 27.9 24.1 29.1 30.3 30.7C39.9 33.2 52.5 36.4 52.5 51.3C52.5 65.6 41.5 73 26.3 73C10.3 73.2 0 64.7 0 50.4Z"/>
	<path d="M91.8 56.3V71.5H79.8C68.6 71.5 61.9 64.7 61.9 53.5V34.5H52.7V30.4L75.3 6.29999H78.9V21.1H91.6V34.5H79.3V50.6C79.3 54.2 81.5 56.4 85.1 56.4H91.8V56.3Z"/>
	<path d="M150.8 21.1V71.6H137.4L135.9 68C131.8 71.3 126.5 73.3 120.7 73.3C105.3 73.3 94.2 62 94.2 46.4C94.2 30.8 105.2 19.6 120.7 19.6C126.6 19.6 132 21.6 136.1 25L137.9 21.1H150.8ZM134.3 46.3C134.3 39.7 129.5 34.8 123 34.8C116.4 34.8 111.7 39.7 111.7 46.3C111.7 52.9 116.5 57.8 123 57.8C129.5 57.8 134.3 52.9 134.3 46.3Z"/>
	<path d="M212.7 2.90002V71.6H199.8L198.2 67.9C194.1 71.4 188.9 73.4 183 73.4C167.5 73.4 156.5 62.1 156.5 46.5C156.5 30.8 167.5 19.6 183 19.6C187.7 19.6 191.9 20.9 195.5 23.1V2.90002H212.7ZM196.6 46.3C196.6 39.7 191.8 34.8 185.3 34.8C178.8 34.8 174 39.8 174 46.3C174 52.9 178.8 57.8 185.3 57.8C191.8 57.9 196.6 52.9 196.6 46.3Z"/>
	<path d="M221.3 21.1H238.7V71.6H221.3V21.1ZM221.4 0H238.5V14.8H221.4V0Z"/>
	<path d="M246.6 49.9V21.1H264V49.1C264 54.4 266.9 57.6 271.5 57.6C276.1 57.6 279 54.4 279 49.1V21.1H296.4V49.9C296.4 64 286.5 73.2 271.6 73.2C256.6 73.2 246.6 64 246.6 49.9Z"/>
	<path d="M381.7 39.9V71.6H364.3V42.1C364.3 36.8 362.4 34.3 358.7 34.3C353.9 34.3 351.5 37.6 351.5 43.9V71.6H334.6V42.1C334.6 37 332.7 34.3 329 34.3C324.3 34.3 321.7 37.7 321.7 43.9V71.6H304.3V21.1H316.5L319 26.5C322.6 22.3 327.9 19.6 333.8 19.6C340 19.6 345.1 22.4 348.3 27C351.9 22.4 357.5 19.6 363.8 19.6C374.5 19.6 381.7 28 381.7 39.9Z"/>
	<path d="M391.9 61.9C392 65.3 392.7 68.4 394 71.1L409 65.9C408.4 64.8 408 63.4 407.9 61.9H391.9Z"/>
	<path d="M460 40.4C464.1 33.5 468.3 26.7 472.4 19.8H454.4L442.9 39.1C439.6 44.6 437.6 49.1 436.6 53.3C434.3 48 429.7 44.2 423.6 42.6L436.1 22.6V19.7H393.3V33.9H414.2L404.2 50.7V54.2H414.2C419 54.2 422.1 57 422.1 61.4L436 56.6L437.5 56.1L475.4 43C472.2 41.2 468.4 40.2 464.1 40.2C462.7 40.1 461.3 40.2 460 40.4Z"/>
	<path d="M508.7 18.3C495.3 18.3 485.7 26.7 482.6 40.4L526.7 25.1C522.1 20.7 515.9 18.3 508.7 18.3Z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M535.6 26.4C541.178 26.4 545.7 21.8781 545.7 16.3C545.7 10.7219 541.178 6.20001 535.6 6.20001C530.022 6.20001 525.5 10.7219 525.5 16.3C525.5 21.8781 530.022 26.4 535.6 26.4ZM535.75 19.7C537.545 19.7 539 18.2449 539 16.45C539 14.6551 537.545 13.2 535.75 13.2C533.955 13.2 532.5 14.6551 532.5 16.45C532.5 18.2449 533.955 19.7 535.75 19.7Z"/>
	</svg>

