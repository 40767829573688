var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormBuilder, Validators } from '@angular/forms';
import { TeamTemplateComponent } from '../../../core/generic/team-template.component';
import { TeamService } from '../../../core/services/team.service';
import { passwordComplexity } from '../../../core/validators/password-validator';
import { passwordMatcher } from '../../../core/validators/confirm-password-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationServiceHttp } from '../../../../http-services/authentication-http.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { SetPasswordRequest } from 'src/app/view-models/set-password-request';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { ToastMsg } from 'src/app/modules/core/models/ngprime/toastMsg';
export class SetPasswordPageComponent extends TeamTemplateComponent {
    constructor(templateService, fb, activatedRoute, authenticationHttpService, toastService, translateService, router, location) {
        super(templateService);
        this.activatedRoute = activatedRoute;
        this.authenticationHttpService = authenticationHttpService;
        this.toastService = toastService;
        this.translateService = translateService;
        this.router = router;
        this.location = location;
        this.isInvalidLink = false;
        this.pForm = fb.group({
            newPassword: ['', [Validators.required, passwordComplexity()]],
            passwordConfirm: ['', [Validators.required, passwordMatcher.bind(this)]]
        });
    }
    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe(r => {
            const token = r.get('SID');
            if (token) {
                this.authenticationHttpService.validateLink(token).subscribe(tokenResult => {
                    if (tokenResult.isSuccess === true && tokenResult.data == true) {
                        this.linkCode = token;
                    }
                    else {
                        this.isInvalidLink = true;
                    }
                });
            }
            else {
                this.isInvalidLink = true;
            }
        });
    }
    pFormSubmit() {
        if (!this.pForm.valid) {
            this.pForm.markAllAsTouched();
        }
        else {
            const formValues = this.pForm.getRawValue();
            const setPasswordRequest = new SetPasswordRequest();
            setPasswordRequest.password = formValues.newPassword;
            setPasswordRequest.linkCode = this.linkCode;
            this.authenticationHttpService.setPassword(setPasswordRequest).subscribe(((r) => __awaiter(this, void 0, void 0, function* () {
                if (!r.isSuccess) {
                    this.toastService.showError(new ToastMsg({ detail: r.errorMessage }));
                }
                else {
                    const text = this.translateService.instant('pages.set_password.success_set_message');
                    this.toastService.showSuccess(new ToastMsg({ detail: text }));
                    yield this.router.navigate([`/${this.teamCode}`]).then(() => {
                        window.location.reload();
                    });
                }
            })));
        }
    }
}
