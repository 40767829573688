<div class="content-container registration-page">
	<!--Register Layout-->
	<div class="container">
		<div class="row align-items-center justify-content-center">
			<form [formGroup]="form" class="registration-form px-4 pt-4 mb-4 bg-white border border-1">
				<div class="register-header d-flex mb-4">
					<img [src]="logoPath" width="60">
					<div class="header-content">
						<h2 class=" pt-2 text-uppercase" font-size="10px">{{'pages.registration.register' | translate}} </h2>
						<div class="login-account">
							{{'pages.registration.already_have_an_account' | translate}}
							<a (click)="login()" class="login-account-link link-cyan">
								{{'pages.login.title' | translate}}
							</a>
						</div>
					</div>
				</div>
				<!--Personal Information section-->
				<div class="form-group form-group-reverded">
					<input pInputText type="text" class="p-inputtext" uppercase [patterEntry]="nameRegexpPattern" formControlName="firstName" id="name"
					 placeholder="{{'pages.registration.placeholder_Name' | translate}}">
					<label for="name">{{'pages.registration.first_name' | translate}} <span class="font-weight-bold">*</span></label>
					<validator-info [model]="form.controls.firstName"></validator-info>
				</div>
				<div class="form-group form-group-reverded">
					<input pInputText type="text" class="p-inputtext" uppercase formControlName="lastName" [patterEntry]="nameRegexpPattern" id="lastName" 
					 placeholder="{{'pages.registration.placeholder_lastName' | translate}}">
					<label for="lastName">
						{{'pages.registration.last_name' | translate}}
						<span class="font-weight-bold">*</span>
					</label>
					<validator-info [model]="form.controls.lastName"></validator-info>
				</div>
				<div class="form-group form-group-reverded">
					<input pInputText type="text"	class="p-inputtext" formControlName="nationalId" [patterEntry]="'^[\-0-9A-Za-z]*$'" id="nationalID"
					 placeholder="{{'pages.registration.placeholder_national_id' | translate}}">
					<label for="nationalID">
						{{'pages.registration.national_id' | translate}}
						<span class="font-weight-bold">*</span>
					</label>
					<validator-info [model]="form.controls.nationalId"></validator-info>
				</div>
				<div class="form-group form-group-reverded">
					<input pInputText type="email" formControlName="email" class="p-inputtext" id="email" placeholder="xxxxxxxx@xxx.com">
					<label for="email">
						{{'pages.registration.email' | translate}}
						<span class="font-weight-bold">*</span>
					</label>
					<validator-info [model]="form.controls.email"></validator-info>
				</div>
				<div class="p-field-checkbox sign-up-checkbox">
					<p-checkbox name="isMarketingAccepted" value="marketing" inputId="marketing"></p-checkbox>
					<label for="marketing">{{'pages.registration.marketing' | translate}}</label>
				</div>
				<!--Actions -->
				<div class="form-group form-actions">
					<button pButton pRipple type="button" label="{{'pages.registration.register' | translate}}" class="p-button-primary w-100" (click)="nextItem()"></button>
				</div>
				<!--Conditions Message-->
				<div class="form-terms-conditions">
						{{'pages.registration.Tr_Conditions_pt1' | translate}}
						{{helperService.isNullOrEmpty(this.teamName) ? 'team' : this.teamName}}
						{{'pages.registration.Tr_Conditions_pt2' | translate}}
						<a class="underline" (click)="loadAttachments('Terms_and_Conditions.pdf')">{{'pages.registration.terms_conditions' | translate}}</a>,
						<a class="underline" (click)="loadAttachments('GDPR.pdf')">{{'pages.registration.gdpr_acceptance' | translate}}</a>,
						{{'pages.registration.Tr_Conditions_2' | translate}}
				</div>
				<div class="form-footer">
					<i class="fa fa-lock"></i>{{'pages.registration.Secure_registration' | translate}}
				</div>
			</form>
		</div>
	</div>
</div>
