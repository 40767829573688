var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
export class SignalrHub {
    constructor(settings, authenticationService) {
        this.authenticationService = authenticationService;
        this._keyword = '/SeatMapHub';
        this._hostUrl = settings.url ? settings.url + this._keyword : environment.coreUrl + this._keyword;
        this._data = settings.data ? settings.data : {};
        this._onUpdateSeat = settings.onUpdateSeat ? settings.onUpdateSeat : null;
    }
    SetUpConnection() {
        const obs = new Observable((ob) => {
            this._connection = new HubConnectionBuilder()
                .withUrl(this._hostUrl, { accessTokenFactory: () => this._token })
                .withAutomaticReconnect()
                .configureLogging(environment.production ? LogLevel.Error : LogLevel.Debug)
                .build();
            if (this._connection) {
                ob.next(true);
            }
            else {
                ob.next(false);
            }
        });
        return obs;
    }
    setUpNotificationHandler() {
        if (this._onUpdateSeat) {
            this._connection.on('UpdateSeats', this._onUpdateSeat);
        }
    }
    start() {
        this._connection.start()
            .then((result) => __awaiter(this, void 0, void 0, function* () {
            if (this._data) {
                yield this.addToGroup();
            }
        }), error => {
            setTimeout(() => this.start(), 3000);
        });
    }
    addToGroup() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this._connection.invoke('AddToGroup', this._data);
            }
            catch (err) {
            }
        });
    }
}
