/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./specify-team.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./specify-team.component";
var styles_SpecifyTeamComponent = [i0.styles];
var RenderType_SpecifyTeamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpecifyTeamComponent, data: {} });
export { RenderType_SpecifyTeamComponent as RenderType_SpecifyTeamComponent };
export function View_SpecifyTeamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please specify the team for web site."]))], null, null); }
export function View_SpecifyTeamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-specify-team", [], null, null, null, View_SpecifyTeamComponent_0, RenderType_SpecifyTeamComponent)), i1.ɵdid(1, 114688, null, 0, i2.SpecifyTeamComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpecifyTeamComponentNgFactory = i1.ɵccf("app-specify-team", i2.SpecifyTeamComponent, View_SpecifyTeamComponent_Host_0, {}, {}, []);
export { SpecifyTeamComponentNgFactory as SpecifyTeamComponentNgFactory };
