/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/primeng-lts/toast/primeng-lts-toast.ngfactory";
import * as i3 from "primeng-lts/toast";
import * as i4 from "primeng-lts/api";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "angular-google-tag-manager";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p-toast", [["position", "top-right"], ["styleClass", "customToast"]], null, null, null, i2.View_Toast_0, i2.RenderType_Toast)), i1.ɵdid(1, 1294336, null, 1, i3.Toast, [i4.MessageService, i1.ChangeDetectorRef], { styleClass: [0, "styleClass"], position: [1, "position"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "customToast"; var currVal_1 = "top-right"; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i7.TranslateService, i8.GoogleTagManagerService, i5.Router], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
