import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ITeamTemplate} from './ITeamTemplate';
import {Observable} from 'rxjs';
import {TeamService} from '../services/team.service';

@Injectable({providedIn: 'root'})
export class TeamTemplateResolver implements Resolve<ITeamTemplate> {
	constructor(private teamService: TeamService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITeamTemplate> | Promise<ITeamTemplate> | ITeamTemplate {
		const routeParameter = route.paramMap.get('team');
		const result = this.teamService.getTeamInfoByTemplateName(routeParameter);
		return result;
	}
}
