import { CheckboxModule } from 'primeng-lts/checkbox';
import { PasswordModule } from 'primeng-lts/password';
import { InputTextModule } from 'primeng-lts/inputtext';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxPrintModule } from 'ngx-print';
import { AuthRoutingModule } from '../auth-module/auth.routing.module';
import { AuthPageComponent } from '../auth-module/pages/auth-page/auth-page.component';
import { ForgotPasswordPageComponent } from '../auth-module/pages/forgot-password-page/forgot-password-page.component';
import { RegistrationPageComponent } from '../auth-module/pages/registration-page/registration-page.component';
import { SetPasswordPageComponent } from '../auth-module/pages/set-password-page/set-password-page.component';
import { CoreModule } from '../core/core.module';
import { SanitizeHtmlPipe } from '../core/generic/sanitize';
import { LayoutModule } from '../layout-module/layout.module';
import { AddressComponent } from './component/address/address.component';
import { MarketingInfoModalComponent } from './component/marketing-info-modal/marketing-info-modal.component';
import { CpsSimpleTicketsComponent } from './components/cps-simple-tickets/cps-simple-tickets.component';
import {FileUploadModule} from 'primeng-lts/fileupload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNGConfig } from 'primeng-lts/api';
import {ProgressSpinnerModule} from 'primeng-lts/progressspinner';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { RegistrationComponent } from '../auth-module/pages/registration-page/registration/registration.component';
import { AddressInformationComponent } from '../auth-module/pages/registration-page/address-information/address-information.component';
import { ProfileInfoComponent } from '../auth-module/pages/registration-page/profile-info/profile-info.component';
import { SuccessComponent } from '../auth-module/pages/registration-page/success/success.component';
import { StepsModule } from 'primeng-lts/steps';
import { DropdownModule } from 'primeng-lts/dropdown';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import {CardModule} from 'primeng-lts/card';
import { InactivityDialogComponent } from './components/inactivity-dialog/inactivity-dialog.component';
import { DynamicDialogModule } from 'primeng-lts/dynamicdialog';
import { CpsTransferredTicketComponent } from './components/cps-transferred-ticket/cps-transferred-ticket.component';
import { CspNormalPageComponent } from './components/client-side-printing/csp-normal-page/csp-normal-page.component';
import { DialogModule } from 'primeng-lts/dialog';
import { TransferTicketPrintComponent } from '../main-module/pages/transfer-ticket/transfer-ticket-print/transfer-ticket-print.component';

@NgModule({
	declarations: [
		AddressComponent,
		AuthPageComponent,
		RegistrationPageComponent,
		ForgotPasswordPageComponent,
		SetPasswordPageComponent,
		MarketingInfoModalComponent,
		CpsSimpleTicketsComponent,
		SanitizeHtmlPipe,
		SpinnerLoadingComponent,
		RegistrationComponent,
		AddressInformationComponent,
		ProfileInfoComponent,
		SuccessComponent,
		InactivityDialogComponent,
		CpsTransferredTicketComponent,
		CspNormalPageComponent,
		TransferTicketPrintComponent
	],
	imports: [
		CommonModule,
		CoreModule,
		AuthRoutingModule,
		LayoutModule,
		FormsModule,
		ReactiveFormsModule,
		InputTextModule,
		PasswordModule,
		TranslateModule,
		StepsModule,
		DropdownModule,
		InputSwitchModule,
		RadioButtonModule,
		CheckboxModule,
		QRCodeModule,
		NgxBarcode6Module,
		NgxPrintModule,
		FileUploadModule,
		ProgressSpinnerModule,
		CardModule,
		DynamicDialogModule,
		DialogModule
		],
	exports: [
		AddressComponent,
		AuthPageComponent,
		RegistrationPageComponent,
		ForgotPasswordPageComponent,
		SetPasswordPageComponent,
		MarketingInfoModalComponent,
		CpsSimpleTicketsComponent,
		SpinnerLoadingComponent,
		RegistrationComponent,
		AddressInformationComponent,
		ProfileInfoComponent,
		SuccessComponent,
		InputTextModule,
		RadioButtonModule,
		InactivityDialogComponent,
		CpsTransferredTicketComponent,
		CspNormalPageComponent,
		TransferTicketPrintComponent
	],
	providers: [
		PrimeNGConfig,
		QRCodeModule,
		NgxBarcode6Module,
		NgxPrintModule,
	]
})
export class SharedModule {
}
