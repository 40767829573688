import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DataFormatsEnum } from './data-formats-enum';

@Injectable({ providedIn: 'root' })
export class HelperService  {

	constructor(
		private datePipe: DatePipe,
		private currrencyPipe: CurrencyPipe,
	) { }

	downloadFile(filename: string, content: string) {
		var element = document.createElement('a');
		element.setAttribute(
			'href',
			`data:application/octet-stream;base64,${content}`
		);
		element.setAttribute('download', filename);
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	getDateString(inputDate: Date) {
		try {
			if (!inputDate) return '';

			const datetime = new Date(inputDate);
			const year = datetime.getFullYear();
			let month: any = datetime.getMonth() + 1;
			month = String(month).padStart(2, '0');
			let date: any = datetime.getDate();
			date = String(date).padStart(2, '0');
			return `${year}-${month}-${date}`;
		} catch (error) {
			return '';
		}
	}


	getDataFormating(value: any, dataFormat: any) {
		if (dataFormat === DataFormatsEnum.CURRENCY)
			return this.currrencyPipe.transform(value, DataFormatsEnum.CURRENCY);
		if (dataFormat === DataFormatsEnum.DATE)
			return this.datePipe.transform(value, DataFormatsEnum.DATE);
			if(typeof(dataFormat) === 'function'){
				return dataFormat(value);
			}
		return value;
	}

	getPositionFromNumber(value: number): string {
    if (!value || Number.isNaN(value)) return '';
    if (value === 1) return '1st';
    if (value === 2) return '2nd';
    if (value === 3) return '3rd';
    return `${value}th`;
  }

  isNullOrEmpty(string: string) { return string == null || string == '' ? true : false; }
}
