var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { LayoutShared } from '../shared/layout.shared';
import { TeamService } from '../../../core/services/team.service';
import { AuthPageComponent } from '../../../auth-module/pages/auth-page/auth-page.component';
import { Title } from '@angular/platform-browser';
export class UnauthLayoutComponent extends LayoutShared {
    constructor(route, translateService, authenticationService, router, activatedRoute, templateService, pageTitle) {
        super(translateService, templateService);
        this.route = route;
        this.translateService = translateService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.pageTitle = pageTitle;
        this.team = route.snapshot.data['teamTemplate'];
        this.router.events.subscribe((event) => {
            if (event.snapshot && event.snapshot.data.title) {
                const title = event.snapshot.data.title;
                pageTitle.setTitle(title);
            }
        });
    }
    ngOnInit() {
    }
    get isLoginPage() {
        return this.route.children[0].component == AuthPageComponent;
    }
    isLanguageActive(culture) {
        return this.translateService.currentLang === culture;
    }
    register() {
        this.router.navigate(['steps'], { relativeTo: this.activatedRoute.parent });
    }
    login() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.router.navigate([`/${this.team.teamId}/main`]);
        });
    }
}
