import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GetJsonDataService {
    constructor(http) {
        this.http = http;
    }
    getTeamThemeColors(teamCode) {
        return this.http.get(`/assets/styles/team-theme-colors/${teamCode}.json`); //${teamCode}
    }
}
GetJsonDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetJsonDataService_Factory() { return new GetJsonDataService(i0.ɵɵinject(i1.HttpClient)); }, token: GetJsonDataService, providedIn: "root" });
