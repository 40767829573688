import { RegistrationService } from './../../../../core/services/registration.service';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamTemplateComponent } from 'src/app/modules/core/generic/team-template.component';
import { TeamService } from 'src/app/modules/core/services/team.service';
import { ITeamTemplate } from 'src/app/modules/core/team-configuration/ITeamTemplate';
import { NationalIdValidator } from 'src/app/modules/core/validators/national-id-validator';
import { RegistrationRequest } from 'src/app/view-models/registration-request';
import { EmailValidator } from 'src/app/modules/core/validators/email-validator';
import { HelperService } from 'src/app/helper-services/helper-service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['../registration-form.scss', './registration.component.scss']
})
export class RegistrationComponent extends TeamTemplateComponent implements OnInit {
	public form: FormGroup;
	public template: ITeamTemplate;
	public currentRegistration: RegistrationRequest;
	public isCfa: boolean = false;
	public nameRegexpPattern = /^[A-Za-zΑ-Ωα-ωΆά Έέ Ήή Ίί Όό Ύύ ΏώΪϊ Ϋϋ""'\-\-]*$/;
	public phoneRegexpPattern = /(^[+]{0,1})([\d])*$/;
	public isBackPressed: any;
	@Output() updateWidth = new EventEmitter();

	constructor(
		templateService: TeamService,
    private formBuilder: FormBuilder,
		private router: Router,
		private nationalIdValidator: NationalIdValidator,
		private emailValidator: EmailValidator,
		private activatedRoute: ActivatedRoute,
		private registrationService: RegistrationService,
		public helperService: HelperService
	) {
		super(templateService);
		this.template = templateService.teamTemplate;
	}

	ngOnInit() {
		this.loadDataIfExist();
		this.initForm();
		this.isCfa = this.template.teamName == 'CFA';
		this.registrationService.setIndex(0);
	}

	nextItem() {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		this.updateRegisterInformation();
		this.router.navigate([`${this.template.teamId}/steps/profile-info`]);
  }

	loadAttachments(fileName: string) {
		let path = `/assets/attachments/${this.teamName}/${fileName}`;
		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.target = '_blank';
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}

	login() {
		this.router.navigate([`/${this.template.teamId}/login`]);
	}

	private updateRegisterInformation() {
		this.registrationService.updateInfo(this.form.value);
	}

	private initForm() {
		this.form = this.formBuilder.group({
			firstName: [this.currentRegistration.firstName, [Validators.required, Validators.maxLength(50)]],
			lastName: [this.currentRegistration.lastName, [Validators.required, Validators.maxLength(149)]],
			nationalId: [this.currentRegistration.nationalId, {
				validators: [Validators.required],
				asyncValidators: [this.nationalIdValidator.uniqueNationalId()],
				updateOn: 'blur'
			}],
			email: [this.currentRegistration.email, {
				validators: [Validators.required, Validators.email],
				asyncValidators: [this.emailValidator.uniqueEmail()],
				updateOn: 'blur'
			}],
			isGdprAccepted: [true],
			isTermsAndConditionsAccepted: [true],
			isMarketingAccepted: [this.currentRegistration.isMarketingAccepted || false],
			isTicketTermsAndConditionsAccepted: [true],
			isThirdPartyAccepted: [true],
		});
  }

	private loadDataIfExist() {
		if (this.registrationService.getRegistrationInformation()) {
			this.currentRegistration = this.registrationService.getRegistrationInformation();
		}
		else {
			this.currentRegistration = new RegistrationRequest();
		}
	}
}
