export enum SaleStatusEnum {
	New = 1,
	Completed = 2,
	Canceled = 3,
	Refunded = 4,
	Prereserved = 5,
	Archived = 6,
	Priority = 7,
	PrereservedSeasonTicketSale = 8

}
